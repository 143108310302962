import styled from "styled-components";
import { Flex } from "@chakra-ui/react";

export const ToolbarRowWrapper = styled(Flex)`
  flex-direction: row;
  margin-bottom: 7px;
  font-size: 0.75rem;
  padding-left: 2px;
  align-items: center;
`;
