import { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Button,
  Flex,
  Stack,
  HStack,
  Tabs,
  TabList,
  Tab,
  TabIndicator,
  TabPanel,
  TabPanels,
  FormControl,
  FormLabel,
  Container,
} from "@chakra-ui/react";
import { useAlertEventsById } from "@/services/useAlertEvents";
import { PostModalProvider } from "@/components/feeds/modal/post-modal-context";
import FeedPost from "@/components/feeds/feed-post";
import { Scroll } from "@/components/feeds/feed-layout-styled";
import { dateFormatter } from "@/common/utility/dateTimeUtil";
import { useCurrentUser } from "@/services/useCurrentUser";
import { LanguageProvider, useLanguage } from "@/components/userprofile/language/lang-context";
import useTheme from "@/common/utility/useTheme";
const AlertEventsModal = ({ isOpen, onClose, eventId }) => {
  const {
    state: { lang },
  } = useLanguage();
  const { data, error, isLoading } = useAlertEventsById(eventId);
  const [alertEvents, setAlertEvents] = useState<any>({});
  //const [user, setUser] = useState<any>({});
  const { data: user } = useCurrentUser();
  const theme = useTheme();

  useEffect(() => {
    if (!isLoading) {
      setAlertEvents(data);
    }

  }, [isLoading]);

  return (
    <>
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size={"3xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader display="flex" alignItems="center" justifyContent="center">
          {lang?.ALERTS_EVENTS_VIEW_TITLE || "Alert Event"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Scroll theme={theme}>
            {alertEvents && (
              <Flex flexDirection="column">
                <Flex flexDirection="row">
                  <HStack spacing={"36"}>
                    <Flex flexDirection="column" pl={5} pr={5}>
                      <Stack>
                        <FormControl mt={2}>
                          <FormLabel>{lang?.ALERTS_EVENTS_VIEW_NAME || "Alert Name"}</FormLabel>
                          <Text padding={1} fontSize={"sm"}>
                            {" "}
                            {alertEvents?.alertEvent?.alertName}
                          </Text>
                        </FormControl>
                        <FormControl mt={2}>
                          <FormLabel>{lang?.ALERTS_EVENTS_VIEW_TRIGGEREDAT || "Triggered at"}</FormLabel>
                          <Text padding={1} fontSize={"sm"}>
                            {`${dateFormatter({
                              value:
                                alertEvents?.alertEvent?.eventTriggeredDate,
                              formatType: "MMM dd, yyyy, h:mm:ss a",
                            })}`}
                          </Text>
                        </FormControl>
                      </Stack>
                    </Flex>
                    <Flex flexDirection="column" pl={5}>
                      <Stack>
                        <FormControl mt={2}>
                          <FormLabel>{lang?.ALERTS_EVENTS_VIEW_TYPE || "Trigger Type"}</FormLabel>
                          <Text padding={1} fontSize={"sm"}>
                            {" "}
                            {alertEvents?.alertEvent?.alertType}
                          </Text>
                        </FormControl>
                        <FormControl mt={2}>
                          <FormLabel>{lang?.ALERTS_EVENTS_VIEW_TOTAL || "Total Posts"}</FormLabel>
                          <Text padding={1} fontSize={"sm"}>
                            {" "}
                            {alertEvents?.alertEvent?.totalPosts}
                          </Text>
                        </FormControl>
                      </Stack>
                    </Flex>
                  </HStack>
                </Flex>
                <Flex flexDirection="row">
                  <Tabs align="start" position="relative" variant="unstyled">
                    <TabList>
                      <Tab>
                        <FormControl mt={2}>
                          <FormLabel fontSize={"md"}>
                            {" "}
                            <i className="fa fa-folder"></i> {lang?.ALERTS_EVENTS_VIEW_POSTS || "Posts"}
                          </FormLabel>
                        </FormControl>
                      </Tab>
                      <Tab>
                        <FormControl mt={2}>
                          <FormLabel fontSize={"md"}>
                            <i className="fa fa-users"></i>{lang?.ALERTS_EVENTS_VIEW_RECIPIENTS || "Recipients"}
                          </FormLabel>
                        </FormControl>
                      </Tab>
                    </TabList>
                    <TabIndicator
                      mt="-1.2px"
                      height="2px"
                      bg="blue.500"
                      borderRadius="1px"
                    />
                    <TabPanels>
                      <TabPanel padding={"-1"}>
                        <Flex
                          direction={"column"}
                          textAlign={"left"}
                          justifyContent={"flex-start"}
                          minWidth={"680px"}
                          flexBasis="100%"
                          maxHeight={"800px"}
                        >
                          {alertEvents?.posts &&
                            alertEvents?.posts?.map((post, index) => (
                              <PostModalProvider key={index}>
                                <FeedPost
                                  theme={theme}
                                  showTags={true}
                                  user={user}
                                  post={post}
                                  isAlert={true}
                                  display={null}
                                  lang={lang}
                                ></FeedPost>
                              </PostModalProvider>
                            ))}
                        </Flex>
                      </TabPanel>
                      <TabPanel padding={"-1"}>
                        <Flex flexDirection={"column"}>
                          <Flex
                            direction={"column"}
                            textAlign={"left"}
                            justifyContent={"flex-start"}
                            minWidth={"680px"}
                            flexBasis="100%"
                            maxHeight={"800px"}
                          >
                            <FormControl mt={2}>
                              <FormLabel>{lang?.ALERTS_EVENTS_VIEW_RECIPIENTS_EMAILS || "Emails"}</FormLabel>
                                                          <Container maxW="680px" bg="white">
                                                              {alertEvents?.alertEvent?.emails?.length > 0 ? (
                                                                  alertEvents?.alertEvent?.emails?.map((email, index) => (
                                    <>
                                      <Text color={theme?.feed?.footer?.color}>
                                        {email}
                                      </Text>

                                    </>
                                  ))
                                ) : (
                                  <>
                                    <Text
                                      fontSize={"sm"}
                                      color={theme?.feed?.footer?.color}
                                    >
                                        {lang?.NONE || "None"}
                                    </Text>
                                  </>
                                )}
                              </Container>
                            </FormControl>
                            <FormControl mt={2}>
                              <FormLabel>{lang?.ALERTS_EVENTS_VIEW_RECIPIENTS_SMS || "Sms Numbers"}</FormLabel>
                                                          <Container maxW="680px" bg="white">
                                                              {alertEvents?.alertEvent?.smsNumbers?.length > 0 ? (
                                                                  alertEvents?.alertEvent?.smsNumbers?.map((sms, index) => (
                                    <>
                                      <Text color={theme?.feed?.footer?.color}>
                                        {sms}
                                      </Text>
                                    </>
                                  ))
                                ) : (
                                  <>
                                    <Text
                                      fontSize={"sm"}
                                      color={theme?.feed?.footer?.color}
                                    >
                                        {lang?.NONE || "None"}
                                    </Text>
                                  </>
                                )}
                              </Container>
                            </FormControl>
                            <FormControl mt={2}>
                              <FormLabel>{lang?.ALERTS_EVENTS_VIEW_RECIPIENTS_TEAMS || "Teams"}</FormLabel>
                                                          <Container maxW="680px" bg="white">
                                                              {alertEvents?.alertEvent?.teams?.length > 0 ? (
                                                                  alertEvents?.alertEvent?.teams?.map((team, index) => (
                                    <>
                                      <Text color={theme?.feed?.footer?.color}>
                                        {team}
                                      </Text>
                                    </>
                                  ))
                                ) : (
                                  <>
                                    <Text
                                      fontSize={"sm"}
                                      color={theme?.feed?.footer?.color}
                                    >
                                        {lang?.NONE || "None"}
                                    </Text>
                                  </>
                                )}
                              </Container>
                            </FormControl>
                          </Flex>
                        </Flex>
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </Flex>
              </Flex>
            )}
          </Scroll>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => onClose()}>{lang?.MESSAGE_CLOSE || "Close"}</Button>
        </ModalFooter>
      </ModalContent>
      </Modal>
    </>
  );
};

export default AlertEventsModal;
