import useSWR from "swr";

const fetcher = (url, ...rest) => fetch(url, ...rest).then((res) => res.json());

export function useNogginFormValues() {
  const { data, error, isLoading } = useSWR(() => {
    return "/api/noggin/getFormValues";
  }, fetcher);

  return {
    data,
    isLoading,
    isError: error,
  };
}

export function useEverbridgeFormValues() {
  const { data, error, isLoading } = useSWR(() => {
    return "/api/everbridge/getFormValues";
  }, fetcher);

  return {
    data,
    isLoading,
    isError: error,
  };
}