import { useState, useEffect, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormLabel,
  FormControl,
  Input,
  Spinner,
  Text,
  Flex,
  Spacer,
  Checkbox,
  Textarea,
} from "@chakra-ui/react";
import { CustomToast } from "@/common/toast/CustomToast";
import {
  IXMattersRiskEvent,
  IXMattersRiskEventModalProps,
} from "@/interfaces/IXMattersIntegration";
import { useXmattersIntegration } from "./xmatters-integration-context";
import { MiniPost } from "@/components/feeds/mini-post";
import { errorMessage } from "@/common/utility/errorMessage";
import { useLanguage } from "../userprofile/language/lang-context";

const initialXmattersRiskEvent: IXMattersRiskEvent = {
  id: "",
  searchId: "",
  alertTitle: "",
  source: "",
  eventDescription: "",
  includePostUrl: false,
  isPost: false,
  priority: null,
};

const fetchBasicSearch = async (id: string) => {
  try {
    const response = await fetch(`/api/searches/search/basic?id=${id}`);
    if (response.ok) {
      const search = await response.json();
      return search;
    }
  } catch (error) {}
};

const XmattersIntegrationModal = ({
  data,
  post,
  isOpen,
  onOpen,
  onClose,
  modalResponse,
}: IXMattersRiskEventModalProps) => {
  const {
    state: { fetch, xmattersIntegration },
    onSave,
  } = useXmattersIntegration();
  const { successToast } = CustomToast();

  const isFirstRender = useRef(true);
  const isFirstRenderFetch = useRef(true);
  const [isNew, setIsNew] = useState(true);
  const [isModifyTitle, setIsModifyTitle] = useState(false);
  const [xmattersRiskEvent, setXmattersRiskEvent] =
    useState<IXMattersRiskEvent>(initialXmattersRiskEvent);
  const {
    state: { lang },
  } = useLanguage();

  const isDisabled =
    xmattersRiskEvent.alertTitle === "" ||
    xmattersRiskEvent.eventDescription === "";

  useEffect(() => {
    if (isFirstRender.current) {
      setIsNew(Boolean(data && data.id) ? false : true);

      if (data) {
        setXmattersRiskEvent(data);
      } else {
        setXmattersRiskEvent(initialXmattersRiskEvent);
      }
      isFirstRender.current = false;
    }
  }, [data]);

  useEffect(() => {
    if (isFirstRenderFetch.current && data && data.searchId) {
      (async () => {
        const search = await fetchBasicSearch(data.searchId);
        if (search) {
          updateXmattersRiskEventState("alertTitle", search.name);
        }
      })();
      isFirstRenderFetch.current = false;
    }
  }, [data]);

  const updateXmattersRiskEventState = (
    property: keyof IXMattersRiskEvent,
    value: any
  ) => {
    setXmattersRiskEvent((prevState) => {
      return {
        ...prevState,
        [property]: value,
      };
    });
  };

  const handleIsModifyTitle = (e) => {
    setIsModifyTitle(e.target.checked);
  };

  const save = async () => {
    if (xmattersRiskEvent.isPost) {
      const res = await onSave(xmattersRiskEvent);
      if (res?.status === 200) {
        if (!fetch) {
          successToast(
            "xMatters Settings",
            "The risk event was successfully created."
          );
          onClose();
        }
      }
    } else {
      //pass data to query builder search
      const riskEvent = {
        alertTitle: xmattersRiskEvent.alertTitle,
        priority: xmattersRiskEvent.priority,
        eventDescription: xmattersRiskEvent.eventDescription,
        includePostUrl: xmattersRiskEvent.includePostUrl,
      };

      const json = JSON.stringify(riskEvent);
      modalResponse(json);
      onClose();
    }
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size={"lg"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader display="flex" alignItems="center" justifyContent="center" fontSize="md">
          {lang?.XMATTERS_RISK_EVENT_SETTINGS || "xMatters Settings"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={1}>
          {data.isPost && <MiniPost post={post} />}
          <FormControl mt={1} isRequired>
            <Flex>
              <FormLabel>{lang?.XMATTERS_ALERT_TITLE || "Alert title"}</FormLabel>
              <Spacer width={"50%"} />
              <Checkbox size="sm" onChange={handleIsModifyTitle}>{lang?.XMATTERS_MODIFY_TITLE || "Modify title?"}</Checkbox>
            </Flex>
             <Input
              size="sm"
              onChange={(e) =>
                updateXmattersRiskEventState("alertTitle", e.target.value)
              }
              value={xmattersRiskEvent.alertTitle}
              isDisabled={!isModifyTitle}
            />
            {errorMessage(true, "alert title")}
          </FormControl>
          {!xmattersRiskEvent.isPost && (
            <FormControl mt={1}>
              <FormLabel>{lang?.SEARCHES_SEARCH_PRIORITY || "Alert Priority"}</FormLabel>
                <Input
                size="sm"
                onChange={(e) => {}}
                value={xmattersRiskEvent.priority}
                isDisabled={true}
              />
            </FormControl>
          )}
          <FormControl mt={1} isRequired>
            <FormLabel>{lang?.XMATTERS_DESCRIPTION || "Description"}</FormLabel>
             <Textarea
              size="sm"
              maxLength={10000}
              onChange={(e) =>
                updateXmattersRiskEventState("eventDescription", e.target.value)
              }
              value={xmattersRiskEvent.eventDescription}
            />
            <Flex justify="flex-end" fontSize={"xs"}>
              {xmattersRiskEvent.eventDescription.length}/10000
            </Flex>
            {errorMessage(true, "event description")}
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            size="sm"
            colorScheme="messenger"
            mr={3}
            isLoading={fetch}
            loadingText="Submitting..."
            onClick={save}
            isDisabled={isDisabled}
          >
            {lang?.SAVE || "Save"}
          </Button>
          <Button size="sm" colorScheme="gray" onClick={() => onClose()}>{lang?.CANCEL || "Cancel"}</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default XmattersIntegrationModal;
