import { useState, useEffect, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormLabel,
  FormControl,
  Input,
  Spinner,
  Text,
  Select,
  Textarea,
  Flex,
} from "@chakra-ui/react";
import { CustomToast } from "@/common/toast/CustomToast";
import { useNogginFormValues } from "@/services/useIntegrations";
import {
  INogginRiskEvent,
  INogginAlertSettings,
  INogginRiskEventModalProps,
} from "@/interfaces/INogginIntegration";
import { useNogginIntegration } from "./noggin-integration-context";
import { MiniPost } from "@/components/feeds/mini-post";
import { errorMessage } from "@/common/utility/errorMessage";
import { useLanguage } from "../userprofile/language/lang-context";

const initialNogginRiskEvent: INogginRiskEvent = {
  id: "",
  title: "",
  description: "",
  authorId: "",
  authorName: "",
  scope: "",
  severity: "",
  urgency: "",
  content: "",
  insertedDate: new Date(),
  publishedDate: new Date(),
  location: undefined,
  searchId: "",
  authorUrl: "",
  authorImageUrl: "",
  postUrl: "",
  isPost: false,
};

const fetchBasicSearch = async (id: string) => {
  try {
    const response = await fetch(`/api/searches/search/basic?id=${id}`);
    if (response.ok) {
      const search = await response.json();
      return search;
    }
  } catch (error) {}
};

const NogginIntegrationModal = ({
  data,
  post,
  isOpen,
  onOpen,
  onClose,
  modalResponse,
}: INogginRiskEventModalProps) => {
  const {
    state: { fetch, nogginIntegration },
    onSave,
  } = useNogginIntegration();
  const { successToast } = CustomToast();
  const { data: formValues, isLoading, isError } = useNogginFormValues();

  const isFirstRender = useRef(true);
  const isFirstRenderFetch = useRef(true);
  const [isNew, setIsNew] = useState(true);
  const [scope, setScope] = useState("");
  const [severity, setSeverity] = useState("");
  const [urgency, setUrgency] = useState("");
  const [nogginRiskEvent, setNogginRiskEvent] = useState<INogginRiskEvent>(
    initialNogginRiskEvent
  );
  const {
    state: { lang },
  } = useLanguage();

  const isDisabled =
    nogginRiskEvent.title === "" || nogginRiskEvent.description === "";

  useEffect(() => {
    if (isFirstRender.current) {
      setIsNew(Boolean(data && data.id) ? false : true);

      if (data) {
        setNogginRiskEvent(data);

        if (data.scope !== "") {
          setScope(data.scope);
        }
        if (data.urgency !== "") {
          setUrgency(data.urgency);
        }
        if (data.severity !== "") {
          setSeverity(data.severity);
        }
      } else {
        setNogginRiskEvent(initialNogginRiskEvent);
      }
      isFirstRender.current = false;
    }
  }, [data]);

  useEffect(() => {
    if (isFirstRenderFetch.current && data && data.searchId) {
      (async () => {
        const search = await fetchBasicSearch(data.searchId);
        if (search) {
          updateNogginRiskEventState(
            "description",
            `An incident automatically created by the signal alert process due to a result matching criteria of the ${search.name}`
          );
        }
      })();
      isFirstRenderFetch.current = false;
    }
  }, [data]);

  const updateNogginRiskEventState = (
    property: keyof INogginRiskEvent,
    value: any
  ) => {
    setNogginRiskEvent((prevState) => {
      return {
        ...prevState,
        [property]: value,
      };
    });
  };

  const handleScope = (e) => {
    const value = e.target.value;
    setScope(value);
    if (value) {
      updateNogginRiskEventState("scope", value.toLowerCase());
    }
  };

  const handleUrgency = (e) => {
    const value = e.target.value;
    setUrgency(value);
    if (value && value === "Semi-urgent") {
      updateNogginRiskEventState("urgency", "semiUrgent");
    } else if (value) {
      updateNogginRiskEventState("urgency", value.toLowerCase());
    }
  };

  const handleSeverity = (e) => {
    const value = e.target.value;
    setSeverity(value);
    if (value) {
      updateNogginRiskEventState("severity", value.toLowerCase());
    }
  };

  const save = async () => {
    if (nogginRiskEvent.isPost) {
      const res = await onSave(nogginRiskEvent);
      if (res?.status === 200) {
        if (!fetch) {
          successToast(
            "Noggin Alert Settings",
            "The risk event was successfully created."
          );
          onClose();
        }
      }
    } else {
      //pass data to query builder search
      const riskEvent = {
        title: nogginRiskEvent.title,
        description: nogginRiskEvent.description,
        urgency: nogginRiskEvent.urgency,
        severity: nogginRiskEvent.severity,
        scope: nogginRiskEvent.scope,
      };

      const json = JSON.stringify(riskEvent);
      modalResponse(json);
      onClose();
    }
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size={"lg"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader display="flex" alignItems="center" justifyContent="center" fontSize="md">
          {lang?.NOGGIN_ALERT_SETTINGS || "Noggin Alert Settings"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={1}>
          {data.isPost && <MiniPost post={post} />}
          <FormControl mt={1}>
            <FormLabel>{lang?.EVERBRIDGE_SCOPE || "Scope"}</FormLabel>
            <Select
              size="sm"
              placeholder={lang?.SELECT_VALUE || "Select value"}
              onChange={handleScope}
              value={scope}
            >
              {formValues?.scopeValues?.map((scope: string) => (
                <option key={scope} value={scope}>
                  {scope}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl mt={1}>
            <FormLabel>{lang?.EVERBRIDGE_URGENCY || "Urgency"}</FormLabel>
            <Select
              size="sm"
              placeholder={lang?.SELECT_VALUE || "Select value"}
              onChange={handleUrgency}
              value={urgency}
            >
              {formValues?.urgencyValues?.map((urgency: string) => (
                <option key={urgency} value={urgency}>
                  {urgency}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl mt={1}>
            <FormLabel>{lang?.SEVERITY || "Severity"}</FormLabel>
             <Select
              size="sm"
              placeholder={lang?.SELECT_VALUE || "Select value"}
              onChange={handleSeverity}
              value={severity}
            >
              {formValues?.severityValues?.map((severity: string) => (
                <option key={severity} value={severity}>
                  {severity}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl mt={1} isRequired>
            <FormLabel>{lang?.NOGGIN_TITLE || "Title"}</FormLabel>
              <Input
              size="sm"
              type="text"
              onChange={(e) =>
                updateNogginRiskEventState("title", e.target.value)
              }
              value={nogginRiskEvent.title}
            />
            {errorMessage(true, "title")}
          </FormControl>
          <FormControl mt={1} isRequired>
            <FormLabel>{lang?.NOGGIN_DESCRIPTION || "Description"}</FormLabel>
            <Textarea
              size="sm"
              maxLength={10000}
              onChange={(e) =>
                updateNogginRiskEventState("description", e.target.value)
              }
              value={nogginRiskEvent.description}
            />
            <Flex justify="flex-end" fontSize={"xs"}>
              {nogginRiskEvent.description.length}/10000
            </Flex>
            {errorMessage(true, "description")}
          </FormControl>
        </ModalBody>

        <ModalFooter>
           <Button
            size="sm"
            colorScheme="messenger"
            mr={3}
            isLoading={fetch}
            loadingText="Submitting..."
            onClick={save}
            isDisabled={isDisabled}
          >
            {lang?.SAVE || "Save"}
          </Button>
          <Button size="sm" colorScheme="gray" onClick={() => onClose()}>{lang?.CANCEL || "Cancel"}</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NogginIntegrationModal;
