import useSWR from "swr";

const fetcher = (key: string) => fetch(key).then((res) => res.json());
export function useCurrentUser() {
  const { data, error, isLoading } = useSWR(() => {
    let url = "/api/profile/getCurrentUser";
    return `${url}`;
  }, fetcher);

  return {
    data,
    isLoading,
    isError: error,
  };
}
