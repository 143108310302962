import useSWR from "swr";

//const fetcher = (...args) => fetch(...args).then((res) => res.json());
const fetcher = (url, ...rest) => fetch(url, ...rest).then((res) => res.json());

export function useTeams() {
  const { data, error, isLoading } = useSWR(() => {
    return "/api/teams?getDisabled=true";
  }, fetcher);

  return {
    data,
    isLoading,
    isError: error,
  };
}