import React from "react";
import {
  ChakraProvider,
  CSSReset,
  extendTheme,
  useColorMode,
} from "@chakra-ui/react";

const ThemeWrapper = ({ children }) => {
  const { colorMode } = useColorMode();
  const theme = extendTheme({
    styles: {
      global: (props) => ({
        body: {
          color: "default",
          bg: colorMode === "light" ? "#EBE6E6" : "#000000",
          "*": {
            borderColor: colorMode === "light" ? "#CBC5C5" : "gray.600",
          },
        },
      }),
    },
    components: {
      Text: {
        baseStyle: {
          color: colorMode === "light" ? "#000000" : "#939597",
        },
      },
      Modal: {
        baseStyle: {
          dialog: {
            bg: colorMode === "light" ? "#EBE6E6" : "gray.700",
            color: colorMode === "light" ? "#000000" : "#C0C0C2",
          },
        },
      },
      AlertDialog: {
        baseStyle: {
          dialog: {
            bg: colorMode === "light" ? "#EBE6E6" : "gray.700",
            color: colorMode === "light" ? "#000000" : "#C0C0C2",
          },
        },
      },
      FormLabel: {
        baseStyle: {
            color: colorMode === "light" ? "#000000" : "gray.400",
            fontSize:"xs"
        },
      },
      Textarea: {
        baseStyle: {
          bg: colorMode === "light" ? "#FFFFFF" : "gray.700",
          color: colorMode === "light" ? "gray.700" : "#C0C0C2",
          fontSize: "xs",
          _disabled: {
            bg: colorMode === "light" ? "gray.200" : "gray.600",
            color: colorMode === "light" ? "gray.500" : "gray.400",
          },
        },
      },
      Spinner: {
        baseStyle: {
          color: colorMode === "light" ? "blue.500" : "gray.100",
        },
      },
      Heading: {
        baseStyle: {
          color: colorMode === "light" ? "#5D5D5D" : "gray.100",
        },
      },
      Table: {
        baseStyle: {
          color: colorMode === "light" ? "#000000" : "#C0C0C2", // Set your desired text color for the Table component here
        },
      },
    },
  });

  return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
};

export default ThemeWrapper;
