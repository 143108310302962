import React, { useEffect, useRef, useState } from 'react';
import { GoogleMap, CircleF, RectangleF, StandaloneSearchBox, MarkerF, DrawingManagerF } from '@react-google-maps/api';
import { GeoInputWrapper } from './geo-input-styled';
import { IGeo, IGeoInput } from '@/interfaces/search-builder/IGeo';
import { CustomToast } from "@/common/toast/CustomToast";
import { getDistance, getZoomLevel } from '../helpers';

const SetMapInitialState = (props: IGeoInput) => {
    let rectangleBounds;
    let rectangleAreaInKm;
    let circleCenter;
    let circleRadius;
    let mapCenter;
    let zoom;

    if (props.circleCenter) {
        circleCenter = new google.maps.LatLng(props.circleCenter);
        mapCenter = new google.maps.LatLng(props.circleCenter);
        circleRadius = props.circleRadius | 0;
        zoom = getZoomLevel(Number(props.circleRadius) * 1500);
    }
    else if (props.rectangleBounds) {
        const rectangleDiagonal = getDistance(
            Number(props.rectangleBounds.ne.lat), Number(props.rectangleBounds.ne.lng),
            Number(props.rectangleBounds.sw.lat), Number(props.rectangleBounds.sw.lng)
        );
        zoom = getZoomLevel(rectangleDiagonal * 150000);
        mapCenter = new google.maps.LatLngBounds(props.rectangleBounds.sw, props.rectangleBounds.ne).getCenter();
        rectangleBounds = props.rectangleBounds ? new google.maps.LatLngBounds(props.rectangleBounds.sw, props.rectangleBounds.ne) : null;
        rectangleAreaInKm = props.rectangleAreaInKm | 0;
    }
    else {
        mapCenter = new google.maps.LatLng(props.mapCenter);
        zoom = props.zoom;
    }

    const initialState =
        {
            rectangleBounds: rectangleBounds,
            rectangleAreaInKm: rectangleAreaInKm,
            circleCenter: circleCenter,
            circleRadius: circleRadius,
            mapCenter: mapCenter,
            zoom: zoom,
            defaultMarker: null,
            drawingMode: null
        } as IGeo;

    return initialState;
};

const GeoInput = (props: IGeoInput) => {

    const [geoShapeSummary, setGeoShapeSummary] = useState(null);
    const circleRef = useRef(null);
    const { errorToast } = CustomToast();
    const [map, setMap] = useState<IGeo>(SetMapInitialState(props));
    const [circle, setCircle] = useState(null);
    const [rectangle, setRectangle] = useState(null);
    const { rectangleBounds, circleCenter, circleRadius, mapCenter, zoom, defaultMarker, drawingMode } = map;
    const [autocomplete, setAutocomplete] = useState(null);

    useEffect(() => {
        // Update the map based on the new values
        if (props.circleCenter) {
            updateMapState("rectangleBounds", null);
        }
        else if (props.rectangleBounds) {
            updateMapState("circleCenter", null);
        }
        else {
            if (props.drawingMode === 'circle') {
                updateMapState("circleCenter", map.circleCenter);
                updateMapState("rectangleBounds", null);
            }
            else {
                updateMapState("rectangleBounds", map.rectangleBounds);
                updateMapState("circleCenter", null);
            }
        }

        updateMapState("drawingMode", props.drawingMode === 'circle' ? google.maps.drawing.OverlayType.CIRCLE : google.maps.drawing.OverlayType.RECTANGLE);

        let infoData = ""
        if (map?.circleCenter || map?.rectangleBounds) {
            if (map.rectangleBounds) {
                let ne = map.rectangleBounds.getNorthEast(); // Coords of the northeast corner
                let sw = map.rectangleBounds.getSouthWest(); // Coords of the southwest corner
                let nw = new google.maps.LatLng(ne.lat(), sw.lng()); // Coords of the NW corner
                let se = new google.maps.LatLng(sw.lat(), ne.lng()); // Coords of the SE corner
                infoData = `Rectangle bounds: NW: [${nw.lat().toFixed(3)};${nw.lng().toFixed(3)}] NE: [${ne.lat().toFixed(3)};${ne.lng().toFixed(3)}] SE: [${se.lat().toFixed(3)};${se.lng().toFixed(3)}] SW: [${sw.lat().toFixed(3)};${sw.lng().toFixed(3)}] / Area: ${Number(map.rectangleAreaInKm).toFixed(3)} km2`;
            }
            else if (map.circleCenter && map.circleRadius && map.circleRadius > 0) {
                let circleAreaInKm2 = (Math.PI * ((map.circleRadius / 1000) * (map.circleRadius / 1000)));
                infoData = `Circle center: [${map.circleCenter.lat().toFixed(3)}, ${map.circleCenter.lng().toFixed(3)}] / Radius: ${(map.circleRadius / 1000).toFixed(3)} km / Area: ${(circleAreaInKm2).toLocaleString(undefined, { maximumFractionDigits: 3 })} km2`;
            }
        }

        setGeoShapeSummary(infoData);

    }, [props.circleCenter, props.circleRadius, props.rectangleBounds,
    props.drawingMode, props.mapCenter, map.circleCenter, map.circleRadius, map.rectangleBounds, map.rectangleAreaInKm]);

    useEffect(() => {
        if (circle) {
            // Remove the existing circle from the map
            circle.setMap(null);
        }
        if (rectangle) {
            // Remove the existing circle from the map
            rectangle.setMap(null);
        }
    }, [drawingMode, circle, rectangle]);

    //https://developers.google.com/maps/documentation/javascript/reference/polygon#CircleOptions
    const circleOptions = {
        strokeColor: 'purple',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: 'blue',
        fillOpacity: 0.35,
        clickable: true,
        draggable: true,
        editable: true,
        visible: true,
    }

    //https://developers.google.com/maps/documentation/javascript/reference/polygon#RectangleOptions
    const rectangleOptions = {
        strokeColor: 'purple',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: 'blue',
        fillOpacity: 0.35,
        clickable: true,
        draggable: true,
        editable: true,
        visible: true,
    }

    const updateMapState = (name: keyof IGeo, value: any) => {
        if (name === "circleRadius" && value > props.circleMaxRadius) {
            return;
        }

        setMap((prevState) => ({ ...prevState, [name]: value }));
    }

    const onLoadDrawingManager = drawingManager => {
        //console.log("onLoadDrawingManager", drawingManager)
    }

    const onPolygonComplete = polygon => {
        //console.log(polygon)
    }

    function clearMap(setMap: React.Dispatch<React.SetStateAction<IGeo>>) {
        setMap((prevState) => {
            return {
                ...prevState,
                rectangleBounds: null,
                circleCenter: null,
                defaultMarker: null,
                circleRadius: null,
                rectangleAreaInKm: null
            };
        });

        // propagate changes to consumers
        props.onChange({
            map: {
                geo: { circle: null, rectangle: null }
            }
        });
    }

    function ValidateAndSetRectangle(newRectangle) {
        // Calculate the area of the rectangle in square meters
        const ne = newRectangle.bounds.getNorthEast();
        const sw = newRectangle.bounds.getSouthWest();
        const width = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(ne.lat(), ne.lng()), new google.maps.LatLng(ne.lat(), sw.lng()));
        const height = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(ne.lat(), ne.lng()), new google.maps.LatLng(sw.lat(), ne.lng()));
        const area = width * height;

        // validate rectangle size!!!
        if (width > props.rectangleMaxWidth || height > props.rectangleMaxWidth) {
            errorToast("", `Rectangle's width/height cannot be larger than ${props.rectangleMaxWidth} mts.`)
            updateMapState("rectangleBounds", null); // clear the rectangle because is too big!
            newRectangle.setMap(null);
            //clearMap(setMap);
            return;

            // Need to interpolate the new latitude if we want to redraw a new point below limit
            const ratio = props.rectangleMaxWidth / width;
            //newLat = google.maps.geometry.spherical.interpolate(new google.maps.LatLng(fixedPoint.lat(), fixedPoint.lng()), new google.maps.LatLng(newPoint.lat(), fixedPoint.lng()), ratio).lat();
            //updateState("rectangleBounds", newBounds); // keep current
        }

        updateMapState("rectangleBounds", newRectangle.bounds); // set the new rectangle
        updateMapState("rectangleAreaInKm", (area / 1000 / 1000)); // set the new rectangle area
    }


    function calculateRectangleArea(newRectangle: any) {
        const ne = newRectangle.bounds.getNorthEast();
        const sw = newRectangle.bounds.getSouthWest();
        const width = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(ne.lat(), ne.lng()), new google.maps.LatLng(ne.lat(), sw.lng()));
        const height = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(ne.lat(), ne.lng()), new google.maps.LatLng(sw.lat(), ne.lng()));
        const area = width * height;
        return area;
    }

    const drawingManagerOptions = () => {
        return ({
            drawingControl: true,
            drawingControlOptions: {
                position: google.maps.ControlPosition.BOTTOM_LEFT,
                drawingModes: [
                    map.drawingMode
                ]
            },
            drawingMode: null,
            circleOptions: circleOptions,
            rectangleOptions: rectangleOptions,
            polygonOptions: rectangleOptions
        })
    };

    const onLoadAutocomplete = (event) => {
        setAutocomplete(event);
    }

    const onPlaceChanged = () => {
        if (!autocomplete) return;
        const places = autocomplete.getPlaces();
        if (places.length === 0) return;

        const place = places[0];

        if (place) {
            // clear the map
            clearMap(setMap);

            const newCenter = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
            };
            updateMapState("zoom", 8);
            updateMapState("mapCenter", new google.maps.LatLng(newCenter));
            updateMapState("defaultMarker", newCenter);
        }
    };

    const onMarkerRightClick = (e: google.maps.MapMouseEvent): void => {
        updateMapState("defaultMarker", null);
    }

    const onRectangleRightClick = (e: google.maps.MapMouseEvent): void => {
        updateMapState("rectangleBounds", null);
        // propagate changes to consumers
        props.onChange({
            map: {
                geo: { circle: null, rectangle: null }
            }
        });
    }

    const onCirclelRightClick = (e: google.maps.MapMouseEvent): void => {
        updateMapState("circleCenter", null);
        // propagate changes to consumers
        props.onChange({
            map: {
                geo: { circle: null, rectangle: null }
            }
        });
    }

    const onLoadCircle = circle => {
        circleRef.current = circle;
    }

    const onCircleComplete = newCircle => {
        if (circle) {
            // Remove the existing circle from the map
            circle.setMap(null);
        }

        if (rectangle) {
            // Remove the existing rectangle from the map
            rectangle.setMap(null);
        }

        // clear the map
        clearMap(setMap);

        const radius = newCircle.getRadius();
        if (radius <= props.circleMaxRadius) {
            const center = newCircle.getCenter();
            updateMapState("circleCenter", center); // set the new circle's center'
            updateMapState("circleRadius", radius); // set the new circle's radius'

            // propagate changes to consumers
            props.onChange({
                map: {
                    geo: {
                        circle: {
                            center: { latitude: center.lat(), longitude: center.lng() },
                            radius: radius
                        },
                        rectangle: null
                    }
                }
            });

            // Store the reference to the new circle
            setCircle(newCircle);
        }
        else {
            errorToast("", `Circle radius cannot be greater than ${props.circleMaxRadius?.toLocaleString()} mts.`)
            updateMapState("circleCenter", null); // set the new circle's center'
            newCircle.setMap(null);
            // clear the map
            //clearMap(setMap);
        }
    };

    const onCircleRadiusChanged = () => {
        if (!circleRef.current) return;
        const newRadius = circleRef.current.getRadius();
        const newCenter = circleRef.current.getCenter();
        if (newRadius <= props.circleMaxRadius) {
            updateMapState("circleRadius", newRadius);
            // propagate changes to consumers
            props.onChange({
                map: {
                    geo: {
                        circle: {
                            center: { latitude: newCenter.lat(), longitude: newCenter.lng() },
                            radius: newRadius
                        },
                        rectangle: null
                    }
                }
            });
        }
        else {
            errorToast("", `Circle radius cannot be greater than ${props.circleMaxRadius?.toLocaleString()} mts.`)
            circleRef.current.setRadius(props.circleMaxRadius)
        }
    }

    const onCircleDragEnd = () => {
        if (circleRef.current) {
            const newCenter = circleRef.current.getCenter();
            updateMapState("circleCenter", newCenter);

            // propagate changes to consumers
            props.onChange({
                map: {
                    geo: {
                        circle: {
                            center: { latitude: newCenter.lat(), longitude: newCenter.lng() },
                            radius: circleRef.current.getRadius()
                        },
                        rectangle: null
                    }
                }
            });
        }
    }

    const rectangleRef = useRef(null);
    const onLoadRectangle = rectangle => {
        rectangleRef.current = rectangle;
    }

    const onRectangleComplete = newRectangle => {
        if (rectangle) {
            // Remove the existing rectangle from the map
            rectangle.setMap(null);
        }

        if (circle) {
            // Remove the existing circle from the map
            circle.setMap(null);
        }

        // clear the map
        clearMap(setMap);

        ValidateAndSetRectangle(newRectangle);
        // Store the reference to the new rectangle
        setRectangle(newRectangle);
    }

    const onRectangleBoundsChanged = () => {
        if (!rectangleRef.current) return;

        const newBounds = rectangleRef.current.getBounds();
        ValidateAndSetRectangle(rectangleRef.current);

        const area = calculateRectangleArea(rectangleRef.current);
        let ne = newBounds.getNorthEast(); // Coords of the northeast corner
        let sw = newBounds.getSouthWest(); // Coords of the southwest corner
        let nw = new google.maps.LatLng(ne.lat(), sw.lng()); // Coords of the NW corner
        let se = new google.maps.LatLng(sw.lat(), ne.lng()); // Coords of the SE corner        
        const bounds = {
            ne: { latitude: ne.lat(), longitude: ne.lng() },
            nw: { latitude: nw.lat(), longitude: nw.lng() },
            se: { latitude: se.lat(), longitude: se.lng() },
            sw: { latitude: sw.lat(), longitude: sw.lng() }
        };

        // propagate changes to consumers
        props.onChange({
            map: {
                geo: {
                    circle: null,
                    rectangle: {
                        bounds: bounds,
                        area: (area / 1000 / 1000) //km2
                    }
                }
            }
        });
    }

    return (
        <GeoInputWrapper>
            <GoogleMap
                id="layer-builder-map"
                mapContainerStyle={{ width: props.width, height: props.height }}
                zoom={zoom}
                center={mapCenter}
            >
                <DrawingManagerF
                    onLoad={onLoadDrawingManager}
                    onPolygonComplete={onPolygonComplete}
                    onCircleComplete={onCircleComplete}
                    onRectangleComplete={onRectangleComplete}
                    options={drawingManagerOptions()}
                />
                <StandaloneSearchBox
                    onLoad={onLoadAutocomplete}
                    onPlacesChanged={() => onPlaceChanged()}
                >
                    <input
                        type="text"
                        placeholder="Type a location"
                        style={{
                            boxSizing: `border-box`,
                            border: `1px solid transparent`,
                            width: `250px`,
                            height: `32px`,
                            padding: `0 12px`,
                            borderRadius: `3px`,
                            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                            fontSize: `14px`,
                            outline: `none`,
                            textOverflow: `ellipses`,
                            position: "absolute",
                            left: "50%",
                            marginLeft: "-120px"
                        }}
                    />
                </StandaloneSearchBox>
                {defaultMarker ? (
                    <MarkerF
                        position={defaultMarker}
                        draggable={false}
                        onRightClick={onMarkerRightClick} />
                ) : null}
                {rectangleBounds ? (
                    <RectangleF
                        onLoad={onLoadRectangle}
                        bounds={rectangleBounds}
                        options={rectangleOptions}
                        onRightClick={onRectangleRightClick}
                        onBoundsChanged={onRectangleBoundsChanged}
                    />
                ) : null}
                {circleCenter ? (
                    <CircleF
                        onLoad={onLoadCircle}
                        center={circleCenter}
                        options={circleOptions}
                        radius={circleRadius}
                        onRightClick={onCirclelRightClick}
                        onDragEnd={onCircleDragEnd}
                        onRadiusChanged={onCircleRadiusChanged}
                    />
                ) : null}
            </GoogleMap>
            <div>{geoShapeSummary}</div>
        </GeoInputWrapper>
    )
}

export default GeoInput;