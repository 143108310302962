import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Button,
  Flex,
  Input,
  useColorMode,
  InputGroup,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  InputRightElement,
  useColorModeValue,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import { CustomToast } from "@/common/toast/CustomToast";
import { useLanguage } from "@/components/userprofile/language/lang-context";
import EmojiPicker, { EmojiStyle, Theme } from "emoji-picker-react";
import { table } from "console";

interface MultiTagInputProps {
  terms: string[];
  maxTags: number;
  maxLengthPerTag: number;
  colorScheme?: string;
  placeHolderText: string;
  enableEmojiPicker: boolean;
  onTermsChange: (terms: string[]) => void;
  onRefreshTranslation?: any;
  allowRefreshTranslation?: boolean;
  prefix?: string;
  bannedTermsList?: string[];
  tagsContainerHeight?: number;
  inputSize?: string;
  fontSize?: string;
  padding?: number;
  validationRegex?: string;
  tabIndex: number;
}

function MultiTagInput(props: MultiTagInputProps) {
  const { control, handleSubmit, reset, setValue } = useForm();
  const {
    state: { lang },
  } = useLanguage();
  const { colorMode } = useColorMode();
  const [tags, setTags] = useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [emojisValue, setEmojisValue] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const twitterBannedTermsMessage1 =
    "You have entered the following banned terms: ";
  const twitterBannedTermsMessage2 =
    "Twitter has restrictions on the use of Twitter data for surveillance purposes, or in any other ways that would be inconsistent with their users reasonable expectations of privacy. More information on this can be found at: https://developer.twitter.com/en/developer-terms/more-on-restricted-use-cases";

  // Define the tags component background based on the color mode
  const bg = useColorModeValue("gray.100", "whiteAlpha.50");

  const handleEmojiButtonClick = () => {
    setShowEmojiPicker(true);
  };

  const handleEmojiPickerClose = () => {
    setShowEmojiPicker(false);
  };

  const { errorToast } = CustomToast();

  useEffect(() => {
    if (inputValue.length === 0) {
      setEmojisValue([]);
    }
  }, [inputValue]);

  useEffect(() => {
    if (props.terms) {
      if (!props.bannedTermsList && !props.prefix) {
        setTags(props.terms);
        return;
      }

      let onlyValidTerms = props.terms;
      if (props.bannedTermsList && props.bannedTermsList.length > 0) {
        onlyValidTerms = props.terms
          .map((term) => {
            // check against bannedTermsList if present
            // convert the term and the banned terms to lower case for comparison
            const lowerCaseTerm = term.toLowerCase();
            const lowerCaseBannedTerms = props.bannedTermsList.map(
              (bannedTerm) => bannedTerm.toLowerCase()
            );

            // check if the lower case term is in the lower case banned terms list
            if (lowerCaseBannedTerms.includes(lowerCaseTerm)) {
              errorToast("", twitterBannedTermsMessage1 + term);
              errorToast("", twitterBannedTermsMessage2);
              return; // skip this term
            }
            return term;
          })
          .filter(Boolean); // removes empty items
      }

      if (props.prefix) {
        // Add prefix at the start of each keyword if it does not exist
        let termsWithPrefix = onlyValidTerms.map((term) => {
          if (term.startsWith(props.prefix)) {
            return term.replace(/ /g, ""); // already has prefix
          } else {
            return `${props.prefix}${term}`.replace(/ /g, ""); // added prefix
          }
        });
        setTags(termsWithPrefix);
      } else {
        setTags(onlyValidTerms);
      }
    }
  }, [props.terms]);

  useEffect(() => {
    const handleEscapeKeyPress = (event) => {
      if (event.key === "Escape") {
        setShowEmojiPicker(false);
      }
    };

    document.addEventListener("keydown", handleEscapeKeyPress);

    return () => {
      document.removeEventListener("keydown", handleEscapeKeyPress);
    };
  }, [showEmojiPicker]);

  const handleEmojiClick = (item: any) => {
    const inputText = `${inputValue}${item.emoji}`;
    setInputValue(inputText);
  };

  const removeTag = (indexToRemove) => {
    const newTags = tags.filter((_, index) => index !== indexToRemove);
    setTags(newTags);
    props.onTermsChange(newTags);
  };

  const multiTagFooter = () => {
    const countText = `${tags.length}/${props.maxTags}`;
    return (
      <Flex flexDirection="row" justifyContent="space-between">
        <Tooltip
          isDisabled={!tags || tags.length === 0}
          shouldWrapChildren={true}
          hasArrow
          label="Clear all terms"
          placement="auto-end"
        >
          <IconButton
            style={
              !tags || tags.length === 0 ? { visibility: "hidden" } : undefined
            }
            isDisabled={!tags || tags.length === 0}
            onClick={() => {
              setTags([]);
              props.onTermsChange([]);
            }}
            fontSize="sm"
            marginTop={-2}
            marginLeft={-2}
            variant="unstyled"
            aria-label="Remove terms"
            icon={
              tags && tags.length > 0 ? (
                <i className="fa-sharp fa-solid fa-delete-left"></i>
              ) : (
                <i className="fa-duotone fa-delete-left"></i>
              )
            }
          />
        </Tooltip>
        {props.allowRefreshTranslation && tags && tags.length > 0 && (
          <Tooltip
            shouldWrapChildren={true}
            hasArrow
            label="Refresh translation"
            placement="bottom"
          >
            <IconButton
              onClick={props.onRefreshTranslation}
              fontSize="sm"
              marginTop={-2}
              marginLeft={-2}
              variant="unstyled"
              aria-label="Refresh translation"
              icon={<i className="fa-sharp fa-light fa-language"></i>}
            />
          </Tooltip>
        )}
        <Text fontSize="xs" marginTop={1} color="gray.500" textAlign="end">
          {countText}
        </Text>
      </Flex>
    );
  };

  const onSubmit = (data) => {
    const enteredValue = inputValue.trim();
    if (!enteredValue) {
      errorToast("Error", "Must type a term!");
      return;
    }

    if (props.maxTags === 1 && enteredValue.indexOf(",") >= 0) {
      errorToast(
        "Error",
        `Comma separated terms are not supported in the selected source. Max allowed: ${props.maxTags}`
      );
      return;
    }

    if (tags.length >= props.maxTags) {
      errorToast(
        "Error",
        `Cannot add more terms. Max allowed: ${props.maxTags}`
      );
      return;
    }

    if (
      props.validationRegex &&
      !new RegExp(props.validationRegex).test(enteredValue)
    ) {
      errorToast("Error", "Invalid input format!");
      return;
    }

    const inputTerms = enteredValue.split(",").map((term) => term.trim());
    const uniqueTextInput = new Set(inputTerms);
    const lowerCaseBannedTerms =
      props.bannedTermsList?.map((bannedTerm) => bannedTerm.toLowerCase()) ||
      [];

    const updatedTags = [...tags]; // Create a copy of the existing tags
    let wereTagsModified = false;
    uniqueTextInput.forEach((term) => {
      if (term === "") {
        // when there is comma , at the end of the term it acts like an empty term so we need to skip it.
        return;
      }

      const lowerCaseTerm = term.toLowerCase();

      if (lowerCaseBannedTerms.includes(lowerCaseTerm)) {
        errorToast("", twitterBannedTermsMessage1 + term);
        errorToast("", twitterBannedTermsMessage2);
        return;
      }

      if (term.length > props.maxLengthPerTag) {
        errorToast(
          "Error",
          `Term is too long!. Max length allowed: ${props.maxLengthPerTag}`
        );
        return;
      }

      if (term && !tags.includes(term)) {
        if (props.prefix && !term.startsWith(props.prefix)) {
          term = `${props.prefix}${term}`.replace(/ /g, "");

          if (tags.includes(term)) {
            errorToast("Error", `You already entered term: ${term}`);
            return;
          }
        }

        updatedTags.push(term);
        wereTagsModified = true;
      } else {
        errorToast("Error", `You already entered term: ${term}`);
        return;
      }
    });

    // propagate changes to parent only when tags were changed
    if (wereTagsModified) {
      setTags(updatedTags);
      props.onTermsChange(updatedTags);
    }

    setInputValue("");
    setEmojisValue([]);
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="tag"
        control={control}
        defaultValue={inputValue}
        render={({ field: { onChange, value } }) => (
          <div style={{ position: "relative" }}>
            <InputGroup>
              <Input
                tabIndex={props.tabIndex}
                fontSize="sm"
                fontWeight="normal"
                padding={props.padding || 4}
                name="termsInput"
                value={inputValue}
                onChange={(e) => {
                  onChange(e);
                  setInputValue(e.target.value);
                }}
                placeholder={props.placeHolderText}
                size="md"
                variant="filled"
                autoComplete="off"
              />
              {props.enableEmojiPicker && (
                <InputRightElement>
                  <Button
                    onClick={() => handleEmojiButtonClick()}
                    variant="unstyled"
                  >
                    <i className="fa-light fa-smile"></i>
                  </Button>
                </InputRightElement>
              )}
            </InputGroup>
            {props.enableEmojiPicker && showEmojiPicker && (
              <div
                style={{
                  position: "absolute",
                  zIndex: 1000,
                  top: "-100%",
                  left: "100%",
                }}
                className="emoji-picker-container"
              >
                <EmojiPicker
                  onEmojiClick={handleEmojiClick}
                  theme={colorMode === "dark" ? Theme.DARK : Theme.LIGHT}
                  width="100%"
                  emojiStyle={EmojiStyle.APPLE}
                  previewConfig={{
                    showPreview: false,
                  }}
                />
                <Button
                  size="sm"
                  variant="ghost"
                  position="absolute"
                  top="0"
                  right="0"
                  onClick={handleEmojiPickerClose}
                >
                  {colorMode === "dark" ? (
                    <i className="fa-sharp fa-regular fa-circle-xmark"></i>
                  ) : (
                    <i className="fa-sharp fa-solid fa-circle-xmark"></i>
                  )}
                </Button>
              </div>
            )}
          </div>
        )}
      />

      <Flex
        marginTop={0}
        flexDirection="row"
        background={bg}
        width="100%"
        mt={1.5}
        borderRadius="md"
        minHeight={props.tagsContainerHeight ?? 82}
        alignItems="flex-start"
      >
        {tags.length > 0 && (
          <Flex padding={2} flexWrap="wrap">
            {tags.map((tag, index) => (
              <Tag
                mr={1.5}
                mb={1.5}
                key={index}
                size="md"
                borderRadius="full"
                variant={"solid"}
                colorScheme={colorMode === "dark" ? "yellow" : "teal"}
              >
                <TagLabel>{tag}</TagLabel>
                <TagCloseButton onClick={() => removeTag(index)} />
              </Tag>
            ))}
          </Flex>
        )}
      </Flex>
      {multiTagFooter()}
      <input type="submit" value="Submit" style={{ display: "none" }} />
    </form>
  );
}

export default MultiTagInput;
