import { dateFormatterFromUtc, } from "@/common/utility/dateTimeUtil";
import { Box, Flex, HStack, useColorModeValue } from "@chakra-ui/react";
import { useState } from "react";
import { CustomInput } from "../forms";
import { useFlag } from "./flag-context";
import { CustomToast } from "@/common/toast/CustomToast";
import { useLanguage } from "../userprofile/language/lang-context";

function FlagNotes(props) {
    const [note, setNote] = useState('');
    const {
        state: { status, fetch },
        onAddNote
    } = useFlag();
    const {
        state: { lang },
    } = useLanguage();
    const { successToast } = CustomToast();
    const handleNote = async (note) => {
        if (note) {
            const flagNote = {
                content: note,
                followUpId: props?.flag?.id,
                userIdCreator: props?.user?.userId,
                author: props?.user?.name + ' ' + props?.user?.lastName,
                //createdDateUtc: moment.utc().format('YYYY-MM-DD HH:mm:ss') // This is filled through backend service..so it's not necessary to assign any value here
            };
            // console.log(flagNote);
            const response = await onAddNote(flagNote);
            if (response?.ok && !fetch) {
                successToast("Flags", "Successfully added a note");
                //  modalResponse(flag);
                // onClose();
            }
        }
    };

    return (

        <Flex
            boxShadow={'lg'}
            maxW={'md'}
            direction={{ base: 'column-reverse', md: 'row' }}
            width={'md'}
            rounded={'xl'}
            p={2}

            justifyContent={'space-between'}
            position={'relative'}
            bg={useColorModeValue('#F1F9F9', 'gray.800')}
            _after={{
                content: '""',
                position: 'absolute',
                height: '21px',
                width: '29px',
                left: '35px',
                top: '-10px',
                backgroundSize: 'cover',
            }}
            _before={{
                content: '""',
                position: 'absolute',
                zIndex: '-1',
                height: 'full',
                maxW: 'md',
                width: 'md',
                filter: 'blur(40px)',
                transform: 'scale(0.98)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                top: 0,
                left: 0,
            }}>

            <Flex
                direction={'column'}
                textAlign={'left'}
                justifyContent={'flex-start'}
                overflowY={"auto"}
                height={props.height}
                flexBasis="100%"
            >
                <Box mb={2} >
                    <CustomInput size="sm"
                        value={note}
                        placeholder={lang?.REVIEW_MYFLAGS_LEAVEANOTE || "leave a note and press enter"}
                        onChange={(e) => setNote(e.currentTarget.value)}
                        onKeyPress={(e) => { if (e.key === 'Enter') { handleNote(e.target.value); setNote(''); } }}
                    />
                </Box>
                {props && props?.flag?.notes?.slice().sort((a, b) => Number(new Date(b?.createdDateUtc)) - Number(new Date(a?.createdDateUtc))).map((note, idx) => (
                    <Box key={idx}>
                        <HStack fontWeight={'bold'} fontSize={14}>
                            <Box
                                fontWeight={'medium'}
                                color={'gray.500'}
                                float={"left"}>
                                {' '}
                                {note?.author ? note.author + ' at' : 'Anonymous '}

                            </Box>
                            <Box fontWeight={'medium'}
                                color={'gray.500'}
                                float={"right"}>
                                {' '}
                                {`${dateFormatterFromUtc(note?.createdDateUtc)}`}
                            </Box>
                        </HStack>
                        <Box fontWeight={'medium'}
                            fontSize={'15px'}
                            justifyContent={"flex-start"}
                            mb={2}
                            flexWrap={"wrap"}
                            wordBreak={"break-word"}>
                            {note?.content}
                        </Box>
                    </Box>
                ))}
            </Flex >
        </Flex>
    );
}
export default FlagNotes