import * as React from "react";
import { internalGet, internalPost } from "@/common/http/httpServices";
import { useRouter } from "next/router";

const NAME = "XMATTERS_INTEGRATION";
const TYPE_FETCH = `${NAME}_FETCH`;
const TYPE_ERROR = `${NAME}_ERROR`;
const TYPE_COMPLETE = `${NAME}_COMPLETE`;

const XmattersIntegrationContext = React.createContext<any | null>(null);

function xmattersIntegrationReducer(state, action) {
  switch (action.type) {
    case TYPE_FETCH: {
      return { ...state, fetch: true };
    }
    case TYPE_COMPLETE: {
      return { ...state, fetch: false, ...action?.payload };
    }
    case TYPE_ERROR: {
      return { ...state, fetch: false, ...action?.payload };
    }
    default: {
      throw new Error(`Unsupported action type: ${action.type}`);
    }
  }
}

function useXmattersIntegration() {
  const context = React.useContext(XmattersIntegrationContext);
  const router = useRouter();

  if (!context) {
    throw new Error(
      `useXmattersIntegration must be used within a XmattersIntegrationProvider`
    );
  }

  const refreshData = () => {
    router.replace(router.asPath);
  };

  const [state, dispatch] = context;

  const onSave = async (payload) => {
    dispatch({
      type: TYPE_FETCH,
      payload: { status: "pending" },
    });

    const init = {
      url: "/api/xmatters/pushEvent",
      body: payload,
    };

    const { response: res } = await internalPost(init).catch((error) =>
      dispatch({
        type: TYPE_ERROR,
        payload: { error },
      })
    );

    if (res?.status < 300) {
      const data = await res.json();
      const error = { errorMessage: data?.errorMessage };

      dispatch({
        type: TYPE_COMPLETE,
        payload: { status: "completed", error, integration: payload }
      });
    }

    return res;
  };

  return {
    state,
    dispatch,
    onSave
  };
}

function XmattersIntegrationProvider(props) {
  const [state, dispatch] = React.useReducer(xmattersIntegrationReducer, {
    fetch: false,
    error: {},
    integration: {},
  });
  const value = React.useMemo(() => [state, dispatch], [state]);

  return <XmattersIntegrationContext.Provider value={value} {...props} />;
}

export { XmattersIntegrationProvider, useXmattersIntegration };
