import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { CustomToast } from "@/common/toast/CustomToast";
import { FlagProvider } from "./flag-context";
import FlagNotes from "./flag-notes";
import { useCurrentUser } from "@/services/useCurrentUser";
import { useFlagPost } from "@/services/useFlagPost";
const FlagNotesModal = ({ documentId, isOpen, onOpen, onClose }) => {
  const { data: currentUser } = useCurrentUser();
  const { data: existingFlaggedObject, isLoading: isLoadingFlag } = useFlagPost(
    { documentId, refreshInterval: 2000 }
  );
  const { successToast } = CustomToast();

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        size={"lg"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            Flag Notes
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={1} m={5}>
            {!isLoadingFlag && (
              <FlagProvider>
                <FlagNotes
                  {...{ flag: existingFlaggedObject[0] }}
                  {...{ user: currentUser }}
                  {...{ height: 400 }}
                />
              </FlagProvider>
            )}
          </ModalBody>

          {/* <ModalFooter>
                            <Button
                                colorScheme="messenger"
                                mr={3}
                            //isLoading={fetch}
                            // loadingText="Submitting..."
                            //onClick={save}
                            // isDisabled={isDisabled}
                            >
                                Save
                            </Button>
                            <Button onClick={() => onClose()}>Cancel</Button>
                        </ModalFooter> */}
        </ModalContent>
      </Modal>
    </>
  );
};

export default FlagNotesModal;
