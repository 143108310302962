import { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormLabel,
  FormControl,
  Input,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { CustomToast } from "@/common/toast/CustomToast";
import { useFlagFoldersApi } from "./flag-folders-context";
import { IFlagFolder, IFlagFolderModalProps } from "@/interfaces/IFlagFolder";
import { errorMessage } from "@/common/utility/errorMessage";
import { useLanguage } from "@/components/userprofile/language/lang-context";

const initialFlagFolder: IFlagFolder = {
  id: 0,
  name: "",
  haveFlags: false,
  createdDate: new Date(),
};

const FlagFoldersModal = ({
  data,
  isOpen,
  onClose,
  modalResponse
}: IFlagFolderModalProps) => {
  const {
    state: { fetch },
    onSearchFlagFolderName,
    onAddFlagFolder,
    onUpdateFlagFolder,
  } = useFlagFoldersApi();
  const { successToast } = CustomToast();

  const [isNew, setIsNew] = useState(false);
  const [flagFolder, setFlagFolder] = useState(initialFlagFolder);
  const [isLoading, setIsLoading] = useState(false);
  const [timerId, setTimerId] = useState(null);
  const [isValidName, setIsValidName] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const toFlagFolder = (data: IFlagFolder) => {
    const flag: IFlagFolder = {
      id: data.id || 0,
      name: data.name || "",
      haveFlags: data.haveFlags ?? false ? data.haveFlags : false,
      createdDate: data.createdDate || new Date(),
    };

    return flag;
  };
  const {
    state: { lang },
  } = useLanguage();

  useEffect(() => {
    setIsNew(Boolean(data && data.id) ? false : true);
    setFlagFolder(toFlagFolder(data));

    setIsLoading(false);
    setIsValidName(false);
  }, [data]);

  const updateFlagFolder = (property: keyof IFlagFolder, value: any) => {
    setFlagFolder((prevState) => {
      return {
        ...prevState,
        [property]: value,
      };
    });
  };

  const handleSearch = async (event) => {
    setIsLoading(true);
    setIsSearching(true);
    const searchTerm = event.target.value;
    updateFlagFolder("name", searchTerm);

    clearTimeout(timerId);
    const newTimerId = setTimeout(async () => {
      if (searchTerm.length > 0) {
        const result = await onSearchFlagFolderName({
          name: searchTerm.trim(),
        });
        if (result.response?.ok && result.data?.isAvailable) {
          setIsValidName(false);
        } else {
          setIsValidName(true);
        }
        setIsLoading(false);
      }
    }, 3000);
    setTimerId(newTimerId);
    setIsSearching(false);
  };

  const save = async () => {
    setIsSubmitting(true);
    if (isNew) {
      const response = await onAddFlagFolder({ name: flagFolder.name });
      if (response?.ok) {
        successToast("Flag Folders", lang?.FLAG_FOLDER_CREATE_SUCCESS || "Successfully added");
        modalResponse(flagFolder);
      }
    } else {
      const response = await onUpdateFlagFolder(flagFolder);
      if (response?.ok) {
        successToast("Flag Folders", lang?.FLAG_FOLDER_UPDATE_SUCCESS || "Successfully updated");
        modalResponse(flagFolder);
      }
    }
    setIsSubmitting(false);
  };

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {isNew ? lang?.FLAG_FOLDER_CREATE_TITLE || "Create Flag Folder" : lang?.FLAG_FOLDER_EDIT_TITLE || "Update Flag folder"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={1}>
          <FormControl mt={1} isRequired isInvalid={!flagFolder.name}>
            <FormLabel>{lang?.FLAG_FOLDER_NAME_TITLE || "Folder name"}</FormLabel>
            <Input
              placeholder={lang?.FLAG_FOLDER_NAME_TITLE || "Folder name"}
              value={flagFolder.name}
              onChange={handleSearch}
            />
            {errorMessage(true, "name", lang?.FLAGACTIONS_FLAGFOLDER_REQUIRED)}
            {isValidName && flagFolder.name?.length > 0 && !isLoading && (
              <Text fontSize="xs" color="green">
                {" "}
                <i className="fa-solid fa-check" aria-hidden="true" /> {lang?.MESSAGE_FLAGFOLDER_NAME_AVAILABLE || "Flag folder name is available"}
              </Text>
            )}
            {!isValidName && flagFolder.name?.length > 0 && !isLoading && (
              <Text fontSize="xs" color="red">
                <i
                  className="fa-solid fa-triangle-exclamation"
                  aria-hidden="true"
                />{" "}
                {lang?.MESSAGE_FLAGFOLDER_NAME_ALREADY_EXISTS || "Flag folder name already exists"}
              </Text>
            )}
            {isLoading && <Spinner size={"sm"} />}
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            isDisabled={flagFolder.name === "" || !isValidName || isLoading}
            colorScheme="messenger"
            mr={3}
            isLoading={fetch && isSubmitting}
            loadingText="Submitting..."
            onClick={save}
          >
            {lang?.SAVE || "Save"}
          </Button>
          <Button colorScheme="gray" onClick={onClose}>{lang?.CANCEL || "Cancel"}</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FlagFoldersModal;
