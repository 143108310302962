import { FormErrorMessage } from "@chakra-ui/react";

export const errorMessage = (
  isRequired: boolean,
  label: string,
  message?: string
) => {
  if (message) {
    return <FormErrorMessage fontSize="sm">{message}</FormErrorMessage>;
  }

  if (isRequired) {
    return (
      <FormErrorMessage fontSize="sm">
        {label.toLowerCase()} is required
      </FormErrorMessage>
    );
  }
};
