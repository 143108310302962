import {
  SimpleGrid,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from "@chakra-ui/react";
import styled, { withTheme } from "styled-components";

export const CustomFormLabel = ({ label }) => (
  <FormLabel fontSize="12px">{label}</FormLabel>
);

const CustomInputTheme = styled(Input)`
  padding: 0.2em 0.4em;
  border: ${({ theme }) => `1px solid ${theme.input.borderColor}`};
  border-radius: 0.4285rem;
  background-color: transparent;
  transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
  :focus {
    background-color: transparent;
    border-color: ${({ theme }) => theme.input.focusBorderColor};
    box-shadow: none;
    outline: 0;
  }
  width: 100%;
`;

const InputTheme = ({ theme, ...rest }) => <CustomInputTheme {...rest} />;

export const CustomInput = withTheme(InputTheme);
