import { NextApiRequest, NextApiResponse } from "next";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

interface IInternalPos {
  url: string;
  body?: any;
}

export const internalPost = async ({ url, body }: IInternalPos) => {
  let response = null;
  // let data = null;
  let error = null;

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Signal-App":"v4"
  };

  let bodyData = null;
  if (Array.isArray(body)) {
    bodyData = JSON.stringify(body);
  } else if (body !== null && typeof body === "object") {
    bodyData = JSON.stringify(
      Object.fromEntries(
        Object.entries(body).filter(([key, value]) => value !== null)
      )
    );
  }

  let data;
  try {
    response = await fetch(url, {
      method: "POST",
      headers,
      body: bodyData,
    });

    if (response.status === 200) {
      // only deserialize when is OK:200 as we can assume backend API will come with a valid json
      //data = await response.json(); // consume/read response's' body here ...only once and returned the body as json
      data = null; // TEMP: in order to not break existing code as reponse's body is being consumed after retuned from here as part of a hook/service....which is wrong!
    } else {
      // otherwise we deserialize into text in case backend API responded with a 500 or with custom error/exception in case we need to get more details
      const bodyString = await response.text();
      // we build a valid json to be returned so all of the UI API that uses 'internalPost' can properly handle a json response
      data = {
        failed: true,
        status: response.status,
        statusText: response.statusText,
        url: response.url,
        responseString: bodyString,
      };
      console.log(
        "[internalPost] will return the following custom error json object===>",
        data
      );
      error = response.statusText;
    }
  } catch (err) {
    error = err;
    console.error(err);
  }

  return {
    response,
    data,
    error,
  };
};

export const internalGet = async ({ url }) => {
  let response = null;
  // let data = null;
  let error = null;

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Signal-App":"v4"
  };

  let data;
  try {
    response = await fetch(url, {
      method: "GET",
      headers,
    });

    if (response.status === 200) {
      // only deserialize when is OK:200 as we can assume backend API will come with a valid json
      //data = await response.json(); // consume/read response's' body here ...only once and returned the body as json
      data = null; // TEMP: in order to not break existing code as reponse's body is being consumed after returned from here as aprt of hook/service....which is wrong!
    } else {
      // otherwise we deserialize into text in case backend API responded with a 500 or with custom error/exception in case we need to get more details
      const bodyString = await response.text();
      // we build a valid json to be returned so all of the UI API that uses 'internalGet' can properly handle a json response
      data = {
        failed: true,
        status: response.status,
        statusText: response.statusText,
        url: response.url,
        responseString: bodyString,
      };
      console.log(
        "[internalGet] will return the following custom error json object===>",
        data
      );
      error = response.statusText;
    }
  } catch (err) {
    error = err;
    console.error(err);
  }

  return {
    response,
    data,
    error,
  };
};

export const internalGetWithHeaders = async ({ url, headers }) => {
  let response = null;  
  let error = null;
  
  let data;
  try {
    response = await fetch(url, {
      method: "GET",
      headers,
    });

    if (response.status === 200) {
      // only deserialize when is OK:200 as we can assume backend API will come with a valid json
      //data = await response.json(); // consume/read response's' body here ...only once and returned the body as json
      data = null; // TEMP: in order to not break existing code as reponse's body is being consumed after returned from here as aprt of hook/service....which is wrong!
    } else {
      // otherwise we deserialize into text in case backend API responded with a 500 or with custom error/exception in case we need to get more details
      const bodyString = await response.text();
      // we build a valid json to be returned so all of the UI API that uses 'internalGet' can properly handle a json response
      data = {
        failed: true,
        status: response.status,
        statusText: response.statusText,
        url: response.url,
        responseString: bodyString,
      };
      console.log(
        "[internalGet] will return the following custom error json object===>",
        data
      );
      error = response.statusText;
    }
  } catch (err) {
    error = err;
    console.error(err);
  }

  return {
    response,
    data,
    error,
  };
};

export const internalPut = async ({ url, body }: IInternalPos) => {
  let response = null;  
  let error = null;

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Signal-App":"v4"
  };

  let bodyData = null;
  if (Array.isArray(body)) {
    bodyData = JSON.stringify(body);
  } else if (body !== null && typeof body === "object") {
    bodyData = JSON.stringify(
      Object.fromEntries(
        Object.entries(body).filter(([key, value]) => value !== null)
      )
    );
  }

  let data;
  try {
    response = await fetch(url, {
      method: "PUT",
      headers,
      body: bodyData,
    });
    // data = await response.json();

    if (response.status === 200) {
      // only deserialize when is OK:200 as we can assume backend API will come with a valid json
      //data = await response.json(); // consume/read response's' body here ...only once and returned the body as json
      data = null; // TEMP: in order to not break existing code as reponse's body is being consumed after returned from here as aprt of hook/service....which is wrong!
    } else {
      // otherwise we deserialize into text in case backend API responded with a 500 or with custom error/exception in case we need to get more details
      const bodyString = await response.text();
      // we build a valid json to be returned so all of the UI API that uses 'internalGet' can properly handle a json response
      data = {
        failed: true,
        status: response.status,
        statusText: response.statusText,
        url: response.url,
        responseString: bodyString,
      };
      console.log(
        "[internalPut] will return the following custom error json object===>",
        data
      );
      error = response.statusText;
    }
  } catch (err) {
    error = err;
    console.error(err);
  }

  return {
    response,
    data,
    error,
  };
};

export const internalDelete = async ({ url, body }: IInternalPos) => {
  let response = null;  
  let error = null;

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Signal-App":"v4"
  };

  let bodyData = null;
  if (Array.isArray(body)) {
    bodyData = JSON.stringify(body);
  } else if (body !== null && typeof body === "object") {
    bodyData = JSON.stringify(
      Object.fromEntries(
        Object.entries(body).filter(([key, value]) => value !== null)
      )
    );
  }

  try {
    response = await fetch(url, {
      method: "DELETE",
      headers,
      body: bodyData,
    });
    // data = await response.json();
  } catch (err) {
    error = err;
    console.error(err);
  }

  return {
    response,
    // data,
    error,
  };
};

export const httpFetch = async ({
  url,
  req,
  res,
  method = "GET",
  fetchPromise = false,
}) => {
  const cookies = req.cookies;
  const headers = getHeaders(
    cookies.token,
    cookies.organisationId,
    cookies.realm
  );

  const init = {
    method,
    headers,
    body: req.body ? JSON.stringify(req.body) : null,
  };

  if (fetchPromise && method.toLowerCase() === "post") {
    return fetch(`${publicRuntimeConfig.API_URL}${url}`, init);
  }

  return fetch(`${publicRuntimeConfig.API_URL}${url}`, {
    headers,
  });
};

type HeadersType = {
  Accept: string;
  "Content-Type": string;
  Authorization: string;
  "X-Organisation"?: string;
  "X-Realm"?: string;
  "Signal-App": string
};

const getHeaders = (token: string, organisationId: string, realm: string) => {
  let headers: HeadersType = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    "Signal-App":"v4"
  };

  if (realm) {
    headers["X-Realm"] = realm;
  }

  if (organisationId) {
    headers["X-Organisation"] = organisationId;
  }

  return headers;
};

export const httpGet = async ({ url, req, res }) => {
  let response = null;
  let data = null;
  let error = null;

  const cookies = req.cookies;
  const headers = getHeaders(
    cookies.token,
    cookies.organisationId,
    cookies.realm
  );

  try {
    response = await fetch(`${publicRuntimeConfig.API_URL}${url}`, {
      headers,
    });
    if (response.status === 200) {
      // only deserialize when is OK:200 as we can assume backend API will come with a valid json
      data = await response.json();
    } else {
      // otherwise we deserialize into text in case backend API responded with a 500 or with custom error/exception in case we need to get more details
      const bodyString = await response.text();
      // we build a valid json to be returned so all of the UI API that uses 'httpGet' can properly handle a json response
      data = {
        failed: true,
        status: response.status,
        statusText: response.statusText,
        url: response.url,
        responseString: bodyString,
      };
      console.log(
        "[httpGet] will return the following custom error json object===>",
        data
      );
      error = response.statusText;
    }
  } catch (err) {
    error = err;
    console.error(err);
  }

  return {
    response,
    data,
    error,
  };
};

export const httpPost = async ({ url, req, res }) => {
  let response = null;
  let data = null;
  let error = null;

  const cookies = req.cookies;
  const headers = getHeaders(
    cookies.token,
    cookies.organisationId,
    cookies.realm
  );
  const method = "POST";

  const init = {
    method,
    headers,
    body: req.body ? JSON.stringify(req.body) : null,
  };

  const httpUrl = `${publicRuntimeConfig.API_URL}${url}`;

  try {
    response = await fetch(httpUrl, init);
    if (response.status === 200) {
      try {
        // Try to deserialize the response as JSON
        data = await response.json();
      } catch (error) {
        data = null;
      }
    } else {
      // otherwise we deserialize into text in case backend API responded with a 500 or with custom error/exception in case we need to get more details
      const bodyString = await response.text();
      // we build a valid json to be returned so all of the UI API that uses 'httpPost' can properly handle a json response
      data = {
        failed: true,
        status: response.status,
        statusText: response.statusText,
        url: response.url,
        responseString: bodyString,
      };
      console.log(
        "[httpPost] will return the following custom error json object===>",
        data
      );
      error = response.statusText;
    }
  } catch (err) {
    error = err;
    console.error(err);
  }

  return {
    response,
    data,
    error,
  };
};

interface QueryParams {
  [key: string]: string | number | boolean;
}

export const httpPut = async ({ url, req, res }) => {
  let response = null;
  let data = null;
  let error = null;

  const cookies = req.cookies;
  const headers = getHeaders(
    cookies.token,
    cookies.organisationId,
    cookies.realm
  );
  const method = "PUT";

  const init = {
    method,
    headers,
    body: req.body ? JSON.stringify(req.body) : null,
  };

  const httpUrl = `${publicRuntimeConfig.API_URL}${url}`;

  try {
    response = await fetch(httpUrl, init);
  } catch (err) {
    error = err;
    console.error(err);
  }

  return {
    response,
    data,
    error,
  };
};

export const httpDelete = async ({ url, req, res }) => {
  let response = null;
  let data = null;
  let error = null;

  const cookies = req.cookies;
  const headers = getHeaders(
    cookies.token,
    cookies.organisationId,
    cookies.realm
  );
  const method = "DELETE";

  const init = {
    method,
    headers,
    body: req.body ? JSON.stringify(req.body) : null,
  };

  const httpUrl = `${publicRuntimeConfig.API_URL}${url}`;

  try {
    response = await fetch(httpUrl, init);
  } catch (err) {
    error = err;
    console.error(err);
  }

  return {
    response,
    data,
    error,
  };
};

export async function httpGetHandler(
  req: NextApiRequest,
  res: NextApiResponse,
  url: string,
  queryParams?: QueryParams
) {
  let queryString = "";
  if (queryParams && Object.keys(queryParams).length > 0) {
    queryString = `?${Object.entries(queryParams)
      .map(([key, value]) => `${key}=${value}`)
      .join("&")}`;
  }

  const { data, response } = await httpGet({
    url: `${url}${queryString}`,
    req,
    res,
  });

  return res.status(response.status).json(data || {});
}

export const httpGetNoSession = async ({ url, req, res }) => {
  let response = null;
  let data = null;
  let error = null;

  try {
    response = await fetch(`${publicRuntimeConfig.API_URL}${url}`, {});
    if (response.status === 200) {
      // only deserialize when is OK:200 as we can assume backend API will come with a valid json
      data = await response.json();
    } else {
      // otherwise we deserialize into text in case backend API responded with a 500 or with custom error/exception in case we need to get more details
      const bodyString = await response.text();
      // we build a valid json to be returned so all of the UI API that uses 'httpGet' can properly handle a json response
      data = {
        failed: true,
        status: response.status,
        statusText: response.statusText,
        url: response.url,
        responseString: bodyString,
      };
      console.log(
        "[httpGet] will return the following custom error json object===>",
        data
      );
      error = response.statusText;
    }
  } catch (err) {
    error = err;
    console.error(err);
  }

  return {
    response,
    data,
    error,
  };
};
