import useSWR from "swr";

//const fetcher = (...args) => fetch(...args).then((res) => res.json());
const fetcher = (url, ...rest) => fetch(url, ...rest).then((res) => res.json());

export function useUsers() {
  const { data, error, isLoading } = useSWR(() => {
    return "/api/users";
  }, fetcher);

  return {
    data,
    isLoading,
    isError: error,
  };
}

export function useGroups() {
  const { data, error, isLoading } = useSWR(() => {
    return "/api/groups";
  }, fetcher);

  return {
    data,
    isLoading,
    isError: error,
  };
}