import { IOrganisationSettingValues } from "@/interfaces/IOrganisationSettingValues";
import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";

const OrganisationSettingsContext = React.createContext<any | null>(null);
const organisationFeaturesKey = "organisationFeatures";

export function useOrganisationSettings() {
  return useContext(OrganisationSettingsContext);
}

export function OrganisationSettingsProvider({ children }) {
  const [dataCache, setDataCache] = useState({});
  const [isFetchOrganisationFeatures, setFetchOrganisationFeatures] =
    useState(true);

  const getOrganisationFeatures = async () => {
    try {
      const response = await fetch(`/api/organisation`);
      if (response.ok) {
        const features = await response.json();
        return features;
      }
      return [];
    } catch (error) {
      console.error("Error fetching organisation features:", error);
      return [];
    }
  };

  const fetchOrganisationFeatures = async () => {
    if (!dataCache[organisationFeaturesKey]) {
      const features = await getOrganisationFeatures();
      if (features) {
        setDataCache((prevCache) => ({
          ...prevCache,
          [organisationFeaturesKey]: Object.freeze<
            IOrganisationSettingValues[]
          >(features?.settings), //get organisation settings
        }));
      }
    }
  };

  const updateOrganisationAndFeatures = async () => {
    setDataCache((prevCache) => {
      const updatedCache = { ...prevCache };
      delete updatedCache[organisationFeaturesKey];
      return updatedCache;
    });

    setFetchOrganisationFeatures(true);
  };

  //get all organisation settings with values
  const allFeatures = () => {
    return dataCache[organisationFeaturesKey] || [];
  };

  //get only enabled features return only ids
  const features = () => {
    if (dataCache[organisationFeaturesKey]) {
      const allEnabledFeatures = dataCache[organisationFeaturesKey].filter(
        (feature: IOrganisationSettingValues) =>
          feature.value?.toLowerCase() === "true"
      );
      const features = allEnabledFeatures.map(
        (feature: IOrganisationSettingValues) => feature.id
      );
      return features;
    }
    return [];
  };

  //check feature by id if enabled/disabled return true/false
  const hasFeature = (id: string) => {
    const hasFeature =
      dataCache[organisationFeaturesKey]?.some(
        (feature: IOrganisationSettingValues) =>
          feature.id === id && feature.value?.toLowerCase() === "true"
      ) || false;
    return hasFeature;
  };

  const contextValue = useMemo(
    () => ({
      dataCache,
      fetchOrganisationFeatures,
      updateOrganisationAndFeatures,
      allFeatures,
      features,
      hasFeature,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataCache, fetchOrganisationFeatures, updateOrganisationAndFeatures]
  );

  useEffect(() => {
    if (isFetchOrganisationFeatures) {
      fetchOrganisationFeatures();
      setFetchOrganisationFeatures(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchOrganisationFeatures]);

  return (
    <OrganisationSettingsContext.Provider value={contextValue}>
      {children}
    </OrganisationSettingsContext.Provider>
  );
}
