import {
    differenceInMinutes,
    differenceInHours,
    differenceInDays,
    differenceInWeeks,
    differenceInMonths,
    format,
    isDate,
} from "date-fns";

export const timeDiff = (value) => formatTimeDiff(new Date(value));

export const dateFormatter = ({ value, formatType }) => {
    if (value && isDateObject(value) && isDate(value)) {
        return format(value, formatType);
    } else if (value && isStringObject(value) && isValidDate(value)) {
        const truncatedDateString = value.replace(/\.\d+/, ""); // Remove extra digits after milliseconds
        return format(new Date(truncatedDateString), formatType);
    }

    return "";
};

export const dateFormatterFromUtc = (value) => {
    if (value && isDateObject(value) && isDate(value)) {
        const createdDate = new Date(value);
        const formattedTime = createdDate.toLocaleTimeString(undefined, {
            timeZone: 'UTC',
            timeStyle: 'short',
        });
        const formattedDate = createdDate.toLocaleDateString(undefined, {
            dateStyle: 'medium',
        });
        const formattedDateTime = `${formattedTime}, ${formattedDate}`;
        return formattedDateTime;
    } else if (value && isStringObject(value) && isValidDate(value)) {
        const truncatedDateString = value.replace(/\.\d+/, ""); // Remove extra digits after milliseconds
        const createdDate = new Date(truncatedDateString);
        const formattedTime = createdDate.toLocaleTimeString(undefined, {
            timeZone: 'UTC',
            timeStyle: 'short',
        });
        const formattedDate = createdDate.toLocaleDateString(undefined, {
            dateStyle: 'medium',
        });
        const formattedDateTime = `${formattedTime}, ${formattedDate}`;
        return formattedDateTime;
    }

    return "";
};

const isValidDate = (dateString) => {
    if (!dateString) {
        return false;
    }
    const correctedDateString = dateString.replace(/\.\d+/, ""); // Remove extra digits after milliseconds
    return isDate(new Date(correctedDateString));
};

const isDateObject = (value) => {
    return value instanceof Date;
};

const isStringObject = (value) => {
    return typeof value === "string";
};

// define a function to format the time difference
function formatTimeDiff(timestamp) {
    const currentDate = new Date();
    const insertedDate = new Date(timestamp);
    const diffInMinutes = differenceInMinutes(currentDate, insertedDate);
    if (diffInMinutes < 1) {
        return "just now";
    } else if (diffInMinutes == 1) {
        return `${diffInMinutes} min ago`;
    } else if (diffInMinutes < 60) {
        return `${diffInMinutes} mins ago`;
    } else {
        const diffInHours = differenceInHours(currentDate, insertedDate);
        if (diffInHours == 1) {
            return `${diffInHours} hour ago`;
        } else if (diffInHours < 24) {
            return `${diffInHours} hours ago`;
        } else {
            const diffInDays = differenceInDays(currentDate, insertedDate);
            if (diffInDays == 1) {
                return `${diffInDays} day ago`;
            } else if (diffInDays < 7) {
                return `${diffInDays} days ago`;
            } else {
                const diffInWeeks = differenceInWeeks(currentDate, insertedDate);
                if (diffInWeeks == 1) {
                    return `${diffInWeeks} week ago`;
                } else if (diffInWeeks <= 4) {
                    return `${diffInWeeks} weeks ago`;
                } else {
                    const diffInMonths = differenceInMonths(currentDate, insertedDate);
                    if (diffInMonths == 1) {
                        return `${diffInMonths} month ago`;
                    }
                    return `${diffInMonths} months ago`;
                }
            }
        }
    }
}
