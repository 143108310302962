import * as React from "react";
import { useCallback } from 'react';
import { httpGet, internalGet } from "@/common/http/httpServices";

const type = "GET_LANG";

const LangContext = React.createContext<any | null>(null);

function langReducer(state, action) {
    switch (action.type) {
        case type: {
            return { ...state, ...action?.payload };
        }
        default: {
            throw new Error(`Unsupported action type: ${action.type}`);
        }
    }
}

function useLanguage() {
    const context = React.useContext(LangContext);

    if (!context) {
        throw new Error(`useLanguage must be used within a LanguageProvider`);
    }

    const [state, dispatch] = context;

    const onLoadLanguage = useCallback(async () => {
        try {
            const menuLang = localStorage.getItem("locale") || "en";

            const { response } = await internalGet({ url: `/api/locale/${menuLang}` });
            const lang = await response.json();

            if (typeof window !== "undefined") {
                localStorage.setItem("locale", menuLang);
            }
            dispatch({ type, payload: { lang, locale: menuLang } });
        } catch (error) {
            dispatch({ type, payload: { error } });
        }
    }, [dispatch]);

    React.useEffect(() => {
        if (state.locale === null && typeof window !== "undefined") {
            // const savedLocale = localStorage.getItem("locale") || "en";           
            onLoadLanguage();
        }
    }, [state.locale, onLoadLanguage]);

    const onFetchLanguage = async (e: React.FormEvent) => {
        const currentTarget = e?.currentTarget as HTMLSelectElement;
        const locale = currentTarget?.value;
        localStorage.setItem("locale", locale);
        onLoadLanguage();
    };

    return {
        state,
        dispatch,
        onFetchLanguage,
        onLoadLanguage,
    };
}

function LanguageProvider(props) {
    const [state, dispatch] = React.useReducer(langReducer, {
        lang: {},
        locale: null,
    });

    const value = React.useMemo(() => [state, dispatch], [state]);

    return <LangContext.Provider value={value} {...props} />;
}


export { LanguageProvider, useLanguage };
