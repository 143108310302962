import React, { useCallback, useEffect, useState } from "react";
import Image from "next/image";
import { Flex, Stack, Text, useDisclosure } from "@chakra-ui/react";
import { IPostToolbarProps } from "@/interfaces/IPostModalProps";
import EverbridgeIntegrationModal from "@/components/integrations/everbridge-integration-modal";
import XmattersIntegrationModal from "@/components/integrations/xmatters-integration-modal";
import NogginIntegrationModal from "@/components/integrations/noggin-integration-modal";
import { IEverbridgeRiskEvent } from "@/interfaces/IEverbridgeIntegration";
import { IXMattersRiskEvent } from "@/interfaces/IXMattersIntegration";
import {
  INogginRiskEvent,
  INogginAlertSettings,
} from "@/interfaces/INogginIntegration";
import { EverbridgeIntegrationProvider } from "@/components/integrations/everbridge-integration-context";
import { XmattersIntegrationProvider } from "@/components/integrations/xmatters-integration-context";
import { NogginIntegrationProvider } from "@/components/integrations/noggin-integration-context";
import { RodrikIcon } from "@/components/icons/rodrik-icon";
import { useLivestreamsApi } from "./post-modal-context";
import { CustomToast } from "@/common/toast/CustomToast";
import ConfirmDialog from "@/common/confirm-dialog/ConfirmDialog";
import { FlagProvider } from "@/components/flags/flag-context";
import FlagActionModal from "@/components/flags/flag-action-modal";
import { ToolbarRowWrapper } from "./post-modal-styled";
import { useOrganisationSettings } from "@/common/context/organisation-settings-context";
import FlagNotesModal from "@/components/flags/flag-notes-modal";
import BrieferPostModal from "../briefer-post-modal";
import Constants, {
  RodrikIconLookup,
  RodrikModelLookup,
} from "@/common/constants";
import { fetchRiskEvent } from "@/services/useEverbridge";

const PostToolbar = ({
  translateTo,
  onClose,
  ...props
}: IPostToolbarProps & { translateTo: () => void; onClose: () => void }) => {
  const { onDeletePost } = useLivestreamsApi();
  const { successToast, errorToast } = CustomToast();
  const [isOpen, setIsOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogAction, setDialogAction] = useState("Activate");
  const { hasFeature } = useOrganisationSettings();
  const [isFlagOpen, setIsFlagOpen] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [hasRiskEvent, setHasRiskEvent] = useState(false);

  const [translateButtonTitle, setTranslateButtonTitle] = useState(
    props.translateButtonTitle
  );
  const [isReadOnly, setIsReadOnly] = useState(
    props.user?.roleName == Constants.ROLE_READ_ONLY
  );

  const {
    isOpen: isOpenFlagNotes,
    onOpen: onOpenFlagNotes,
    onClose: onCloseFlagNotes,
  } = useDisclosure();
  const {
    isOpen: isOpenEverbridge,
    onOpen: onOpenEverbridge,
    onClose: onCloseEverbridge,
  } = useDisclosure();

  const {
    isOpen: isOpenXMatters,
    onOpen: onOpenXMatters,
    onClose: onCloseXMatters,
  } = useDisclosure();

  const {
    isOpen: isOpenNoggin,
    onOpen: onOpenNoggin,
    onClose: onCloseNoggin,
  } = useDisclosure();

  const {
    isOpen: isOpenBriefer,
    onOpen: onOpenBriefer,
    onClose: onCloseBriefer,
  } = useDisclosure();

  const toEverbridgeRiskEvent = (post: any) => {
    const initialRiskEvent: IEverbridgeRiskEvent = {
      eventId: "",
      postId: post?.uid,
      searchId: post?.searchUid,
      status: null,
      urgency: null,
      type: null,
      severity: null,
      scope: null,
      certainty: null,
      headline: "",
      web: post?.url,
      description: "",
      categories: [],
      subCategory: null,
      areaDescription: "",
      circle: "",
      includePostUrl: false,
      isPost: true,
      allowDerivedLocation: false,
      postedDate: post?.publishDate,
      postUniqueId: post?.uniqueId,
    };

    return initialRiskEvent;
  };

  const toXMattersRiskEvent = (post: any) => {
    const initialRiskEvent: IXMattersRiskEvent = {
      id: post?.uid,
      searchId: post?.searchUid,
      alertTitle: "",
      source: post?.sourceType,
      eventDescription: "",
      includePostUrl: false,
      isPost: true,
      priority: "",
    };

    return initialRiskEvent;
  };

  const toNogginRiskEvent = (post: any) => {
    const initialRiskEvent: INogginRiskEvent = {
      id: post?.uniqueId,
      title: "",
      description: `An incident automatically created by the signal alert process due to a result matching criteria of the `,
      authorId: post?.authorId,
      authorName: post?.authorName,
      scope: "",
      severity: "",
      urgency: "",
      content: "",
      insertedDate: new Date(),
      publishedDate: new Date(post?.publishDate),
      searchId: post?.searchUid,
      authorUrl: post?.authorUrl,
      authorImageUrl: post?.authorImageUrl,
      postUrl: post?.url || "",
      isPost: true,
    };

    if (post?.geoLatitude && post?.geoLongitude) {
      initialRiskEvent.location = {
        type: "Point",
        coordinates: [post?.geoLongitude, post?.geoLatitude],
      };
    }

    return initialRiskEvent;
  };

  let post = props.post;

  const transformText = (value: string) => {
    return value?.charAt(0).toUpperCase() + value?.slice(1);
  };

  const setFlag = (setFlag: boolean) => {
    // Place code here to open the Flag Dialog
  };
  // const flagModalResponse = (data: any) => {
  //   //setRefetch(true);
  //   setIsFlagOpen(data);
  // };
  const openNotes = () => {
    // Place code here to open the Notes Dialog
  };

  const deletePost = () => {
    // Place code here to Delete Post
  };

  const viewOnMap = () => {
    // Place code here to View on Map
  };

  const modalReponseFromEverbridge = (data: any) => {
    if (data) {
      //get response
      setHasRiskEvent(true);
    }
  };

  const openDialog = (icon: string, title: string, onClick: () => void) => {
    return (
      <>
        {post?.url !== "" ? (
          <a href={post?.url} title={transformText(title)} target="_blank">
            <Flex flexDirection="row" marginBottom={"7px"}>
              <Image
                src={icon}
                alt="[image]"
                className={`icon-${title}`}
                width={18}
                height={18}
              />
              <Text fontSize="0.75rem" ml={"5px"}>
                {transformText(title)}
              </Text>
            </Flex>
          </a>
        ) : (
          <>
            <Flex flexDirection="row" marginBottom={"7px"}>
              <Image
                src={icon}
                alt="[image]"
                className={`icon-${title}`}
                width={18}
                height={18}
              />
              <Text fontSize="0.75rem" ml={"5px"}>
                {transformText(title)}
              </Text>
            </Flex>
          </>
        )}
      </>
    );
  };

  const rodrikLevel = () => {
    let sortedScores = post?.machineLearningScores?.sort(
      (a, b) => b.Score - a.Score
    );
    if (sortedScores?.length > 0) return sortedScores[0].Score;
    return 0;
  };

  const getModelScore = (modelId: string) => {
    let modelScore = 0;
    let result = post?.machineLearningScores?.find(
      (m) => m.ModelId === modelId
    );
    if (result) modelScore = result.Score;
    return modelScore;
  };

  const getSourceIconClass = (source) => {
    let sourceType = "";
    if (source) {
      sourceType = source.toLowerCase();
    }
    let iconCssClass = "";
    switch (sourceType) {
      case "twitter":
        iconCssClass = "fa-brands fa-x-twitter";
        break;
      case "rss":
        iconCssClass = "fas fa-rss";
        break;
      case "wordpress":
        iconCssClass = "fab fa-wordpress";
        break;
      case "youtube":
        iconCssClass = "fab fa-youtube-square";
        break;
      case "blog":
        iconCssClass = "fal fa-blog";
        break;
      case "news":
        iconCssClass = "fal fa-newspaper";
        break;
      case "boards":
        iconCssClass = "fal fa-comments";
        break;
      case "forum":
        iconCssClass = "fas fa-comments";
        break;
      case "reddit":
        iconCssClass = "fab fa-reddit-square";
        break;
      case "vk":
        iconCssClass = "fab fa-vk";
        break;
      case "cyber":
        iconCssClass = "icon-spiderweb-2";
        break;
      case "darkweb": // for backward compatibility
        iconCssClass = "icon-spiderweb-2";
        break;
      case "openweb":
        iconCssClass = "fas fa-globe";
        break;
      case "tor":
        iconCssClass = "fa-solid fa-onion";
        break;
      case "i2p":
        iconCssClass = "fas fa-ghost";
        break;
      case "zeronet":
        iconCssClass = "fab fa-creative-commons-zero";
        break;
      case "openbazaar":
        iconCssClass = "fas fa-store";
        break;
      case "telegram":
        iconCssClass = "fab fa-telegram";
        break;
      case "discord":
        iconCssClass = "fab fa-discord";
        break;
      case "irc":
        iconCssClass = "far fa-comment-alt";
        break;
      case "bing":
        iconCssClass = "icon-bing";
        break;
      case "data breach":
        iconCssClass = "fas fa-house-damage";
        break;
      case "whatsapp":
        iconCssClass = "fab fa-whatsapp";
        break;
      case "icq":
        iconCssClass = "fas fa-flower";
        break;
      case "ipaws":
        iconCssClass = "fad fa-thunderstorm";
        break;
      case "tiktok":
        iconCssClass = "fab fa-tiktok";
        break;
      case "facebook":
        iconCssClass = "fa fa-facebook";
        break;
      case "instagram":
        iconCssClass = "fa fa-instagram";
        break;
      default:
        iconCssClass = "fas fa-exclamation-circle";
        break;
    }

    return iconCssClass;
  };

  const handleDelete = async () => {
    openConfirmDialog("Are you sure you want to delete this post?", "Delete");
  };

  const handleConfirmDelete = async () => {
    try {
      await onDeletePost({ postId: post.uid, searchId: post.searchUid });

      successToast("Success!", `The post has been deleted.`);
      onClose();
    } catch (error) {
      errorToast("Error!", "Ooops! Something went wrong while deleting!");
    }
  };

  const handleConfirm = async () => {
    if (dialogAction == "Delete") handleConfirmDelete();
    setIsOpen(false);
  };

  const openConfirmDialog = (message, action) => {
    setDialogMessage(message);
    setDialogAction(action);
    setIsOpen(true);
  };

  const handleCloseDialog = () => {
    setIsOpen(false);
  };
  const handleIsOpenFlag = () => {
    if (!isFlagOpen) {
      setIsFlagOpen(true);
    }
  };

  const handleTranslation = async () => {
    setIsBusy(true);
    translateTo();
    await delay(1000);
    setTranslateButtonTitle(
      post?.isTranslated
        ? props.lang?.ADVANCED_POST_REMOVETRANSLATION || "Remove translation"
        : props.lang?.ADVANCED_POST_TRANSLATE || "Translate"
    );
    setIsBusy(false);
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const getRodrikIcon = (model) => {
    const m = RodrikIconLookup.find((item) => item.id === model);
    return m.icon;
  };

  return (
    <>
      {post &&
        !["4chan", "pastebin", "8kun", "hibp", "riskcenter"].includes(
          post?.sourceType
        ) && (
          <>
            {post?.url !== "" ? (
              <a href={post?.url} target="_blank">
                <ToolbarRowWrapper>
                  <i className={getSourceIconClass(post?.sourceType)} />
                  <Text ml={"7px"}>{transformText(post?.sourceType)}</Text>
                </ToolbarRowWrapper>
              </a>
            ) : (
              <>
                <ToolbarRowWrapper>
                  <i className={getSourceIconClass(post?.sourceType)} />
                  <Text ml={"7px"}>{transformText(post?.sourceType)}</Text>
                </ToolbarRowWrapper>
              </>
            )}
          </>
        )}
      {post &&
        post?.sourceType === "riskcenter" &&
        openDialog("/images/NC4-user.jpg", "riskcenter", () => {})}
      {post &&
        post?.sourceType === "4chan" &&
        openDialog("/images/4chanBubble.png", "4chan", () => {})}
      {post &&
        post?.sourceType === "pastebin" &&
        openDialog("/images/pastebinBubble.png", "pastebin", () => {})}
      {post &&
        post?.sourceType === "8kun" &&
        openDialog("/images/8kunBubble.png", "8kun", () => {})}
      {post &&
        post?.sourceType === "hibp" &&
        openDialog("/images/hibpBubble.png", "hibp", () => {})}

      {!isReadOnly && props.showFlagIcon !== false && (
        <>
          <a href="#" onClick={handleIsOpenFlag}>
            <ToolbarRowWrapper>
              <i
                className={"fa fa-flag fa-sm"}
                style={
                  post?.isFlagged
                    ? {
                        color: "#ffa500",
                      }
                    : {
                        color: "#4c4c4c",
                      }
                }
              />
              <Text ml={"10px"}>
                {!props.post?.isFlagged
                  ? props.lang?.ADVANCED_POST_FLAG || "Flag"
                  : props.lang?.ADVANCED_POST_VIEWFLAG || "View Flag"}
              </Text>
            </ToolbarRowWrapper>
          </a>
          {isFlagOpen && (
            <FlagProvider>
              <FlagActionModal
                isNew={!props.post?.isFlagged}
                documentId={props.post?.uid}
                searchId={props.post?.searchUid}
                uniqueId={props.post?.uniqueId}
                isOpen={isFlagOpen}
                onClose={() => {}}
                // onOpen={onOpenFlag}
                //onClose={handleDialogClose}
                // modalResponse={flagModalResponse}
              ></FlagActionModal>
            </FlagProvider>
          )}
        </>
      )}
      {!isReadOnly && props.showTranslateIcon !== false && (
        <>
          <a
            href="#"
            onClick={() => handleTranslation()}
            title={translateButtonTitle}
          >
            <ToolbarRowWrapper>
              <i
                className={
                  isBusy
                    ? "fa fa-spinner fa-sm fa-spin"
                    : "fa fa-globe-americas fa-sm"
                }
                style={{
                  color: props.post?.isTranslated ? "#12700f" : undefined,
                }}
              />
              <Text ml={"10px"}>{translateButtonTitle}</Text>
            </ToolbarRowWrapper>
          </a>
        </>
      )}
      {!isReadOnly && props.notesEnabled && props.post?.isFlagged && (
        <>
          <a href="#" onClick={() => onOpenFlagNotes()}>
            <ToolbarRowWrapper>
              <i className="fas fa-pencil-alt" />
              <Text ml={"10px"}>
                {props.lang?.ADVANCED_POST_OPEN_NOTES || "Open Notes"}
              </Text>
            </ToolbarRowWrapper>
          </a>

          {isOpenFlagNotes && (
            <FlagProvider>
              <FlagNotesModal
                documentId={props.post?.uid}
                isOpen={isOpenFlagNotes}
                onOpen={onOpenFlagNotes}
                onClose={onCloseFlagNotes}
              />
            </FlagProvider>
          )}
        </>
      )}

      {!isReadOnly && props.showDeleteIcon && !props.post?.isFlagged && (
        <>
          <a href="#" title="Delete" onClick={handleDelete}>
            <ToolbarRowWrapper>
              <i className="fas fa-trash-alt" />
              <Text ml={"10px"}>
                {props.lang?.ADVANCED_POST_DELETE || "Delete"}
              </Text>
            </ToolbarRowWrapper>
          </a>
        </>
      )}
      {!isReadOnly && props.post?.hasGeoLocation && props.showGeoIcon && (
        <>
          <a href="#" onClick={() => viewOnMap()}>
            <ToolbarRowWrapper>
              <i className="fa fa-map-marker-alt" />
              <Text ml={2}>
                {props.lang?.ADVANCED_POST_SHOWMAP || "Show on map"}
              </Text>
            </ToolbarRowWrapper>
          </a>
        </>
      )}
      {!isReadOnly &&
        props.showEverbridgeIcon &&
        hasFeature("EverbridgeIntegrationAvailable") && (
          <a href="#" onClick={() => onOpenEverbridge()}>
            <Flex flexDirection="row" marginBottom={"7px"}>
              <Image
                src="/images/everbridgeIcon.png"
                alt="[image]"
                className="icon-everbridge-post"
                height={15}
                width={15}
              />
              <Text fontSize="0.75rem" ml={2}>
                {hasRiskEvent || (props?.post && props?.post?.hasRiskEvent)
                  ? props.lang?.ADVANCED_POST_RISKEVENT_VCC_UPDATE ||
                    "Update risk event"
                  : props.lang?.ADVANCED_POST_RISKEVENT_VCC_CREATE ||
                    "Create risk event in VCC"}
              </Text>
            </Flex>
          </a>
        )}
      {isOpenEverbridge && (
        <EverbridgeIntegrationProvider>
          <EverbridgeIntegrationModal
            data={toEverbridgeRiskEvent(post)}
            post={post}
            isOpen={isOpenEverbridge}
            onOpen={onOpenEverbridge}
            onClose={onCloseEverbridge}
            modalResponse={modalReponseFromEverbridge}
          ></EverbridgeIntegrationModal>
        </EverbridgeIntegrationProvider>
      )}
      {!isReadOnly &&
        props.showXmattersIcon &&
        hasFeature("XMattersIntegrationAvailable") && (
          <a href="#" onClick={() => onOpenXMatters()}>
            <Flex flexDirection="row" marginBottom={"7px"}>
              <Image
                src="/images/xMattersIcon.png"
                alt="[image]"
                className="icon-everbridge-post"
                height={15}
                width={15}
              />
              <Text fontSize="0.75rem" ml={2}>
                {props.lang?.ADVANCED_POST_PUSHEVENT || "Push event to "}{" "}
                {"XMatters"}
              </Text>
            </Flex>
          </a>
        )}
      {isOpenXMatters && (
        <XmattersIntegrationProvider>
          <XmattersIntegrationModal
            data={toXMattersRiskEvent(post)}
            post={post}
            isOpen={isOpenXMatters}
            onOpen={onOpenXMatters}
            onClose={onCloseXMatters}
          ></XmattersIntegrationModal>
        </XmattersIntegrationProvider>
      )}
      {!isReadOnly &&
        props.showNogginIcon &&
        hasFeature("NogginIntegrationAvailable") && (
          <a href="#" onClick={() => onOpenNoggin()}>
            <Flex flexDirection="row" marginBottom={"7px"}>
              <Image
                src="/images/noggin.png"
                alt="[image]"
                className="icon-everbridge-post"
                height={15}
                width={15}
              />
              <Text fontSize="0.75rem" ml={2}>
                {props.lang?.ADVANCED_POST_PUSHEVENT || "Push event to"}{" "}
                {"Noggin"}
              </Text>
            </Flex>
          </a>
        )}
      {isOpenNoggin && (
        <NogginIntegrationProvider>
          <NogginIntegrationModal
            data={toNogginRiskEvent(post)}
            post={post}
            isOpen={isOpenNoggin}
            onOpen={onOpenNoggin}
            onClose={onCloseNoggin}
          ></NogginIntegrationModal>
        </NogginIntegrationProvider>
      )}
      {!isReadOnly && hasFeature("Briefer") && (
        <a href="#" title="Briefer" onClick={() => onOpenBriefer()}>
          <ToolbarRowWrapper>
            <i className="fa-solid fa-notebook" />
            <Text ml={"10px"}>
              {props.lang?.ADVANCED_POST_BRIEFER || "Briefer"}
            </Text>
          </ToolbarRowWrapper>
        </a>
      )}
      {rodrikLevel() > 0 && (
        <Stack direction="column">
          <Text mt={4} mb={-2} fontWeight="bold" fontSize="sm">
            {props.lang?.ADVANCED_POST_RELEVANCY || "Relevancy"}
          </Text>
          <Flex flexWrap="wrap" direction="row">
            {RodrikModelLookup.map((item) => (
              <RodrikIcon
                key={item.id}
                icon={getRodrikIcon(item.id)}
                score={getModelScore(item.id)}
                label={item.name}
              />
            ))}
          </Flex>
        </Stack>
      )}

      {isOpen && (
        <ConfirmDialog
          title="Confirmation"
          message={dialogMessage}
          onConfirm={handleConfirm}
          isOpen={isOpen}
          onClose={handleCloseDialog}
        />
      )}
      {isOpenBriefer && (
        <BrieferPostModal
          isOpen={isOpenBriefer}
          onClose={onCloseBriefer}
          isMulti={false}
          posts={[props.post]}
          setNextPage={() => {}}
          lang={props.lang}
        ></BrieferPostModal>
      )}
    </>
  );
};

export default PostToolbar;
