import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import { Spinner, useColorModeValue, useTheme } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useLanguage } from '../userprofile/language/lang-context';
import { ScrollableWrapper } from '@/common/div/wrappers';
import { useTeams } from '@/services/useTeams';

interface TeamsSelectProps {
    placeholder: string;
    onChange?: (option: any) => void;
    isDisabled: boolean;
    selectedValues: string[];
}

export interface Option {
    value: string;
    label: string;
}

const TeamsSelect = (props: TeamsSelectProps) => {
    const { placeholder, isDisabled, onChange } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [options, setOptions] = useState([]);
    const [allTeams, setAllTeams] = useState([]);
    const {
        state: { lang, locale },
    } = useLanguage();
    const theme = useTheme();
    const menuBackgroundColor = useColorModeValue(theme.colors.white, theme.colors.gray[700]);
    const optionColor = useColorModeValue(theme.colors.black, theme.colors.white);
    const reverseOptionColor = useColorModeValue(theme.colors.white, theme.colors.black);
    const onHoverOptionBackgoundColor = useColorModeValue(theme.colors.blue[500], theme.colors.blue[200]);
    const inputActiveBorderColor = useColorModeValue(theme.colors.blue[600], theme.colors.blue[300]);
    const placeHolderColor = useColorModeValue(theme.colors.black, theme.colors.whiteAlpha[300]);
    const selectedOptionBackgoundColor = useColorModeValue(theme.colors.blue[200], theme.colors.blue[400]);
    const bg = useColorModeValue(theme.colors.gray[100], theme.colors.whiteAlpha[50]);
    const activeBg = useColorModeValue(theme.colors.white[100], theme.colors.gray[700]);
    const hoverBg = useColorModeValue(theme.colors.gray[200], theme.colors.whiteAlpha[100]);

    const { data: teams, isLoading: isLoadingUsers, isError } = useTeams();

    const LoadingIndicator = props => {
        return (
            <Spinner size="sm" color={optionColor} />
        );
    };

    useEffect(() => {

        if (Array.isArray(teams)) {
            const options = teams
                .filter((team) => !props.selectedValues?.includes(team.id))
                .map((team) => ({
                    value: team.id,
                    label: team.name,
                }));

            setOptions(options);
            setAllTeams(teams);
        }

        setIsLoading(false);

    }, [teams, props.selectedValues]);

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <ChevronDownIcon fontSize="28px"></ChevronDownIcon>
            </components.DropdownIndicator>
        );
    };

    return (
        <ScrollableWrapper>
            <Select
                isDisabled={isLoading || isDisabled}
                isLoading={isLoading}
                placeholder={placeholder}
                options={options}
                onChange={(item) => { onChange(allTeams.find(x => x.id === item.value)) }}
                value={null}
                isSearchable
                components={{ IndicatorSeparator: () => null, DropdownIndicator: DropdownIndicator, LoadingIndicator: LoadingIndicator, }}
                styles={{
                    input: (provided, state) => ({
                        ...provided,
                        color: state.isDisabled ? 'darkgray' : optionColor,
                    }),
                    placeholder: (provided, state) => ({
                        ...provided,
                        color: state.isDisabled ? 'darkgray' : placeHolderColor,
                        paddingLeft: '2px'
                    }),
                    singleValue: (provided, state) => ({
                        ...provided,
                        color: state.isDisabled ? 'darkgray' : optionColor,
                        paddingLeft: '5px'
                    }),
                    control: (provided, state) => ({
                        ...provided,
                        background: state.menuIsOpen ? activeBg : bg,
                        boxShadow: 'initial',
                        borderWidth: state.menuIsOpen ? 2 : 0,
                        borderColor: inputActiveBorderColor,
                        borderRadius: '5px',
                    }),
                    menu: (provided, state) => ({
                        ...provided,
                        backgroundColor: menuBackgroundColor,
                        borderWidth: 1,
                    }),
                    option: (provided, state) => ({
                        ...provided,
                        zIndex: 2002,
                        backgroundColor: state.isSelected ? selectedOptionBackgoundColor : menuBackgroundColor,
                        color: state.isSelected ? reverseOptionColor : optionColor,
                        '&:hover': {
                            backgroundColor: onHoverOptionBackgoundColor,
                            color: reverseOptionColor
                        },
                    }),
                    dropdownIndicator: (provided) => ({
                        ...provided,
                        marginRight: '2px',
                        color: optionColor,
                    }),

                }}
            />
        </ScrollableWrapper>
    );
};

export default TeamsSelect;