import * as firebase from "firebase/app";
import {
  deleteToken,
  getMessaging,
  getToken,
  onMessage,
} from "firebase/messaging";
import getConfig from "next/config";
const { publicRuntimeConfig } = getConfig();
const firebaseCloudMessaging = {
  tokenInlocalStorage: async () => {
    return localStorage.getItem("fcmToken");
  },

  init: async function (firebaseConfig) {
    if (!firebase.getApps().length) {
      firebase.initializeApp({
        //signal
        apiKey: firebaseConfig.apiKey,
        authDomain: firebaseConfig.authDomain,
        projectId: firebaseConfig.projectId,
        storageBucket: firebaseConfig.storageBucket,
        messagingSenderId: firebaseConfig.messageSenderId,
        appId: firebaseConfig.appId,
        // apiKey: `${publicRuntimeConfig.FIREBASE_APIKEY}`,
        // authDomain: `${publicRuntimeConfig.FIREBASE_AUTHDOMAIN}`,
        // projectId: `${publicRuntimeConfig.FIREBASE_PROJECTID}`,
        // storageBucket: `${publicRuntimeConfig.FIREBASE_STORAGEBUCKET}`,
        // messagingSenderId: `${publicRuntimeConfig.FIREBASE_MESSAGESENDERID}`,
        // appId: `${publicRuntimeConfig.FIREBASE_APPID}`,
      });

      try {
        const tokenInlocalStorage = await this.tokenInlocalStorage();
        if (tokenInlocalStorage !== null) {
          return tokenInlocalStorage;
        }
        const messaging = getMessaging();
        // const status = await Notification.requestPermission();
        if (Notification.permission === "granted") {
          const serviceWorkerRegistration = await navigator.serviceWorker.ready;
          if (serviceWorkerRegistration.active?.state === "activated") {
            await deleteToken(messaging).then(async () => {
            //  console.log("vapid key", firebaseConfig.vapidKey);
              const fcm_token = await getToken(messaging, {
                vapidKey: firebaseConfig.vapidKey,
              });
              if (fcm_token) {
                localStorage.setItem("fcmToken", fcm_token);
                return fcm_token;
              }
            });
          }
        }
        // else if (
        //   Notification.permission === "denied" ||
        //   Notification.permission === "default"
        // ) {
        //   const permission = await Notification.requestPermission();
        //   // If the user accepts, let's create a notification
        //   if (permission === "granted") {
        //     const serviceWorkerRegistration = await navigator.serviceWorker
        //       .ready;
        //     if (serviceWorkerRegistration.active?.state === "activated") {
        //     await  deleteToken(messaging).then(async () => {
        //         const fcm_token = await getToken(messaging, {
        //           // vapidKey:
        //           //   "BP3NqmRGn-obT5qACKTzJJz269wmA2tMkHGP6ngYK9L0epRXEUsRGO0GxCJETrNt6OBbFfpKTScG529IbVhIoWw",
        //           vapidKey: `${publicRuntimeConfig.FIREBASE_VAPIDKEY}`,
        //         });
        //       if (fcm_token) {
        //         localStorage.setItem("fcmToken", fcm_token);
        //         return fcm_token;
        //       }
        //       })

        //     }
        //   } else if (permission === "denied") {
        //     // remove token from table.
        //   }
        // }
      } catch (error) {
        // console.error(error);
        return null;
      }
    } else {
      try {
        const tokenInlocalStorage = await this.tokenInlocalStorage();
        if (tokenInlocalStorage !== null) {
          return tokenInlocalStorage;
        }
        const messaging = getMessaging();
        if (Notification.permission === "granted") {
          const serviceWorkerRegistration = await navigator.serviceWorker.ready;
          if (serviceWorkerRegistration.active?.state === "activated") {
            await deleteToken(messaging).then(async () => {
              const fcm_token = await getToken(messaging, {
                vapidKey: firebaseConfig.vapidKey,
              });
              if (fcm_token) {
                localStorage.setItem("fcmToken", fcm_token);
                return fcm_token;
              }
            });
          }
        }
      } catch (error) {
        // console.error(error);
        return null;
      }
    }
  },
  getMessage: async function () {
    if (firebase.getApps().length > 0) {
      try {
        const messaging = getMessaging();
        const unsubscribe = onMessage(messaging, (payload) => {
         // console.log("Message Received", payload);
        });
        return () => {
          unsubscribe(); // Unsubscribe from the onMessage event on cleanup
        };
      } catch (error) {}
    }
  },
};
export default firebaseCloudMessaging;
