import { Stack, Flex } from '@chakra-ui/react';
import { useLanguage } from "@/components/userprofile/language/lang-context";
import MultiTagInput from "@/common/multi-tags-input/multiTagInput";
import { useEffect, useState } from 'react';
import { ITopic } from '@/interfaces/ITopic';
import React from 'react';
import TopicSelect from './topic-select';
import { useTwitterBlackList } from '@/services/useQueryBuilder';


function SimpleKeywordsInput({ tabIndex, terms, maxTags, maxLengthPerTag, colorScheme, enableEmojiPicker, showTopics, onChange, checkForBannedterms, placeholder, padding, validationRegex = null }) {
    const {
        state: { lang },
    } = useLanguage();

    const [selectedTerms, setSelectedTerms] = useState<string[]>(terms);
    const [selectedTopic, setSelectedTopic] = useState<ITopic>(null);
    const { blacklist } = useTwitterBlackList();

    const onTopicChange = (topic: ITopic) => {
        if (!topic || !topic.keywords) {
            setSelectedTerms([]);
            return;
        }

        // this will replace all existing keywords by the ones that come from the selected topic
        setSelectedTerms(topic.keywords);
        onChange(topic.keywords);
        setSelectedTopic(topic);

        // should we allow to merge keywords when selecting different topics?...if so, let's uncomment the below
        //console.log(`previous number of keywords: ${selectedTerms.length}`)
        //const uniqueKeywords = Array.from(new Set([...selectedTerms, ...topic.keywords]));
        //if (uniqueKeywords && uniqueKeywords.length <= maxTags)
        //    setSelectedTerms(uniqueKeywords);
        //else
        //    errorToast('', `too many terms...max. allowed is ${maxTags}`);
    };

    useEffect(() => {
        setSelectedTerms(terms);
    }, [terms, setSelectedTerms]);

    return (
        <Stack>
            <Flex width="100%">
                <Stack width={showTopics ? "72%" : "100%"} direction="column" spacing={1.5}>
                    <MultiTagInput tabIndex={tabIndex} maxLengthPerTag={maxLengthPerTag} terms={selectedTerms}
                        maxTags={maxTags}
                        colorScheme={colorScheme}
                        placeHolderText={placeholder}
                        enableEmojiPicker={enableEmojiPicker}
                        onTermsChange={(terms) => { setSelectedTerms(terms); onChange(terms); }}
                        bannedTermsList={checkForBannedterms ? blacklist : null}
                        tagsContainerHeight={0}
                        padding={padding}
                        validationRegex={ validationRegex}                        
                    />
                </Stack>
                {showTopics && (
                    <Stack width="28%" direction="column" spacing={1.5} ml={1.5}>
                        <TopicSelect
                            tabIndex={tabIndex}
                            placeholder="Autofill Topic"
                            onTopicChange={onTopicChange}
                            selectedTopic={selectedTopic} />
                    </Stack>
                )}
            </Flex>
        </Stack>
    );
}

export default React.memo(SimpleKeywordsInput);
