import Image from "next/image";
import { Box, Text, SimpleGrid, Flex, Card, Stack } from "@chakra-ui/react";
import parse from "html-react-parser";
import { timeDiff, dateFormatter } from "@/common/utility/dateTimeUtil";
import { useState } from "react";
import useTheme from "@/common/utility/useTheme";

export const MiniPost = ({ post }) => {
    const theme = useTheme();
    const ParsedText = ({ content }) => {
        const replaceEm = (node) => {
            if (node.type === "tag" && node.name === "em") {
                return;
                // return <StyledEm theme={theme}>{node.children[0].data}</StyledEm>;
            }
        };
        return <>{parse(content, { replace: replaceEm })}</>;
    };

    const placeholderAuthorImage = "/images/noavatar.jpg";
    const [authorImageError, setAuthorImageError] = useState(false);
    const handleAuthorImageError = () => {
        setAuthorImageError(true);
    };

    const placeholderPostImage = "/images/no_image.jpg";
    const [postImageError, setPostImageError] = useState(false);
    const handlePostImageError = () => {
        setPostImageError(true);
    };

    return (
        <Card mb={2} backgroundColor={theme.miniPost.backgroundColor}>
            <Stack mt={3} mb={3} ml={3} mr={3} flexDirection="row">
                <Box width="15%">
                    {post?.authorImageUrl ? (
                        <Image
                            alt=""
                            src={
                                authorImageError
                                    ? placeholderAuthorImage
                                    : post?.authorImageUrl
                            }
                            onError={handleAuthorImageError}
                            width={50}
                            height={50}
                        />
                    ) : (
                        <Image
                            alt=""
                            src={placeholderAuthorImage}
                            width={50}
                            height={50}
                        />
                    )}
                    <Text fontWeight="bold" fontSize="xs">
                        <ParsedText content={post?.authorName || post?.authorId || ""} />
                    </Text>
                    <Text fontSize="xs">
                        Date received <br />
                        {dateFormatter({
                            value: post?.insertedDate,
                            formatType: "dd MMM yyyy hh:mm:ss a",
                        })}
                    </Text>
                    <Text fontSize="xs">
                        {post?.publishDate
                            ? `(${timeDiff(post?.publishDate)})`
                            : `(${timeDiff(post?.insertedDate)})`}
                    </Text>
                </Box>
                <Box width="85%">
                    {post?.title && (
                        <Text fontWeight="bold" fontSize="0.2rem" mb={2}>
                            {post.title}
                        </Text>
                    )}
                    <Text fontSize={post?.content?.length > 500 ? "0.6em": "1.1em" }>
                        <ParsedText content={post?.content || ""} />
                    </Text>
                    {post?.imageUrl && (
                        <>
                            <br />
                            <Image
                                alt=""
                                src={postImageError ? placeholderPostImage : post?.imageUrl}
                                onError={handlePostImageError}
                                layout="responsive"
                                width={50}
                                height={50}
                            />
                        </>
                    )}
                </Box>
            </Stack>
        </Card>
    );
};
