import useSWR from "swr";

const fetcher = (key: string) => fetch(key).then((res) => res.json());

export const useAlertEventsById = (eventId) => {
  const { data, error, isLoading } = useSWR(
    `/api/alerts/events/alertEvent?eventId=${eventId}`,
    fetcher
  );

  return {
    data,
    error,
    isLoading,
  };
};
