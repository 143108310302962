import * as React from "react";
import { internalGet, internalPost } from "@/common/http/httpServices";
import { IDashboardProps } from "@/interfaces/IDashboardProps";

const NAME = "DASHBOARD";
const MENU_CREATED_TYPE = `${NAME}_MENU_CREATED`;
const MENU_UPDATED_TYPE = `${NAME}_MENU_UPDATED`;
const MENU_UPDATED_TYPE_COMPLETE = `${NAME}_MENU_UPDATED_TYPE_COMPLETE`;
const MENU_UPDATED_TYPE_ERROR = `${NAME}_ERROR`;

const SECTIONS_UPDATED = `${NAME}_SECTIONS_UPDATED`;
const SECTIONS_CLEARED = `${NAME}_SECTIONS_CLEARED`;
const TYPE_FETCH = `${NAME}_FETCH`;
const TYPE_ERROR = `${NAME}_ERROR`;
const TYPE_COMPLETE = `${NAME}_COMPLETE`;

const DashboardContext = React.createContext<any | null>(null);

function dashboardReducer(state, action) {
  switch (action.type) {
    case MENU_CREATED_TYPE: {
      return { ...state, ...action?.payload };
    }
    case MENU_UPDATED_TYPE: {
      return { ...state, ...action?.payload };
    }
    case MENU_UPDATED_TYPE_COMPLETE: {
      return { ...state, ...action?.payload };
    }
    case MENU_UPDATED_TYPE_ERROR: {
      return { ...state, status: "error", fetch: false, ...action?.payload };
    }
    case SECTIONS_UPDATED: {
      return { ...state, ...action?.payload };
    }
    case SECTIONS_CLEARED: {
      return { ...state, ...action?.payload };
    }
    case TYPE_FETCH: {
      return { ...state, fetch: true };
    }
    case TYPE_COMPLETE: {
      return { ...state, fetch: false, ...action?.payload };
    }
    case TYPE_ERROR: {
      return { ...state, fetch: false, ...action?.payload };
    }
    default: {
      throw new Error(`Unsupported action type: ${action.type}`);
    }
  }
}

function useDashboard() {
  const context = React.useContext(DashboardContext);

  if (!context) {
    throw new Error(`useDashboard must be used within a DashboardProvider`);
  }

  const [state, dispatch] = context;

  const onUpdateSections = ({
    templateId,
    type,
    sourceId,
    settings,
    save = true,
  }) => {
    const { menus, ...restProps } = state;
    const { sections } = restProps;

    const updatedSections = sections.map((section) => {
      if (section?.id === templateId) {
        return {
          ...section,
          sourceId,
          settings,
        };
      }

      return section;
    });

    const section = sections.find((section) => section?.id === templateId);

    const sectionList = !section
      ? updatedSections.concat({
          type: type,
          id: templateId,
          sourceId,
          settings,
        })
      : updatedSections;

    const updatedMenus = {
      ...menus,
      [templateId]: {
        ...section,
        sourceId,
        settings,
      },
    };

    if (save) {
      onSaveDashboard({
        ...restProps,
        sections: sectionList,
      });
    }

    dispatch({
      type: SECTIONS_UPDATED,
      payload: {
        sections: sectionList,
        menus: updatedMenus,
      },
    });
  };

  const onClearSection = ({ templateId }) => {
    const { menus, ...restProps } = state;
    const { sections } = restProps;

    const filteredSections = sections.filter(
      (section) => section?.id !== templateId
    );

    const updatedMenus = { ...menus };
    delete updatedMenus[templateId];

    onSaveDashboard({
      ...restProps,
      sections: filteredSections,
    });

    dispatch({
      type: SECTIONS_CLEARED,
      payload: {
        sections: filteredSections,
        menus: updatedMenus,
      },
    });
  };

  const onFetchActiveSearches = ({
    item,
    template,
    sourceId,
    dashboardName,
    dashboardTemplateId,
  }) => {
    const { menus, ...restProps } = state;
    const { sections } = restProps;

    const isDuplicate = sections?.some(
      (obj, idx) => sections.indexOf(obj) !== idx
    );

    const sectionList = !isDuplicate
      ? sections.concat({
          type: item?.type,
          id: template?.id,
          sourceId,
          settings: item?.settings,
        })
      : sections;

    onUpdateSections({
      templateId: template?.id,
      type: item?.type,
      sourceId,
      settings: item?.settings,
      save: false,
    });

    onSaveDashboard({
      ...restProps,
      name: dashboardName,
      template: dashboardTemplateId,
      sections: sectionList,
    });

    dispatch({
      type: MENU_CREATED_TYPE,
      payload: {
        sections: sectionList,
      },
    });
  };

  const onSaveDashboard = async ({
    id,
    template,
    name,
    index = 0,
    sections,
  }) => {
    const init = {
      url: "/api/dashboard/saveDashboard",
      body: {
        id,
        template,
        name,
        index,
        sections,
      },
    };

    const { response: res } = await internalPost(init).catch((error) =>
      dispatch({
        type: MENU_UPDATED_TYPE_ERROR,
        payload: { error },
      })
    );

    if (res?.status < 300) {
      const data = (await res.json()) || {};
      const error = { errorMessage: data?.errorMessage };

      dispatch({
        type: MENU_UPDATED_TYPE_COMPLETE,
        payload: { status: "completed", error, ...data },
      });
    }

    return res;
  };
  const onFavoriteDashboard = async ({
    dashboardId,
    name,
    isFavorite,
    favoriteDashboard
  }) => {
    const init = {
      url: "/api/dashboard/favoriteDashboard",
      body: {
        dashboardId,
        name,
        isFavorite,
        favoriteDashboard
      },
    };

    const { response: res } = await internalPost(init).catch((error) =>
      dispatch({
        type: MENU_UPDATED_TYPE_ERROR,
        payload: { error },
      })
    );

    if (res?.status < 300) {
      const data = (await res.json()) || {};
      const error = { errorMessage: data?.errorMessage };

      dispatch({
        type: MENU_UPDATED_TYPE_COMPLETE,
        payload: { status: "completed", error, ...data },
      });
    }

    return res;
  };
  const onFetchUserdashboard = async (template) => {
    dispatch({
      type: TYPE_FETCH,
      payload: { status: "pending" },
    });
    const { response } = await internalGet({
      url: `/api/dashboard/getUserDashboard?template=${template}`,
    }).catch((error) =>
      dispatch({
        type: TYPE_ERROR,
        payload: { error },
      })
    );


    if (response?.ok) {

      //  const data = await response.json();

      const error = { errorMessage: "failed to fetch dashboard" };
      dispatch({
        type: TYPE_COMPLETE,
        payload: { status: "completed", error },
      });
    }

    return response;
  };
  return {
    state,
    dispatch,
    onFetchActiveSearches,
    onUpdateSections,
    onClearSection,
    onFavoriteDashboard,
    onFetchUserdashboard
  };
}

function DashboardProvider(props: IDashboardProps) {
  const [state, dispatch] = React.useReducer(dashboardReducer, {
    id: props.id,
    index: props.index,
    name: props.name,
    sections: props.sections,
    template: props.template,
    features: props.features,
    menus: props.menus,
    ...props.dashboard,
  });
  const value = React.useMemo(() => [state, dispatch], [state]);

  return <DashboardContext.Provider value={value} {...props} />;
}

export { DashboardProvider, useDashboard };
