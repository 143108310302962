import styled from "styled-components";
import { Box } from "@chakra-ui/react";

export const Scroll = styled(Box)`
  overflow: auto;
  flex: 1;
  color: ${({ theme }) => theme?.table?.color};

  &::-webkit-scrollbar {
    width: 0.3em;
    background-color: ${({ theme }) => theme?.scroll?.backgroundColor};
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rbga(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    outline: 0;
    background-color: ${({ theme }) => theme?.scroll?.borderColor};
    border-radius: 0.5em;
  }
`;

export const ButtonMore = styled.button`
  cursor: pointer;
  padding-right: 0.4em;
  padding-left: 0.4em;
`;

export const StyledEm = styled.em`
  background-color: ${({ theme }) => theme?.emBackground};
  color: ${({ theme }) => theme?.emColor};
`;
