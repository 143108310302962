import * as React from "react";
import { internalGet, internalPost, internalDelete, internalPut } from "@/common/http/httpServices";
import { useRouter } from "next/router";

const NAME = "FLAG";
const TYPE_FETCH = `${NAME}_FETCH`;
const TYPE_ERROR = `${NAME}_ERROR`;
const TYPE_COMPLETE = `${NAME}_COMPLETE`;

const FlagContext = React.createContext<any | null>(null);

function flagReducer(state, action) {
    switch (action.type) {
        case TYPE_FETCH: {
            return { ...state, fetch: true };
        }
        case TYPE_COMPLETE: {
            return { ...state, fetch: false, ...action?.payload };
        }
        case TYPE_ERROR: {
            return { ...state, fetch: false, ...action?.payload };
        }
        default: {
            throw new Error(`Unsupported action type: ${action.type}`);
        }
    }
}

function useFlag() {
    const context = React.useContext(FlagContext);
    const router = useRouter();

    if (!context) {
        throw new Error(
            `useFlag must be used within a FlagProvider`
        );
    }

    const refreshData = () => {
        router.replace(router.asPath);
    };

    const [state, dispatch] = context;
    const onCreateFlag = async (payload) => {
        dispatch({
            type: TYPE_FETCH,
            payload: { status: "pending" },
        });

        const init = {
            url: "/api/flags/setFlag",
            body: payload,
        };

        const { response: res } = await internalPost(init).catch((error) =>
            dispatch({
                type: TYPE_ERROR,
                payload: { error },
            })
        );

        if (res?.status < 300) {
            const data = await res.json();
            const error = { errorMessage: data?.errorMessage };

            dispatch({
                type: TYPE_COMPLETE,
                payload: { status: "completed", error }
            });
        }

        return res;
    };
    const onFlagRead = async (payload) => {
        dispatch({
            type: TYPE_FETCH,
            payload: { status: "pending" },
        });

        const init = {
            url: "/api/flags/readFlag",
            body: payload,
        };

        const { response: res } = await internalPost(init).catch((error) =>
            dispatch({
                type: TYPE_ERROR,
                payload: { error },
            })
        );

        if (res?.status < 300) {
            const data = await res.json();
            const error = { errorMessage: data?.errorMessage };

            dispatch({
                type: TYPE_COMPLETE,
                payload: { status: "completed", error }
            });
        }

        return res;
    };  
    const onDeleteFlags = async (payload) => {
        dispatch({
            type: TYPE_FETCH,
            payload: { status: "pending" },
        });

        const init = {
            url: "/api/flags/deleteFlag",
            body: payload,
        };

        const { response } = await internalPut(init);

        const data = await response.json();
        const error = { errorMessage: data?.errorMessage };

        if (response?.ok) {
            dispatch({
                type: TYPE_COMPLETE,
                payload: { status: "completed", data: payload },
            });
        } else {
            dispatch({
                type: TYPE_ERROR,
                payload: { error },
            });
        }

        return response;
    };
    const onLoadFlag = async (id) => {
        dispatch({
            type: TYPE_FETCH,
            payload: { status: "pending" },
        });
        const { response } = await internalGet({
            url: `/api/flags/getFlag?id=${id}`,
        }).catch((error) =>
            dispatch({
                type: TYPE_ERROR,
                payload: { error },
            })
        );


        if (response?.ok) {

            const data = await response.json();

            const error = { errorMessage: data?.errorMessage };
            dispatch({
                type: TYPE_COMPLETE,
                payload: { status: "completed", error, flag: { data } },
            });
        }

        return response;
    };

    const onUpdateFlag = async (payload) => {
        dispatch({
            type: TYPE_FETCH,
            payload: { status: "pending" },
        });

        const init = {
            url: "/api/flags/updateFlag",
            body: payload,
        };

        const { response: res } = await internalPost(init).catch((error) =>
            dispatch({
                type: TYPE_ERROR,
                payload: { error },
            })
        );

        if (res?.status < 300) {
            const data = await res.json();
            const error = { errorMessage: data?.errorMessage };

            dispatch({
                type: TYPE_COMPLETE,
                payload: { status: "completed", error }
            });
        }

        return res;
    };

    const onDeleteFlag = async (documentId) => {
        dispatch({
            type: TYPE_FETCH,
            payload: { status: "pending" },
        });

        const init = {
            url: `/api/flags/unFlag?id=${documentId}`
            // body: { id: documentId },
        };

        const { response } = await internalDelete(init);

        const data = await response.json();
        const error = { errorMessage: data?.errorMessage };

        if (response?.status < 300) {
            dispatch({
                type: TYPE_COMPLETE,
                payload: { status: "completed", error, flag: documentId },
            });
        } else {
            dispatch({
                type: TYPE_ERROR,
                payload: { error },
            });
        }

        return response;
    };
    const onAddNote = async (flagNote) => {
        dispatch({
            type: TYPE_FETCH,
            payload: { status: "pending" },
        });

        const init = {
            url: "/api/flags/addNote",
            body: flagNote,
        };

        const { response: res } = await internalPost(init).catch((error) =>
            dispatch({
                type: TYPE_ERROR,
                payload: { error },
            })
        );

        if (res?.status < 300) {
            const data = await res.json();
            const error = { errorMessage: data?.errorMessage };

            dispatch({
                type: TYPE_COMPLETE,
                payload: { status: "completed", error },
            });
        }

        return res;
    };
    return {
        state,
        dispatch,
        onCreateFlag,
        onLoadFlag,
        onFlagRead,
        onUpdateFlag,
        onDeleteFlags,
        onAddNote,
    };
}

function FlagProvider(props) {
    const [state, dispatch] = React.useReducer(flagReducer, {
        fetch: false,
        error: {},
        Flag: {},
    });
    const value = React.useMemo(() => [state, dispatch], [state]);

    return <FlagContext.Provider value={value} {...props} />;
}

export { FlagProvider, useFlag };
