import useSWR from "swr";
import useSWRInfinite from "swr/infinite";
const fetcher = (key: string) => fetch(key).then((res) => res.json());

export function useFlagPost({ documentId, refreshInterval = 0 }) {
  const { data, error, isLoading } = useSWRInfinite(
    () => {
      let url = `/api/flags/getFlag?id=${documentId}`;
      return `${url}`;
    },
    fetcher,
    { revalidateOnFocus: false, refreshInterval }
  );

  return {
    data,
    isLoading,
    isError: error,
  };
}
