import { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Checkbox,
  VStack,
  HStack,
  Image,
  Textarea,
  FormLabel,
  Divider,
  Text,
  Box,
  Stack,
  Flex,
  useDisclosure,
  extendTheme,
  Spinner,
} from "@chakra-ui/react";
import BriefReportModal from "./modal/brief-report-modal";
import { generateReport, countBriefs } from "../../services/useBriefer";
import parse from "html-react-parser";
import { StyledEm } from "@/components/feeds/feed-layout-styled";
import DOMPurify from "dompurify";
import { IBrieferInputModel, IBrieferPost } from "@/interfaces/IBrieferInput";
import { useOrganisationSettings } from "../../common/context/organisation-settings-context";
import { CustomToast } from "@/common/toast/CustomToast";

const theme = extendTheme();
const BrieferPostModal = ({
  isOpen,
  onClose,
  isMulti,
  posts,
  setNextPage,
  lang,
}) => {
  const { allFeatures } = useOrganisationSettings();
  const {
    isOpen: isOpenBriefReport,
    onOpen: onOpenBriefReport,
    onClose: onCloseBriefReport,
  } = useDisclosure();

  const [selectedPostIds, setSelectedPostIds] = useState([]);
  const [briefContext, setBriefContext] = useState("");
  const [isEditBriefContext, setIsEditBriefContext] = useState(false);
  const [briefReport, setBriefReport] = useState("");
  const [isEditBriefReport, setIsEditBriefReport] = useState(false);
  const [isLoadingBrieferReport, setIsLoadingBrieferReport] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const { warningToast } = CustomToast();
  const allOrgaSettings = allFeatures();
  const [brieferLimit, setBrieferLimit] = useState(0);
  const [totalBriefs, setTotalBriefs] = useState(0);
  const [briefReportResult, setBriefReportResult] = useState<any>();

  const briefer = {
    title: briefReportResult?.title,
    location: null,
    createdDate: briefReportResult?.createdDate,
    confidenceLevel: 0,
    isPost: true,
  };

  useEffect(() => {
    if (!isMulti && posts.length > 0) {
      setSelectedPostIds([posts[0].uniqueId]);
    }
  }, [isMulti, posts]);

  useEffect(() => {
    if (allOrgaSettings) {
      const briefer = allOrgaSettings.find((x) => x.id === "BrieferLimit");
      if (briefer && briefer.value) {
        setBrieferLimit(Number(briefer.value));
      }
    }
    const fetch = async () => {
      const counts = await countBriefs();
      if (counts) {
        setTotalBriefs(Number(counts));
      }
    };
    fetch();
  }, [allOrgaSettings]);

  const toggleItemSelection = (postId) => {
    setSelectedPostIds((prevSelectedPostIds) => {
      if (prevSelectedPostIds.includes(postId)) {
        return prevSelectedPostIds.filter((id) => id !== postId);
      } else {
        return [...prevSelectedPostIds, postId];
      }
    });
  };

  const ParsedText = ({ content }) => {
    if (!content) return <></>;

    const replaceEm = (node) => {
      if (
        node.type === "tag" &&
        node.name === "em" &&
        node.children &&
        node.children[0]?.type === "text"
      ) {
        return <StyledEm theme={theme}>{node.children[0].data}</StyledEm>;
      } else if (
        node.type === "tag" &&
        node.name === "em" &&
        node.children &&
        node.children[0]?.type === "tag" &&
        node.children[0]?.name === "em"
      ) {
        return (
          <StyledEm theme={theme}>{node.children[0].children[0].data}</StyledEm>
        );
      }
    };
    content = DOMPurify.sanitize(content);
    return <>{parse(content, { replace: replaceEm })}</>;
  };

  const handleGenerateBrief = async () => {
    if (totalBriefs >= brieferLimit) {
      warningToast(
        "",
        `You have reached your monthly quota!. Max. allowed per month: ${brieferLimit}. The current count is ${totalBriefs}`
      );
      return;
    }

    // console.log(`** Current total of BRIEFS: ${Number(totalBriefs)}`);
    setIsEditBriefContext(false);
    const selectedPosts = posts.filter((post) =>
      selectedPostIds.includes(post.uniqueId)
    );

    if (selectedPosts.length > 0) {
      let posts: IBrieferPost[] = [];
      selectedPosts.forEach((post: any) => {
        const content = DOMPurify.sanitize(post.content);
        posts.push({
          postId: post.uniqueId,
          content: content,
          author: post.authorName,
          authorAvatarUrl: post.authorImageUrl,
        });
      });

      const brieferInput: IBrieferInputModel = {
        searchId: selectedPosts[0].searchUid,
        posts: posts,
        userCustomContext: briefContext,
      };

      setIsNew(false);

      //console.log('BRIEFER PAYLOAD====>', brieferInput);
      setIsLoadingBrieferReport(true);
      let response = await generateReport(brieferInput);
      const result = {
        title: response?.name,
        location: null,
        createDate: response?.createdDate,
        confidenceLevel: 0,
        isPost: true,
      };
      setBriefReportResult(result);
      setIsLoadingBrieferReport(false);
      //console.log('BRIEFER RESPONSE', response);
      setBriefReport(response.content);
      setTotalBriefs(totalBriefs + 1);
    }
  };

  if (!posts) {
    return <Text>There are no posts available.</Text>;
  }

  return (
    posts && (
      <Modal isOpen={isOpen} onClose={onClose} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader display="flex" justifyContent="space-between">
            <Text>{lang?.ADVANCED_POST_BRIEFER || "Briefer"}</Text>
            <Text style={{ textAlign: "right" }} fontSize={"sm"} mr={"10"}>
              {lang?.ADVANCED_POST_BRIEFER_USAGE || "Current monthly usage"}{" "}
              {totalBriefs} out of {brieferLimit}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isMulti && (
              <Text fontSize="sm" as="b" mb={4}>
                {selectedPostIds.length}{" "}
                {lang?.ADVANCED_POST_BRIEFER_SELECTEDPOSTS || "selected posts"}
              </Text>
            )}
            <HStack spacing={4} alignItems="flex-start" pb={10}>
              <Box
                maxHeight="400px"
                width="40%"
                overflow="auto"
                overflowX="hidden"
                css={{
                  "&::-webkit-scrollbar": {
                    width: "6px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#888",
                  },
                }}
              >
                <VStack spacing={2} align="start" flex={1}>
                  {posts?.map((post) => (
                    <HStack key={post.uniqueId} spacing={2} align="center">
                      {isMulti && (
                        <Checkbox
                          isChecked={selectedPostIds.includes(post.uniqueId)}
                          onChange={() => toggleItemSelection(post.uniqueId)}
                        />
                      )}
                      <Image
                        src={post.authorImageUrl}
                        alt={`Icon for ${post.authorTitle}`}
                        w={10}
                        h={10}
                      />
                      <VStack spacing={1} align="start">
                        <Text fontWeight="bold">
                          <ParsedText content={post.authorName} />
                        </Text>
                        <Text>
                          <ParsedText content={post.content} />
                        </Text>
                      </VStack>
                    </HStack>
                  ))}
                </VStack>
              </Box>
              <Divider orientation="vertical" borderTop="1px solid gray.300" />
              <VStack spacing={4} align="start" flex={1} alignItems="start">
                <Box width="600px">
                  {isNew && (
                    <Stack>
                      <FormLabel>
                        {lang?.ADVANCED_POST_BRIEFER_CREATOR || "Brief Creator"}
                      </FormLabel>
                      <Textarea
                        value={briefContext}
                        onChange={(e) => setBriefContext(e.target.value)}
                        rows={5}
                        width="600px"
                        placeholder={
                          lang?.ADVANCED_POST_BRIEFER_PLACEHOLDER2 ||
                          "Enter your custom context prompt for AI generated brief or leave blank..."
                        }
                      />
                      <Button
                        colorScheme="messenger"
                        size="sm"
                        onClick={handleGenerateBrief}
                        mt={2}
                        pr={4}
                        width={130}
                        leftIcon={
                          <i
                            className="fa-solid fa-pencil"
                            aria-hidden="true"
                          />
                        }
                        isDisabled={isMulti && selectedPostIds?.length === 0}
                      >
                        {lang?.ADVANCED_POST_BRIEFER_GENERATE ||
                          "Generate brief"}
                      </Button>
                    </Stack>
                  )}
                  {isEditBriefContext && (
                    <Stack mb={3}>
                      <a
                        href="#"
                        title={lang?.BACK || "Back"}
                        onClick={() =>
                          setIsEditBriefContext(!isEditBriefContext)
                        }
                      >
                        <i className="fa fa-angle-left" /> Back
                      </a>
                      <FormLabel>
                        {lang?.ADVANCED_POST_BRIEFER_CREATOR || "Brief Creator"}
                      </FormLabel>
                      <Textarea
                        value={briefContext}
                        onChange={(e) => setBriefContext(e.target.value)}
                        rows={5}
                        placeholder={
                          lang?.ADVANCED_POST_BRIEFER_PLACEHOLDER2 ||
                          "Enter your custom context prompt for AI generated brief or leave blank..."
                        }
                      />
                      <Button
                        colorScheme="telegram"
                        size="sm"
                        onClick={handleGenerateBrief}
                        mt={2}
                        width={170}
                        leftIcon={
                          <i
                            className="fa-solid fa-arrows-rotate"
                            aria-hidden="true"
                          />
                        }
                        isDisabled={isMulti && selectedPostIds?.length === 0}
                      >
                        {lang?.ADVANCED_POST_BRIEFER_REGENERATE_BRIEF ||
                          "Regenerate brief"}
                      </Button>
                    </Stack>
                  )}
                  {isEditBriefReport && (
                    <Stack>
                      <a
                        href="#"
                        title={lang?.BACK || "Back"}
                        onClick={() => setIsEditBriefReport(!isEditBriefReport)}
                      >
                        <i className="fa fa-angle-left" />{" "}
                        {lang?.BACK || "Back"}
                      </a>
                      <Textarea
                        value={briefReport}
                        onChange={(e) => setBriefReport(e.target.value)}
                        rows={15}
                      />
                    </Stack>
                  )}
                  {isLoadingBrieferReport && (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      height="50vh"
                      flexDirection="column"
                    >
                      <Spinner size="xl" color="blue.500" />
                      <Box mt={4}>
                        {lang?.ADVANCED_POST_BRIEFER_LOADINGREPORT ||
                          "Loading situation report"}
                        ...
                      </Box>
                    </Box>
                  )}
                  {!isLoadingBrieferReport && !isNew && !isEditBriefReport && (
                    <Stack width="600px">
                      <Flex alignItems="left">
                        <Text ml={3} whiteSpace="pre-wrap">
                          <a
                            href="#"
                            title={lang?.TAGS_EDIT || "Edit"}
                            onClick={() =>
                              setIsEditBriefReport(!isEditBriefReport)
                            }
                          >
                            <i className="fa-solid fa-pencil" />
                          </a>
                          {"  "}
                          {briefReport}
                        </Text>
                      </Flex>
                      <Divider orientation="horizontal" mt={2} mb={2} />
                      <Flex alignItems="center" mt={3}>
                        <a
                          href="#"
                          onClick={() => {
                            setIsEditBriefContext(!isEditBriefContext);
                          }}
                        >
                          <i className="fa-solid fa-pencil" />{" "}
                          {lang?.ADVANCED_POST_BRIEFER_EDIT_CONTEXT ||
                            "Edit context"}
                        </a>
                        <Button
                          colorScheme="telegram"
                          size="xs"
                          onClick={handleGenerateBrief}
                          leftIcon={
                            <i
                              className="fa-solid fa-arrows-rotate"
                              aria-hidden="true"
                            />
                          }
                          ml={2}
                        >
                          {lang?.ADVANCED_POST_BRIEFER_REGENERATE ||
                            "Regenerate"}
                        </Button>
                        <Button
                          colorScheme="telegram"
                          size="xs"
                          onClick={() => {
                            onOpenBriefReport();
                          }}
                          ml={2}
                        >
                          {lang?.ADVANCED_POST_SAVE_SEND_REPORT ||
                            "Save/send report"}
                        </Button>
                      </Flex>
                    </Stack>
                  )}
                </Box>
              </VStack>
            </HStack>
            {isMulti && (
              <Stack mt={4} width={100}>
                <Button colorScheme="facebook" size="xs" onClick={setNextPage}>
                  {lang?.LOADMORE || "Load more"}...
                </Button>
              </Stack>
            )}
            {isOpenBriefReport && (
              <BriefReportModal
                isOpen={isOpenBriefReport}
                onClose={onCloseBriefReport}
                briefReport={briefReport}
                isEditContent={false}
                lang={lang}
                briefer={briefer}
              ></BriefReportModal>
            )}
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="flex-end">
              {briefReport && !isLoadingBrieferReport && (
                <Text
                  fontSize="sm"
                  mt="-5"
                  fontWeight="bold"
                  fontStyle="italic"
                >
                  {lang?.ADVANCED_POST_BRIEFER_DISCLAIMER ||
                    "This content has been automatically generated by an AI system."}
                </Text>
              )}
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  );
};

export default BrieferPostModal;