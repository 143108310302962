import useSWR from "swr";
import { internalPost, internalPut } from "../common/http/httpServices";

const fetcher = (url, ...rest) =>
  fetch(url, ...rest)
    .then((res) => res.json())
    .catch((e) =>
      console.log(`error when fetching data in useQueryBuilder: ${e}`)
    );

export async function createSearch(search) {
  try {
    const init = {
      url: "/api/searches/search/search-builder/create",
      body: search,
    };

    const { response, data } = await internalPost(init);

    let jsonResponse;
    // if data is null, the response body hasn't been cosumed yet, do so
    if (data === null) {
      jsonResponse = await response.json();
    } else {
      jsonResponse = data;
    }
    return jsonResponse;
  } catch (e) {
    console.error("Invalid API response:", e);
  }
}

export async function updateSearch(search) {
  try {
    const init = {
      url: "/api/searches/search/search-builder/update",
      body: search,
    };

    const { response, data } = await internalPut(init);
    let jsonResponse;
    // if data is null, the response body hasn't been cosumed yet, do so
    if (data === null) {
      jsonResponse = await response.json();
    } else {
      jsonResponse = data;
    }
    return jsonResponse;
  } catch (e) {
    console.error("Invalid API response:", e);
  }
}

export function useCheckDomain(domain, source, targets) {
  let apiUrl = `/api/searches/search/search-builder/checkDomain?domain=${domain}&source=${source}`;

  if (targets && targets.length > 0) {
    apiUrl = `${apiUrl}&targets=${targets}`;
  }

  const { data, error } = useSWR(apiUrl, fetcher);

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
}

export async function checkDomain(domain, source, targets) {
  try {
    let apiUrl = `/api/searches/search/search-builder/checkDomain?domain=${encodeURIComponent(
      domain
    )}&source=${source}`;
    if (targets && targets.length > 0) {
      apiUrl = `${apiUrl}&targets=${targets}`;
    }

    const settings = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Signal-App": "v4",
      },
    };
    const response = await fetch(apiUrl, settings);
    const data = await response.json();
    return data;
  } catch (e) {
    console.error("Invalid API response:", e);
  }
}

export function useCheckRedditUser(username) {
  let apiUrl = `/api/searches/search/search-builder/checkRedditUsername?username=${username}`;

  const { data, error } = useSWR(apiUrl, fetcher);

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
}

export function useTwitterBlackList() {
  let apiUrl = `/api/searches/search/search-builder/getTwitterBlackList`;

  const { data, error } = useSWR(apiUrl, fetcher);

  return {
    blacklist: data,
    isLoading: !error && !data,
    isError: error,
  };
}

export async function isSearchNameAvailable(name) {
  try {
    const response = await fetch(
      `/api/searches/search/search-builder/isAValidSearchName?searchName=${name}`
    );
    const data = await response.json();
    return data;
  } catch (e) {
    console.error("Invalid API response:", e);
  }
}

export function checkDataBreachSearchLimit() {
  let apiUrl = `/api/searches/search/search-builder/checkDataBreachSearchLimit`;

  const { data, error } = useSWR(apiUrl, fetcher);

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  };
}

export function checkFacebookSearchLimit() {
  let apiUrl = `/api/searches/search/search-builder/checkFacebookSearchLimit`;

  const { data, error } = useSWR(apiUrl, fetcher);

  // // For UI testing only
  // const data = { isAvailable: true };
  // const error = {};

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  };
}

export function checkInstagramSearchLimit() {
  let apiUrl = `/api/searches/search/search-builder/checkInstagramSearchLimit`;

  const { data, error } = useSWR(apiUrl, fetcher);

  // // For UI testing only
  // const data = { isAvailable: true };
  // const error = {};

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  };
}

export function checkSearchLimit() {
  let apiUrl = `/api/searches/search/search-builder/checkSearchLimit`;

  const { data, error } = useSWR(apiUrl, fetcher);

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  };
}

export async function fetchGroups() {
  try {
    const response = await fetch(`/api/groups`);
    const groupsJson = await response.json();
    let groups = groupsJson?.map((x) => ({ id: x.id, name: x.displayName }));
    return groups;
  } catch (e) {
    console.error("Invalid API response:", e);
  }
}

export function useTranslationQuota() {
  try {
    const { data, error } = useSWR(
      `/api/organisation/translationQuota`,
      fetcher
    );

    return {
      data: data,
      isLoading: !error && !data,
      isError: error,
    };
  } catch (e) {
    console.error("Invalid API response:", e);
  }
}

export async function resolveFacebookUrl(url) {
  try {
    let apiUrl = `/api/searches/search/search-builder/resolveFacebookUrl?url=${encodeURIComponent(
      url
    )}`;

    const settings = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Signal-App": "v4",
      },
    };
    const response = await fetch(apiUrl, settings);
    const data = await response.json();
    return data;
  } catch (e) {
    console.error("Invalid API response:", e);
  }
}

export async function fetchFacebookCities(searchText) {
  try {
    const response = await fetch(
      `/api/searches/search/search-builder/getFacebookCities?searchText=${searchText}`
    );
    const jsonRes = await response.json();
    console.info("jsonRes:", jsonRes);
    let cities = jsonRes?.data?.map((x) => ({
      id: x.id,
      name: x.name,
      photoUrl: x.photoUrl,
    }));
    return cities;
  } catch (e) {
    console.error("Invalid API response:", e);
  }
}
