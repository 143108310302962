export async function fetchFormValues() {
  try {
    const response = await fetch(`/api/everbridge/getFormValues`);
    const formValues = await response.json();
    return formValues;
  } catch (e) {
      console.error("Invalid API response:", e);
  }
}

export async function fetchRiskEvent(searchId: string, postUniqueId: string) {
  try {
      const response = await fetch(`/api/everbridge/getRiskEvent?searchId=${searchId}&postUniqueId=${postUniqueId}`);
      const riskEvent = await response.json();
      return riskEvent;
  } catch (e) {
      console.error("Invalid API response:", e);
  }
}