import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";

const UserPermissionsContext = React.createContext<any | null>(null);
const userPermissionsKey = "userPermissions";

export function useUserPermissions() {
  return useContext(UserPermissionsContext);
}

export function UserPermissionsProvider({ children }) {
  const [dataCache, setDataCache] = useState({});
  const [isFetchUserPermissions, setIsFetchUserPermissions] = useState(true); 

  const getUserPermissions = async () => {
    try {
      const response = await fetch(`/api/account/permissions`);
      if (response.ok) {
        const permissions = await response.json();
        return permissions;
      }
      return [];
    } catch (error) {
      console.error("Error fetching account permissions:", error);
      return [];
    }
  };

  const fetchUserPermissions = async () => {
    if (!dataCache[userPermissionsKey]) {
      const permissions = await getUserPermissions();
      setDataCache((prevCache) => ({
        ...prevCache,
        [userPermissionsKey]: Object.freeze(permissions),
      }));
    }
  };

  const updateUserAndPermissions = async () => {
    setDataCache((prevCache) => {
      const updatedCache = { ...prevCache };
      delete updatedCache[userPermissionsKey];
      return updatedCache;
    });

    setIsFetchUserPermissions(true);
  };

  const userPermissions = () => {
    return dataCache[userPermissionsKey] || [];
  };

  const hasPermission = (permission: string) => {
    if (permission) {
      const userPermissions = dataCache[userPermissionsKey] || [];
      return userPermissions.includes(permission);
    }
    return false;
  };

  const contextValue = useMemo(
    () => ({
      dataCache,
      fetchUserPermissions,
      updateUserAndPermissions,
      hasPermission,
      userPermissions
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataCache, fetchUserPermissions, updateUserAndPermissions]
  );

  useEffect(() => {
    if (isFetchUserPermissions) {
      fetchUserPermissions();
      setIsFetchUserPermissions(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchUserPermissions]);

  return (
    <UserPermissionsContext.Provider value={contextValue}>
      {children}
    </UserPermissionsContext.Provider>
  );
}
