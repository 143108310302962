import styled from "styled-components";
import { Icon } from "@chakra-ui/react";

const CircleIcon = (props) => (
  <Icon viewBox="0 0 200 200" {...props}>
    <path
      fill="currentColor"
      d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
    />
  </Icon>
);

const setPriorityColor = (theme, priority) => {
  if (priority == 1) {
    return theme?.priority?.low;
  } else if (priority == 2) {
    return theme?.priority?.medium;
  } else if (priority == 3) {
    return theme?.priority?.high;
  }

  return theme?.priority?.none;
};

export const Circle = styled(CircleIcon)`
  & path {
    fill: ${({ theme, priority }) => setPriorityColor(theme, priority)};
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  i:nth-child(n + 2) {
    padding-left: 0.5em;
  }
`;

export const TableBodyWrapper = styled.div`
  tbody {
    background-color: ${({ theme }) => theme.table.body.backgroundColor};
  }
`;

export const TableFooterWrapper = styled.div`
  span {
    font-size: 12px;
    color: ${({ theme }) => theme.table.color};
  }
  button {
    margin-left: 1em;
  }

  background-color: ${({ theme }) => theme.table.body.backgroundColor};
`;

export const PanelWrapper = styled.div`
  background-color: ${({ theme }) => theme.panel.backgroundColor};
  margin: 0 !important;
  padding: 1.5em !important;
  border-bottom-width: 0.5px;
  border-style: solid;
  height: 4.468em;
`;

export const TabWrapper = styled.div`
  width: 100vw;
  margin-left: -16px;
  > div {
    padding-top: 0;
  }
`;

export const TableWrapper = styled.div`
  width: 100%;
  > div {
    padding-top: 0.5em;
  }
  color: ${({ theme }) => theme.table.color};
  th {
    padding-left: 4px;
    color: ${({ theme }) => theme.table.th.color};
    border-color: ${({ theme }) => theme.table.th.borderColor};
    text-transform: capitalize;
    position: sticky;
    top: 0;
    background-color: ${({ theme }) => theme.table.th.backgroundColor};
  }
  td {
    border-color: #202020;
    padding: 3px;
  }
`;

export const ScrollableWrapper = styled.div`
  div {
    &::-webkit-scrollbar {
      width: 0.3em;
      background-color: ${({ theme }) => theme?.scroll?.backgroundColor};
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rbga(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      outline: 0;
      background-color: ${({ theme }) => theme?.scroll?.borderColor};
      border-radius: 0.5em;
    }
  }
`;

export const FeedFooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    font-size: 0.75em;
    color: ${({ theme }) => theme.table.color};
  }
  a {
    font-size: 1em;
    color: #3366cc;
  }

  background-color: ${({ theme }) => theme.table.body.backgroundColor};
`;
