import { withTheme } from "styled-components";
import { Box, Text, Flex, useDisclosure, Tooltip } from "@chakra-ui/react";
import parse from "html-react-parser";
import { ButtonMore, StyledEm } from "./feed-layout-styled";
import { PostModal } from "./modal/post-modal";
import { timeDiff } from "@/common/utility/dateTimeUtil";
import { IPostModalProps } from "@/interfaces/IPostModalProps";
import { PostModalProvider } from "@/components/feeds/modal/post-modal-context";
import { useEffect, useRef, useState } from "react";
import DOMPurify from "dompurify";
import { PostTags } from "./post-tags";
import { FlagProvider } from "@/components/flags/flag-context";
import FlagActionModal from "@/components/flags/flag-action-modal";
import { useLivestreamsApi } from "@/components/feeds/modal/post-modal-context";
import { CustomToast } from "@/common/toast/CustomToast";
import Constants from "@/common/constants";
import { getDomainWithoutSuffix } from "tldts";

import { EverbridgeIntegrationProvider } from "@/components/integrations/everbridge-integration-context";
import EverbridgeIntegrationModal from "../integrations/everbridge-integration-modal";
import { IEverbridgeRiskEvent } from "@/interfaces/IEverbridgeIntegration";

const SEARCH_TITLE = "search-title";

const FeedPost = ({ theme, post, showTags, user, display, isAlert, lang }) => {
  const {
    isOpen: isOpenPostModal,
    onOpen: onOpenPostModal,
    onClose: onClosePostModal,
  } = useDisclosure();

  const {
    isOpen: isOpenEverbridge,
    onOpen: onOpenEverbridge,
    onClose: onCloseEverbridge,
  } = useDisclosure();

  const { onTranslatePost } = useLivestreamsApi();
  const { errorToast } = CustomToast();

  const [postContent, setPostContent] = useState("");
  const [isTranslated, setIsTranslated] = useState(false);
  const [showPostModal, setShowPostModal] = useState(false);
  const [showFlagModal, setShowFlagModal] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(
    false
  );

  useEffect(() => {
    if (post) {
      setPostContent(display == SEARCH_TITLE ? post.searchName : post.content);
    }
    if (user?.roleName == Constants.ROLE_READ_ONLY) {
      setIsReadOnly(true);
    }
  }, [display, post]);

  const handleOpenAdvancedPost = () => {
    setShowPostModal(true);
  };

  const handleOpenFlag = () => {
    setShowFlagModal(true);
  };

  const handleTranslate = async () => {
      if (post) {
      setIsBusy(true);
      if (!isTranslated) {
        if (post.formattedText.length >= 10000) {
          errorToast("Post", "Maximum translated post is 10000 character.");
          return;
        }

        const postTranslation = {
          languageCode: user?.translationLanguage
            ? user?.translationLanguage
            : "en",
          text: display == SEARCH_TITLE ? post.searchName : post.content,
          sourceType: post.sourceType,
          isShowMore: false,
          uniqueId: post.uniqueId,
          unmarkTranslation: post.isTranslated,
          isAlert: isAlert
       };

        post.isTranslated = !post.isTranslated;

        const res = await onTranslatePost(postTranslation);
        if (res.response.ok) {
          const translatedText = res.data?.text;
           setPostContent(translatedText);
           post.content = translatedText;
           setIsTranslated(true);
           setIsBusy(false);
        }
      } else {
        setPostContent(
          display == SEARCH_TITLE ? post.searchName : post.content
        );
          setIsTranslated(false);
          setIsBusy(false);
      }
      }
  };

  const ParsedText = ({ content, theme }) => {
    if (!content) return <></>;

    const replaceEm = (node) => {
      if (
        node.type === "tag" &&
        node.name === "em" &&
        node.children &&
        node.children[0]?.type === "text"
      ) {
        return <StyledEm theme={theme}>{node.children[0].data}</StyledEm>;
      } else if (
        node.type === "tag" &&
        node.name === "em" &&
        node.children &&
        node.children[0]?.type === "tag" &&
        node.children[0]?.name === "em"
      ) {
        return (
          <StyledEm theme={theme}>{node.children[0].children[0].data}</StyledEm>
        );
      } else if (node.type === "tag" && node.name === "img") {
        return <></>; // Hide images from the dashboard feeds
      }
    };

    content = DOMPurify.sanitize(content);
    return <>{parse(content, { replace: replaceEm })}</>;
  };

  const toEverbridgeRiskEvent = () => {
    const initialRiskEvent: IEverbridgeRiskEvent = {
      eventId: "",
      postId: post?.uid,
      searchId: post?.searchUid,
      status: null,
      urgency: null,
      type: null,
      severity: null,
      scope: null,
      certainty: null,
      headline: "",
      web: post?.url,
      description: "",
      categories: [],
      subCategory: null,
      areaDescription: "",
      circle: "",
      includePostUrl: false,
      isPost: true,
      allowDerivedLocation: false,
      postedDate: post?.publishDate,
      postUniqueId: post?.uniqueId
    };

    return initialRiskEvent;
  };

  const modalReponseFromEverbridge = (data: any) => {
    if (data) {
    }
  };

  let postModalProps: IPostModalProps = {
    post: undefined,
    theme,
    user,
    lang,
    languageCode: "",
    notesEnabled: true,
    imagesEnabled: true,
    showGeoIcon: true,
    showDeleteIcon: true,
    showMoreIcon: false,
    showRiskCenterInfo: true,
    showExtraInfo: true,
    showFlagIcon: true,
    showEverbridgeIcon: true,
    showTranslateIcon: true,
    allowTranslationLanguage: true,
    showXmattersIcon: true,
    showNogginIcon: true,
    showOriginalText: true,
    translateButtonTitle: post?.isTranslated ? lang?.ADVANCED_POST_REMOVETRANSLATION || "Remove translation" : lang?.ADVANCED_POST_TRANSLATE || "Translate",
    showPostModal: false,
  };

  const isFirstRender = useRef(true);
  if (isFirstRender.current && post) {
    onOpenPostModal();
    setPostContent(display == SEARCH_TITLE ? post.searchName : post.content);
    isFirstRender.current = false;
  }

  const highlight = () => {
    if (isAlert && post?.alertPriority > 0) return "hasPriority";
    else if (post?.isFlagged) return "#ffa500";
    return "none";
  };
  function fetchDomainFromSource(source, url) {
    let sourceType = "";
    if (source) {
      sourceType = source.toLowerCase();
    }
    if (["openweb", "i2p", "zeronet", "openbazaar", "irc", "pastebin", "8kun", "icq", "forum", "news", "blog", "boards", "rss"].includes(sourceType))
      return getDomainWithoutSuffix(url);
    else
      return source;
  }
  return (
    <>
      <PostModalProvider>
        <Box
          // key={post?.uniqueId || "defaultKey"}
          paddingLeft={highlight() == "none" ? "0" : "7px"}
          position="relative"
        >
          <Flex paddingTop="0.5em">
            <Flex flex="9" flexDirection="row" flexWrap="wrap">
              <Text
                noOfLines={3}
                fontSize="0.94em"
                color={theme.feed.color}
                overflow="hidden"
                textOverflow="ellipsis"
                wordBreak="break-word"
                width={isAlert ? "" : "100%"}
                alignSelf={isAlert ? "center" : ""}
              >
                {" "}
                {post && (
                  <ParsedText content={post.title || ""} theme={theme} />
                )}
                {post && (
                  <ParsedText content={postContent?.replace("\r\n", "<br/>") || ""} theme={theme} />
                )}
              </Text>
              {display == SEARCH_TITLE && (
                <Box flex="1" textAlign="right">
                  <Text
                    fontSize="0.75em"
                    fontWeight="750"
                    color={theme?.feed?.footer?.color}
                    marginTop="10px"
                    marginBottom="10px"
                  >{`${timeDiff(post?.publishDate)}`}</Text>
                </Box>
              )}
              {display != SEARCH_TITLE && (
                <Flex paddingTop="0.2em" paddingBottom="0.5em" width="100%">
                  <Box flex="2">
                    <Text
                      fontSize="0.75em"
                      fontWeight="750"
                      color={theme?.feed?.footer?.color}
                      wordBreak="break-word"
                      width="100%"
                    >
                      {post && (
                        <ParsedText
                          content={post?.authorName || post?.authorId || ""}
                          theme={theme}
                        />
                      )}
                    </Text>
                  </Box>
                  <Box flex="1">
                    <a href={post?.url} target="_blank" title={post?.url}>
                      <Text
                        fontSize="0.75em"
                        fontWeight="750"
                        color={theme?.feed?.footer?.color}
                      >
                        {`${fetchDomainFromSource(post?.sourceType, post?.url)}`}
                      </Text>
                    </a>
                  </Box>
                  <Box flex="1" textAlign="right">
                    <Text
                      fontSize="0.75em"
                      fontWeight="750"
                      color={theme?.feed?.footer?.color}
                    >{`${timeDiff(post?.publishDate)}`}</Text>
                  </Box>
                </Flex>
              )}{" "}
              {showTags && <PostTags post={post} theme={theme} lang={lang} />}
            </Flex>
            <Flex
              flexDirection="column"
              alignItems="center"
              flex="1"
              maxWidth="35px"
              minWidth="35px"
            >
              <Tooltip label={lang?.SHOW_DETAILS || "Show details"}>
                <ButtonMore onClick={() => handleOpenAdvancedPost()}>
                  <Text fontSize="0.6.250em" fontWeight="bold">
                    ...
                  </Text>
                </ButtonMore>
              </Tooltip>
              {!isReadOnly && display != SEARCH_TITLE && (
                <Tooltip label={lang?.FLAGACTIONS_FLAG || "Flag"}>
                  <a href="#" onClick={() => handleOpenFlag()}>
                    <i
                      className={"fa fa-flag fa-sm"}
                      style={post?.isFlagged ? {
                        padding: "0.7em",
                        color: "#ffa500",
                      } : {
                        padding: "0.7em",
                        color: "#4c4c4c",
                      }
                      }
                    />
                  </a>
                </Tooltip>
              )}
              {!isReadOnly && display != SEARCH_TITLE && (
                <Tooltip
                  label={post?.isTranslated ? lang?.ADVANCED_POST_REMOVETRANSLATION || "Remove translation" : lang?.ADVANCED_POST_TRANSLATE || "Translate"}
                >
                  <a href="#" onClick={() => handleTranslate()}>
                    <i
                      className={isBusy ? "fa fa-spinner fa-sm fa-spin": "fa fa-globe-americas fa-sm"}
                      style={{
                        padding: "0.7em",
                        color: post?.isTranslated ? "#12700f" : "#4c4c4c",
                      }}
                    />
                  </a>
                </Tooltip>
              )}
              {!isReadOnly && display != SEARCH_TITLE && post?.hasRiskEvent && (
                <Tooltip
                  label={lang?.ADVANCED_POST_RISKEVENT_VCC_UPDATE || "Update risk event"}
                >
                  <a href="#" onClick={() => onOpenEverbridge()}>
                    <i
                      className={"fa fa-solid fa-sm fa-webhook"}
                      style={{
                        padding: "0.7em",
                        color: post.isTranslated ? "#12700f" : "#4c4c4c",
                      }}
                    />
                  </a>
                </Tooltip>
              )}
            </Flex>
          </Flex>
          {!isAlert && post?.isFlagged && (
            <Box
              position="absolute"
              top="5px"
              left="0"
              width="2px"
              backgroundColor={post?.isFlagged ? "#ffa500" : "none"}
              height="90%"
            />
          )}
          {isAlert && // Only for Alerts
            post?.alertPriority && (
              <Box
                position="absolute"
                top="5px"
                left="0"
                width="2px"
                backgroundColor={
                  post?.alertPriority == 3
                    ? "red"
                    : post?.alertPriority == 2
                    ? "orange"
                    : post?.alertPriority == 1
                    ? "green"
                    : "none"
                }
                height="90%"
              />
            )}
          <Box
            paddingTop="3px"
            position="absolute"
            left="50%"
            transform="translateX(-50%)"
            width="97%"
            borderBottom="1px solid #434343"
          />
        </Box>
        {post && showPostModal && (
          <PostModal
            {...postModalProps}
            post={post}
            showPostModal={showPostModal}
            onCloseModal={() => {
              setShowPostModal(false);
            }}
          />
        )}
        {!isAlert && post && showFlagModal && (
          <FlagProvider>
            <FlagActionModal
              isNew={!post?.isFlagged}
              documentId={post?.uid}
              searchId={post?.searchUid}
              uniqueId={post?.uniqueId}
              isOpen={showFlagModal}
              onClose={() => {
                setShowFlagModal(false);
              }}
            ></FlagActionModal>
          </FlagProvider>
        )}
        {isOpenEverbridge && (
          <EverbridgeIntegrationProvider>
            <EverbridgeIntegrationModal
              data={toEverbridgeRiskEvent()}
              post={post}
              isOpen={isOpenEverbridge}
              onOpen={onOpenEverbridge}
              onClose={onCloseEverbridge}
              modalResponse={modalReponseFromEverbridge}
            ></EverbridgeIntegrationModal>
          </EverbridgeIntegrationProvider>
        )}
      </PostModalProvider>
    </>
  );
};

export default withTheme(FeedPost);
