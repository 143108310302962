import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  html,
  body {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.text};
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  }

  body::-webkit-scrollbar {
    width: 0.3em;
    background-color: ${({ theme }) => theme?.scroll?.backgroundColor};
  }

  body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rbga(0, 0, 0, 0.3);
  }

  body::-webkit-scrollbar-thumb {
    outline: 0;
    background-color: ${({ theme }) => theme?.scroll?.borderColor};
    border-radius: 0.5em;
  }

  input: {
    border: ${({ theme }) => `1px solid ${theme.input.borderColor}`};
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
  }

  .pac-container {
     z-index: 1501;
  }


/* react-multi-select-component custom styles for Dark UI */
.rmsc.dark {
  --rmsc-main: #4285f4;
  --rmsc-hover: rgba(255, 255, 255, 0.06);
  --rmsc-selected: #4A5568;
  --rmsc-border: #2D3748;  
  --rmsc-bg: #2D3748;
    color: #fff;
  --rmsc-radius: 6px; /* Radius */
  --rmsc-gray: rgba(255, 255, 255, 0.16);
}

.rmsc.dark .dropdown-container {
  background-color: rgba(255, 255, 255, 0.04);
}

.rmsc.dark .dropdown-heading-value .gray {
  color: #545c6a;
}

.rmsc.dark .search {
  width: 100%;
  position: relative;
  border-bottom: 1px solid var(--rmsc-border);
  background-color: #121A25;
  color:#FFF
}

.rmsc.dark .options li {
  list-style: none;
  margin: 0;  
  border: 0.1px solid #2D3748;
}

.rmsc.dark .dropdown-heading-dropdown-arrow {
  color:#FFF;  
}

.rmsc.dark .dropdown-heading-dropdown-arrow svg {
  width:14px !important;
  height:14px !important;
}

/* react-multi-select-component custom styles for Light UI */
.rmsc.light {
  --rmsc-main: #1976D2; 
  --rmsc-hover: rgba(0, 0, 0, 0.1);
  --rmsc-selected: #89d5ff;
  --rmsc-border: #E0E0E0;
  --rmsc-bg: #F5F5F5;
  color: #333;
  --rmsc-radius: 6px; /* Radius */
  --rmsc-gray: rgba(0, 0, 0, 0.3);
}

.rmsc.light .dropdown-container {
  background-color: #edf2f7;
}

.rmsc.light .dropdown-heading-value .gray {
  color: #000;
}

.rmsc.light .search {
  width: 100%;
  position: relative;
  border-bottom: 1px solid var(--rmsc-border);
  background-color: #FFFFFF;
  color: #333; 
}

.rmsc.light .options li {
  list-style: none;
  margin: 0;
  border: 1px solid #E0E0E0;
}

.rmsc.light .dropdown-heading-dropdown-arrow {
  color: #000;
}

.rmsc.light .dropdown-heading-dropdown-arrow svg {
  width: 14px !important;
  height: 14px !important;
}


/* Chakra UI Select Icon */
.chakra-select__icon {
    width:38px !important;
    height:38px !important;
}

`;

export default GlobalStyle;
