import { Flex, Tag, TagLabel, Text } from "@chakra-ui/react";

export const RodrikIcon = ({ icon, label, score }) => {
    return (
        <>
            {score > 0 && (
                <>
                    <Flex>
                        <Tag
                            mt={1}
                            mr={1}
                            minWidth="40px"
                            size="sm"
                            variant='solid'
                            colorScheme={score === 1 ? "orange" : "red"}
                        >
                            <i
                                className={icon}                              
                            />
                            <TagLabel ml={1} fontSize="0.85em">{label}</TagLabel>
                        </Tag>
                    </Flex>
                </>
            )}
        </>
    );
};
