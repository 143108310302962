import React from "react";
import { Input, InputGroup, InputLeftAddon } from "@chakra-ui/react";
import useTheme from "../utility/useTheme";

interface CustomDatePickerInputProps {
    placeholder: string;
    value?: string;
    onClick?: () => void;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    inputLeftAddon: string;
}

const CustomDatePickerInput = React.forwardRef<HTMLInputElement, CustomDatePickerInputProps>(
    ({ placeholder, value, onClick, onChange, inputLeftAddon }, ref) => {
        const theme = useTheme();

        return (
            <InputGroup size="sm">
                <InputLeftAddon width="70px">{inputLeftAddon}</InputLeftAddon>
                <Input
                    bg={theme.input.bgColor}
                    variant="outline"
                    placeholder={placeholder}
                    value={value}
                    onClick={onClick}
                    onChange={onChange}
                    ref={ref} // Attach the ref
                />
            </InputGroup>
        );
    }
);

export default CustomDatePickerInput;
