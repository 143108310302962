"use client";

import { useState, useEffect } from "react";
import type { AppProps } from "next/app";
import Router from "next/router";
import { ThemeProvider } from "styled-components";
import { ProSidebarProvider } from "react-pro-sidebar";

import {
  ChakraProvider,
  extendTheme,
  Flex,
  Spinner,
  Text,
  ColorModeScript, // Add this import
  useColorMode,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import GlobalStyle from "@/components/globalstyles";
import { dark } from "@/common/theme";
import { LanguageProvider } from "@/components/userprofile/language/lang-context";
import { OrganisationSettingsProvider } from "@/common/context/organisation-settings-context";
import { UserPermissionsProvider } from "@/common/context/user-permissions-context";
import { UserInfoProvider } from "@/common/context/user-info-context";
import ThemeWrapper from "@/common/wrapper/themeWrapper";
import { AuthSettingsProvider } from "@/common/context/auth-settings-context";
import { AuthProvider } from "@/common/context/auth-context";
import { DashboardProvider } from "@/components/dashboard/dashboard-context";
import Head from "next/head";
import firebaseCloudMessaging from "../firebase";
import AlertOnScreen from "@/components/firebase/AlertOnScreen";
import getConfig from "next/config";
import { IFireBaseConfiguration } from "@/interfaces/IFirebaseConfiguration";
import { isSupported } from "firebase/messaging";
export default function App({
  Component,
  pageProps: { ...pageProps },
}: AppProps) {
  const { colorMode } = useColorMode();
  const [loading, setLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [fcmToken, setFcmToken] = useState<string | undefined>(undefined);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { publicRuntimeConfig } = getConfig();

  const onCloseAlert = (messageId) => {
    onClose();
    if (toast.isActive(messageId)) {
      toast.close(messageId);
    }
  }

  const getToken = async () => {
    try {
      const firebaseConfig: IFireBaseConfiguration = {
        apiKey: `${publicRuntimeConfig.FIREBASE_APIKEY}`,
        authDomain: `${publicRuntimeConfig.FIREBASE_AUTHDOMAIN}`,
        projectId: `${publicRuntimeConfig.FIREBASE_PROJECTID}`,
        storageBucket: `${publicRuntimeConfig.FIREBASE_STORAGEBUCKET}`,
        messageSenderId: `${publicRuntimeConfig.FIREBASE_MESSAGESENDERID}`,
        appId: `${publicRuntimeConfig.FIREBASE_APPID}`,
        vapidKey: `${publicRuntimeConfig.FIREBASE_VAPIDKEY}`
      };
      const token = await firebaseCloudMessaging.init(firebaseConfig);
      if (token) {
        const payload = await firebaseCloudMessaging.getMessage();
        setFcmToken(token);
        // console.log("fcmToken", token);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    (async () => {
      const hasFirebaseMessagingSupport = await isSupported();
      //  let scriptURL = "/firebase-messaging-sw.js";
      // scriptURL += `?apiKey=${publicRuntimeConfig.FIREBASE_APIKEY}`;
      // scriptURL += `&authDomain=${publicRuntimeConfig.FIREBASE_AUTHDOMAIN}`;
      // scriptURL += `&projectId=${publicRuntimeConfig.FIREBASE_PROJECTID}`;
      // scriptURL += `&storageBucket=${publicRuntimeConfig.FIREBASE_STORAGEBUCKET}`;
      // scriptURL += `&messagingSenderId=${publicRuntimeConfig.FIREBASE_MESSAGESENDERID}`;
      // scriptURL += `&appId=${publicRuntimeConfig.FIREBASE_APPID}`;
      if (hasFirebaseMessagingSupport) {
        if ("serviceWorker" in navigator) {
          navigator.serviceWorker.register("/firebase-messaging-sw.js", { scope: "/" })
            .then(registration => navigator.serviceWorker.ready)
            .then(registration => {
              // console.log("service worker registered successfully");
            })
            .catch(err => {
              // console.log(`ServiceWorker registration failed: ${err}`);
            });

          navigator.serviceWorker.addEventListener("message", event => {
            //  console.log(event.data);
            const messageId = event.data.fcmMessageId;
            if (!toast.isActive(messageId)) {
              toast({
                //status: "info",
                id: messageId,
                position: "top-right",
                duration: 15000,
                isClosable: true,
                colorScheme: "#0074B7",
                render: () => (<><AlertOnScreen isAuthenticated={isAuthenticated} title={event.data.notification.title} message={event.data.notification.body} eventId={event.data.data.eventId} onCloseAlert={onCloseAlert} messageId={messageId} /></>)
              });
            }

          });
        }

        navigator.serviceWorker.getRegistrations().then(async registrations => {
          if (registrations.length > 0) {
            setToken();
          }
          else {
            await navigator.serviceWorker
              .register("/firebase-messaging-sw.js", { scope: "/" })
              //  console.log("Registration successful, scope is:", registration);            
              .catch(function (err) {
                //  console.log("Service worker registration failed, error:", err);
              });

          }
        });
      }
      async function setToken() {
        await getToken();
      }
    })();

  }
    , [toast]);

  useEffect(() => {
    const isEnabled = localStorage.getItem("pushNotifications");
    if (Notification.permission !== "granted") {
      if (isEnabled && isEnabled === "enabled") {
        localStorage.removeItem("pushNotifications");
      }
    }
    else if (Notification.permission === "granted") {
      localStorage.setItem("pushNotifications", "enabled");
    }
  }, []);

  useEffect(() => {
    const start = () => {
      setLoading(true);
    };
    const end = () => {
      setLoading(false);
    };
    Router.events.on("routeChangeStart", start);
    Router.events.on("routeChangeComplete", end);
    Router.events.on("routeChangeError", end);
    return () => {
      Router.events.off("routeChangeStart", start);
      Router.events.off("routeChangeComplete", end);
      Router.events.off("routeChangeError", end);
    };
  }, []);

  useEffect(() => {
    if (pageProps && pageProps.cookies) {
      if (pageProps.cookies.token) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    }

  }, [pageProps]);

  const chakraTheme = extendTheme({
    ...dark,
    styles: {
      global: (props) => ({
        body: {
          color: "default",
          bg: colorMode === "light" ? "#EBE6E6" : "#000000",
          "*": {
            borderColor: colorMode === "light" ? "#CBC5C5" : "gray.600",
          },
        },
      }),
    },
  });

  if (!isAuthenticated) {
    return (
      <>
        <AuthProvider>
          <AuthSettingsProvider>
            <ThemeProvider theme={chakraTheme}>
              <ChakraProvider theme={chakraTheme}>
                <ThemeWrapper>
                  <ColorModeScript initialColorMode="dark" />
                  <ProSidebarProvider>
                    <GlobalStyle />
                    <Flex
                      width="100vw"
                      height="100vh"
                      align="center"
                      justify="center"
                      flexDirection="column"
                    >
                      <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="blue.500"
                        size="xl"
                      />
                      <Text>Verifying your session...</Text>
                    </Flex>
                  </ProSidebarProvider>
                </ThemeWrapper>
              </ChakraProvider>
            </ThemeProvider>
          </AuthSettingsProvider>
        </AuthProvider>
      </>
    );
  }

  return (
    <>
      <Head>
        <title>Signal</title>
      </Head>
      <AuthProvider>
        <AuthSettingsProvider>
          <OrganisationSettingsProvider>
            <UserPermissionsProvider>
              <UserInfoProvider>
                <LanguageProvider>
                  <ThemeProvider theme={chakraTheme}>
                    <ChakraProvider theme={chakraTheme}>
                      <ThemeWrapper>
                        <ColorModeScript initialColorMode="dark" />
                        <ProSidebarProvider>
                          <DashboardProvider>
                            <GlobalStyle />
                            {loading ? (
                              <Flex
                                width="100vw"
                                height="100vh"
                                align="center"
                                justify="center"
                                flexDirection="column"
                              >
                                <Spinner
                                  thickness="4px"
                                  speed="0.65s"
                                  emptyColor="gray.200"
                                  color="blue.500"
                                  size="xl"
                                />
                                <Text>Loading your data...</Text>
                              </Flex>
                            ) : (
                              <Component {...pageProps} fcmToken={fcmToken} getToken={getToken} />

                            )}
                          </DashboardProvider>
                        </ProSidebarProvider>
                      </ThemeWrapper>
                    </ChakraProvider>
                  </ThemeProvider>
                </LanguageProvider>
              </UserInfoProvider>
            </UserPermissionsProvider>
          </OrganisationSettingsProvider>
        </AuthSettingsProvider>
      </AuthProvider>
    </>
  );
}

App.getInitialProps = async (context) => {
  const ctx = context.ctx;
  const req = ctx.req;
  const cookies = req?.cookies;

  const pageProps = {
    cookies: cookies,
  };

  return { pageProps };
};


