interface RectangleBounds {
    sw: { latitude: number; longitude: number };
    ne: { latitude: number; longitude: number };
    nw: { latitude: number; longitude: number };
    se: { latitude: number; longitude: number };
}

interface GeoCircleJson {
    center: { latitude: number; longitude: number };
    radius: number;
    radiusInKm: number;
}

interface GeoRectancleJson {
    bounds: RectangleBounds;
    area: number;
}

interface OptionJson {
    id: number;
    name: string;
}

export function geoRectangleToString(data: GeoRectancleJson): string {
    const { sw, ne, nw, se } = data.bounds;
    const area = data.area.toLocaleString(undefined, { maximumFractionDigits: 3 }); // km2

    const formatCoordinates = (coord: { latitude: number; longitude: number }, name: string): string =>
        `${name}:[${coord.latitude.toFixed(3)}:${coord.longitude.toFixed(3)}]`;

    const formattedCoordinates = [
        formatCoordinates(sw, 'sw'),
        formatCoordinates(ne, 'ne'),
        formatCoordinates(nw, 'nw'),
        formatCoordinates(se, 'se'),
    ].join(", ");

    return `${formattedCoordinates}, area: ${area} km2`;
}

export function geoCircleToString(data: GeoCircleJson): string {
    const center = data.center;
    const circleAreaInKm2 = (Math.PI * ((data.radius / 1000) * (data.radius / 1000)));

    const formatCoordinates = (coord: { latitude: number; longitude: number }): string =>
        `[${coord.latitude.toFixed(3)}:${coord.longitude.toFixed(3)}]`;

    return `center: ${formatCoordinates(center)}, radius: ${(data.radius / 1000).toLocaleString(undefined, { maximumFractionDigits: 3 }) } km, area: ${circleAreaInKm2.toLocaleString(undefined, { maximumFractionDigits: 3 })} km2`;
}

export function multipleOptionsToString(data: OptionJson[]): string {

    const options = data.map((option) => option.name);
    return options.join(', ');
}

export function getDistance(lat1, lng1, lat2, lng2) {
    const R = 6371; // radius of Earth in km
    const dLat = deg2Rad(lat2 - lat1);
    const dLng = deg2Rad(lng2 - lng1);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2Rad(lat1)) * Math.cos(deg2Rad(lat2)) *
        Math.sin(dLng / 2) * Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
}

function deg2Rad(deg) {
    return deg * (Math.PI / 180);
}

export function getZoomLevel(distanceInKm, mapWidthInPixels = 850) {
    const equatorLengthInKm = 40008; // approximate length of the equator in km
    const zoomLevel = Math.log(mapWidthInPixels * (360 / (distanceInKm / equatorLengthInKm))) / Math.log(2);
    return Math.floor(zoomLevel);
}

