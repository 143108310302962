import { useEffect, useState } from "react";
import styled, { withTheme } from "styled-components";
import useTheme from "@/common/utility/useTheme";
import { PostModalProvider } from "../feeds/modal/post-modal-context";
import { PostModal } from "../feeds/modal/post-modal";
import { IPostModalProps } from "@/interfaces/IPostModalProps";
import { encode } from "querystring";
import { useLanguage } from "../userprofile/language/lang-context";

const fetchPost = async (postId?: any) => {
  try {
    const response = await fetch(
      `/api/livestreams/getPostById?${encode(postId)}`
    );
    if (response.ok) {
      const post = await response.json();
      return post;
    }
  } catch (error) {}
};
const PostViewById = (postId) => {
  const theme = useTheme();
  const {
    state: { lang },
  } = useLanguage();
  let postModalProps: IPostModalProps = {
    post: undefined,
    theme,
    user: null,
    lang: lang,
    languageCode: "",
    notesEnabled: true,
    imagesEnabled: true,
    showGeoIcon: true,
    showDeleteIcon: true,
    showMoreIcon: false,
    showRiskCenterInfo: true,
    showExtraInfo: true,
    showFlagIcon: true,
    showEverbridgeIcon: true,
    showTranslateIcon: true,
    allowTranslationLanguage: true,
    showXmattersIcon: true,
    showNogginIcon: true,
    showOriginalText: true,
    translateButtonTitle: "Translate",
    showPostModal: false,
  };
  const [showPostModal, setShowPostModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [post, setPost] = useState(null);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  // const { data, isLoading } = useGetPostById({ id: eventIdFromParams });

  useEffect(() => {
    if (postId) {
      (async () => {
        const data = await fetchPost(postId);
        if (data) {
          setPost(data);
          setShowPostModal(true);
          //setIsFirstLoad(false);
        }
      })();
    } else {
      setShowPostModal(false);
    }
  }, [isFirstLoad, postId]);
  return (
    <>
      {showPostModal && (
        <PostModalProvider>
          <PostModal
            {...postModalProps}
            post={post}
            showPostModal={showPostModal}
            onCloseModal={() => {
              setShowPostModal(false);
              setSelectedPost(null);
            }}
          />
        </PostModalProvider>
      )}
    </>
  );
};
export default withTheme(PostViewById);
