import { useEffect, useState } from "react";
import { withTheme } from "styled-components";
import { Text, Flex, Stack, Tag, TagLabel, Tooltip } from "@chakra-ui/react";
import { toTitleCase } from "@/common/utility/toTitleCase";
import { IEntityType } from "@/interfaces/IEntityType";
import { RodrikModelLookup } from "@/common/constants";

export const PostTags = ({ theme, post, lang }) => {
  const [uniqueEntities, setUniqueEntities] = useState([]);
  const [locations, setLocations] = useState([]);
  const [rodrikTags, setRodrikTags] = useState<
    [
      {
        type: string;
        name: string;
        value: string;
        score: number;
        relevance: number;
      }
    ]
  >(null);
  const [rodrikTopScore, setRodrikTopScore] = useState<{
    type: string;
    name: string;
    value: string;
    score: number;
    relevance: number;
  }>(null);
  const [showAllTags, setShowAllTags] = useState(false);

  // Define the color schemes for each type
  const typeColors = {
    LOCATION: "blue",
    PERSON: "green",
    ORGANIZATION: "purple",
    HIGHRELEVANCE: "red",
    MODERATERELEVANCE: "orange",
  };

  // When the component mounts or when the post?.namedEntities changes, update the uniqueEntities state.
  useEffect(() => {
    // Filter out duplicates based on some criteria, for example, 'value'
    const uniqueEntitiesSet = new Set();
    const filteredEntities = post?.namedEntities?.entities?.filter((entity) => {
      if (!uniqueEntitiesSet.has(entity.value) && entity.type !== "LOCATION") {
        uniqueEntitiesSet.add(entity.value);
        return true;
      }
      return false;
    });

    setUniqueEntities(filteredEntities);

    const uniqueLocationsSet = new Set();
    let filteredLocations = post?.namedEntities?.locations?.filter(
      (location) => {
        if (!uniqueLocationsSet.has(location.geoNameId)) {
          uniqueLocationsSet.add(location.geoNameId);
          return true;
        }
        return false;
      }
    );

    if (post?.hasGeoLocation) {
      const geoLocation = {
        coordinates: { lat: post.geoLatitude, lon: post.geoLongitude },
        name: "Original Geo Location",
        geoNameId: "-",
      };

      if (!filteredLocations) {
        filteredLocations = [];
      }

      filteredLocations.push(geoLocation);
    }

    if (post?.hasAssumedGeoLocation) {
      const assumedGeoLocation = {
        coordinates: {
          lat: post.assumedGeoLatitude,
          lon: post.assumedGeoLongitude,
        },
        name: "Possible User Location",
        geoNameId: "-",
      };

      if (!filteredLocations) {
        filteredLocations = [];
      }

      filteredLocations.push(assumedGeoLocation);
    }
    setLocations(filteredLocations);

    if (post?.machineLearningScores) {
      const relevantScores = post.machineLearningScores.filter(
        (item) => item.Score > 0
      );
      if (relevantScores?.length > 0) {
        const tags = relevantScores.map((score) => {
          const model = RodrikModelLookup.find(
            (item) => item.id === score.ModelId
          );
          return {
            type: score.Score > 1 ? "HIGHRELEVANCE" : "MODERATERELEVANCE",
            name: score.ModelId,
            value: model?.name,
            score: score.Score,
            relevance: score.Relevance,
          };
        });

        setRodrikTags(tags);

        const topModel = tags.find(
          (item) =>
            item.relevance === Math.max(...tags.map((item) => item.relevance))
        );

        setRodrikTopScore(topModel);
      }
    }
  }, [post?.namedEntities]);

  // Group the entities by their entityType
  const entitiesByType: { [key: string]: IEntityType[] } =
    uniqueEntities?.reduce((acc, entity) => {
      if (!acc[entity.type]) {
        acc[entity.type] = [];
      }
      acc[entity.type].push(entity);
      return acc;
    }, {});

  const summaryTags = [
    ...(rodrikTopScore ? [rodrikTopScore] : []),
    ...(entitiesByType?.PERSON?.[0] ? [entitiesByType.PERSON[0]] : []),
    ...(entitiesByType?.ORGANIZATION?.[0]
      ? [entitiesByType.ORGANIZATION[0]]
      : []),
    ...(entitiesByType?.LOCATION?.[0] ? [entitiesByType.LOCATION[0]] : []),
    ...(locations?.[0] ? [locations[0]] : []),
  ];

  const combinedTags = [
    ...(rodrikTags || []),
    ...(entitiesByType?.PERSON || []),
    ...(entitiesByType?.ORGANIZATION || []),
    ...(entitiesByType?.LOCATION || []),
    ...(locations || []),
  ];

  const displayedTags = showAllTags ? combinedTags : summaryTags;

  return (
    <>
      <Stack spacing={1} style={{ direction: "ltr" }} direction="column">
        {displayedTags && displayedTags.length > 0 && (
          <Flex direction="row" flexWrap="wrap">
            {displayedTags.map((tag, idx) => (
              <Flex key={idx}>
                <Tag
                  mb={1}
                  ml={1}
                  minWidth="65px"
                  title={
                    tag?.type
                      ? `${toTitleCase(tag?.type)}:${tag?.value}`
                      : `${tag?.name} [lat:${tag?.coordinates?.lat} lng: ${tag?.coordinates?.lon}]`
                  }
                  size="sm"
                  variant="solid"
                  key={`${post?.namedEntities?.uniqueId}-${tag?.type}-${tag?.geoNameId}-${idx}`} // Adjust the key to include relevant properties
                  colorScheme={typeColors[tag?.type] || "blue"} // Use the color scheme based on the type
                >
                  <TagLabel fontSize="0.85em">
                    {toTitleCase(tag?.type ? tag?.value : tag?.name)}
                  </TagLabel>
                </Tag>
              </Flex>
            ))}
            {combinedTags.length > summaryTags.length && (
              <Flex
                direction="row"
                flexWrap="wrap"
                alignItems="center"
                justifyContent="center" // Center align horizontally
                paddingLeft="0.3em"
                paddingBottom="0.1em"
              >
                <Tooltip label={showAllTags ? lang?.SHOW_LESS || "Show Less" : lang?.SHOW_ALL || "Show All"}>
                  <i
                    onClick={() => setShowAllTags(!showAllTags)}
                    className={
                      showAllTags
                        ? "fa-solid fa-angle-double-left fa-xs"
                        : "fa-solid fa-angle-double-right fa-xs"
                    }
                    style={{ cursor: "pointer" }}
                  />
                </Tooltip>
              </Flex>
            )}
          </Flex>
        )}
      </Stack>
    </>
  );
};
