import * as React from "react";
import { internalPost, internalPut } from "@/common/http/httpServices";

const NAME = "POSTMODAL";
const TYPE_FETCH = `${NAME}_FETCH`;
const TYPE_POST = `${NAME}_POST`;
const TYPE_ERROR = `${NAME}_ERROR`;
const TYPE_DELETE = `${NAME}_DELETE`;
const TYPE_COMPLETE = `${NAME}_COMPLETE`;

const PostModalContext = React.createContext<any | null>(null);

function postModalReducer(state, action) {
  switch (action.type) {
    case TYPE_FETCH: {
      return { ...state, fetch: true };
    }
    case TYPE_POST: {
      return { ...state, fetch: true };
    }
    case TYPE_DELETE: {
      return { ...state, fetch: true };
    }
    case TYPE_COMPLETE: {
      return { ...state, fetch: false, ...action?.payload };
    }
    case TYPE_ERROR: {
      return { ...state, fetch: false, ...action?.payload };
    }
    default: {
      throw new Error(`Unsupported action type: ${action.type}`);
    }
  }
}

function useLivestreamsApi() {
  const context = React.useContext(PostModalContext);

  if (!context) {
    throw new Error(`usePostModalApi must be used within a PostModalProvider`);
  }

  const [state, dispatch] = context;

  const onDeletePost = async (payload) => {
    dispatch({
      type: TYPE_DELETE,
      payload: { status: "pending" },
    });

    const init = {
      url: `/api/livestreams/deletePost`,
      body: payload,
    };

    const { response } = await internalPut(init);

    const data = await response.json();
    const error = { errorMessage: data?.errorMessage };

    if (response?.ok) {
      dispatch({
        type: TYPE_COMPLETE,
        payload: { status: "completed", data: payload },
      });
    } else {
      dispatch({
        type: TYPE_ERROR,
        payload: { error },
      });
    }

    return response;
  };

  const onTranslatePost = async (payload) => {
    dispatch({
      type: TYPE_POST,
      payload: { status: "pending" },
    });

    const init = {
      url: `/api/translation/translatePost`,
      body: payload,
    };

    const { response } = await internalPost(init);

    const data = await response.json();
    const error = { errorMessage: data?.errorMessage };

    if (response?.ok) {
      dispatch({
        type: TYPE_COMPLETE,
        payload: { status: "completed", data: payload },
      });
    } else {
      dispatch({
        type: TYPE_ERROR,
        payload: { error },
      });
    }

    return {response, data};
  };

  return {
    state,
    dispatch,
    onDeletePost,
    onTranslatePost
  };
}

function PostModalProvider(props) {
  const [state, dispatch] = React.useReducer(postModalReducer, {
    fetch: false,
    error: {},
    data: {},
  });
  const value = React.useMemo(() => [state, dispatch], [state]);

  return <PostModalContext.Provider value={value} {...props} />;
}

export { PostModalProvider, useLivestreamsApi };
