import * as React from "react";
import {
  internalGet,
  internalPost,
  internalPut,
} from "@/common/http/httpServices";
import { useRouter } from "next/router";

const NAME = "FlagFolders";
const TYPE_FETCH = `${NAME}_FETCH`;
const TYPE_ERROR = `${NAME}_ERROR`;
const TYPE_COMPLETE = `${NAME}_COMPLETE`;

const FlagFoldersContext = React.createContext<any | null>(null);

function flagFoldersReducer(state, action) {
  switch (action.type) {
    case TYPE_FETCH: {
      return { ...state, fetch: true };
    }
    case TYPE_COMPLETE: {
      return { ...state, fetch: false, ...action?.payload };
    }
    case TYPE_ERROR: {
      return { ...state, fetch: false, ...action?.payload };
    }
    default: {
      throw new Error(`Unsupported action type: ${action.type}`);
    }
  }
}

function useFlagFoldersApi() {
  const context = React.useContext(FlagFoldersContext);
  const router = useRouter();

  if (!context) {
    throw new Error(
      `useFlagFoldersApi must be used within a FlagsFolderProvider`
    );
  }

  const refreshData = () => {
    router.replace(router.asPath);
  };

  const [state, dispatch] = context;

  const onSearchFlagFolderName = async (payload) => {
    dispatch({
      type: TYPE_FETCH,
      payload: { status: "pending" },
    });

    const init = {
      url: "/api/flags/checkFlagFolderName",
      body: payload,
    };

    const { response } = await internalPost(init);

    const data = await response.json();
    const error = { errorMessage: data?.errorMessage };

    return { response: response, data: data };
  };

  const onAddFlagFolder = async (payload) => {
    dispatch({
      type: TYPE_FETCH,
      payload: { status: "pending" },
    });

    const init = {
      url: "/api/flags/create",
      body: payload,
    };

    const { response } = await internalPost(init);

    const data = await response.json();
    const error = { errorMessage: data?.errorMessage };

    if (response?.ok) {
      dispatch({
        type: TYPE_COMPLETE,
        payload: { status: "completed", data: payload },
      });
    } else {
      dispatch({
        type: TYPE_ERROR,
        payload: { error },
      });
    }

    return response;
  };

  const onUpdateFlagFolder = async (payload) => {
    dispatch({
      type: TYPE_FETCH,
      payload: { status: "pending" },
    });

    const init = {
      url: "/api/flags/update",
      body: payload,
    };

    const { response } = await internalPost(init);

    const data = await response.json();
    const error = { errorMessage: data?.errorMessage };

    if (response?.ok) {
      dispatch({
        type: TYPE_COMPLETE,
        payload: { status: "completed", data: payload },
      });
    } else {
      dispatch({
        type: TYPE_ERROR,
        payload: { error },
      });
    }

    return response;
  };

  return {
    state,
    dispatch,
    onSearchFlagFolderName,
    onAddFlagFolder,
    onUpdateFlagFolder
  };
}

function FlagFoldersProvider(props) {
  const [state, dispatch] = React.useReducer(flagFoldersReducer, {
    fetch: false,
    error: {},
    data: {},
  });
  const value = React.useMemo(() => [state, dispatch], [state]);

  return <FlagFoldersContext.Provider value={value} {...props} />;
}

export { FlagFoldersProvider, useFlagFoldersApi };
