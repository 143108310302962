import useSWR from "swr";

//const fetcher = (...args) => fetch(...args).then((res) => res.json());
const fetcher = (url) => fetch(url).then((res) => res.json());

export function useFlagActionFolders() {
  const { data, error, isLoading } = useSWR(() => {
    return "/api/flags/getFlagActionFolders";
  }, fetcher);

  return {
    data,
    isLoading,
    isError: error,
  };
}
