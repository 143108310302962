import styled from "styled-components";

export const GeoInputWrapper = styled.div`
input {
    color:black;
    background-color:white;    

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color:var(--chakra-colors-blackAlpha-700);
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color:var(--chakra-colors-blackAlpha-700);
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
      color:var(--chakra-colors-blackAlpha-700);
    }
}
`;