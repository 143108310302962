import { ToastPosition, useToast } from "@chakra-ui/react";

export const CustomToast = () => {
  const toast = useToast();

  const successToast = (
    title: string = "Success!",
    description: string = "Action completed!",
    pos?: string
  ) => {
    toast({
      position: (pos as ToastPosition) || "top",
      duration: 3000,
      title: title,
      description: description,
      status: "success",
      isClosable: true,
    });
  };

  const errorToast = (
    title: string = "Error!",
    description: string = "Ooops! Something went wrong!",
    pos?: string
  ) => {
    toast({
      position: (pos as ToastPosition) || "top",
      duration: 3000,
      title: title,
      description: description,
      status: "error",
      isClosable: true,
    });
  };

  const warningToast = (
    title: string = "Warning!",
    description: string,
    pos?: string
  ) => {
    toast({
      position: (pos as ToastPosition) || "top",
      duration: 3000,
      title: title,
      description: description,
      status: "warning",
      isClosable: true,
    });
  };

  return { successToast, errorToast, warningToast };
};
