import { Alert, AlertTitle, Box, Button, CloseButton, Icon, useDisclosure } from "@chakra-ui/react";
import { useCurrentUser } from "@/services/useCurrentUser";
import { useEffect, useState } from "react";
import { useLanguage } from "../userprofile/language/lang-context";
import AlertEventsModal from "../alerts/events/alert-event-modal";
import { WarningTwoIcon } from "@chakra-ui/icons";

const AlertOnScreen = ({ isAuthenticated, title, message, eventId, onCloseAlert, messageId }) => {
    const [isAlert, setIsAlert] = useState(false);
    const [toastId, setToastId] = useState("");
    // const {
    //     state: { lang },
    // } = useLanguage();
    // const { data: currentUser } = useCurrentUser();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleAlertNotification = async (id) => {
        setIsAlert(true);
        onOpen();
    };
    const onCloseToastAlert = () => {
        onCloseAlert(toastId);
    }
    useEffect(() => {
        // if we have eventId here means it could be redirected to alert event from signal alert email.
        if (eventId) {
            onOpen();
            setToastId(messageId);
        }
    }, []);
    return (
        <>
            <Box bgGradient='linear(to-r, #36486b, #034f84)' borderRadius='2px'>
                <Alert status="info" variant='left-accent' mr={2}>
                    {/* <i className="bg-purple-800 fa-regular fa-triangle-exclamation fa-2xl"></i> */}
                    <Icon as={WarningTwoIcon} boxSize={12} color="red.500" />
                    <AlertTitle ml={5} color={"white"}>{title} - {message}</AlertTitle>
                    <Button bg='#f0f0f0' color="#3b3a30" ml={5} mr={5} onClick={(e) => handleAlertNotification(eventId)}>View
                    </Button>
                    <CloseButton
                        alignSelf='flex-start'
                        position='relative'
                        right={-1}
                        top={-1}
                        color={"red"}
                        mr={1}
                        onClick={onCloseToastAlert}
                    />
                </Alert>
            </Box>
            {
                eventId?.length > 0 && isAlert && (
                    <AlertEventsModal
                        isOpen={isOpen}
                        onClose={onClose}
                        eventId={eventId}
                    ></AlertEventsModal>
                )
            }
        </>
    );
};

export default AlertOnScreen;
